import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { Button } from 'frr-web/lib/components/Button'

import { Card } from 'components/card/Card'
import { LoanDetailContent } from 'components/layout/LoanDetailContent'
import { UploadDocumentModal } from 'components/modal/UploadDocument.modal'
import { UploadFileRow, UploadRowT } from 'components/UploadFileRow'
import { usePhotoGallery } from 'hooks/usePhotoGallery.hook'
import { DocumentType, Loan } from '../../../types/Loan.d'

export type Props = {
  loan: Loan
}

export const LoanDetailDocuments = (props: Props) => {
  const { takePhoto, files, setFiles } = usePhotoGallery()
  const { t } = useTranslation()
  const [documentModal, setDocumentModal] = useState(false)

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [uploadedFiles, setUploadedFiles] = useState<Array<{ file: File; type: DocumentType }>>([
    {
      type: DocumentType.SwissIDBack,
      file: 'a' as any, // dummy
    },
    {
      type: DocumentType.SwissIDBack,
      file: 'b' as any, // dummy
    },
  ])

  const items: Array<UploadRowT> = Object.keys(files)
    .map((fileName) => ({
      label: t(`document.${fileName}`),
      type: fileName as DocumentType,
      required: false,
      file: files[fileName],
    }))
    .filter((item) => item.file !== undefined)

  return (
    <LoanDetailContent
      {...props}
      pageProps={{
        header: {
          title: t('documents.title'),
          goBack: {
            to: '/loans',
          },
        },
      }}
    >
      <UploadDocumentModal
        onChange={(f) => {
          setFiles({ ...files, ...f })
        }}
        files={files}
        modal={{
          isOpen: documentModal,
          onClose: () => {
            setDocumentModal(false)
          },
        }}
      />
      <Content style={{ flex: 1, marginLeft: 32 }}>
        <ActionBar>
          <Button
            label={t('documents.button.upload')}
            onClick={() => {
              setDocumentModal(true)
            }}
          />
        </ActionBar>
        <Card style={{ flex: 1 }}>
          {uploadedFiles.map((f, key) => (
            <UploadFileRow
              key={`old-${key}`}
              type={f.type}
              file={f.file}
              onChange={(newFiles) => {
                setFiles({
                  ...files,
                  ...newFiles.reduce((obj, i) => ({ ...obj, [i.type]: i.file }), {}),
                })
              }}
              onTakePhoto={takePhoto}
            />
          ))}
          {items.map((item, key) => (
            <UploadFileRow
              {...item}
              type={item.type}
              key={key}
              onRemove={() => {
                if (item.type) {
                  setFiles({ ...files, [item.type]: undefined })
                }
              }}
              onChange={(newFiles) => {
                setFiles({
                  ...files,
                  ...newFiles.reduce((obj, i) => ({ ...obj, [i.type]: i.file }), {}),
                })
              }}
              onTakePhoto={takePhoto}
            />
          ))}
        </Card>
      </Content>
    </LoanDetailContent>
  )
}

const ActionBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  max-width: 600px;
  margin-top: 24px;
`

const Content = styled.div``
