import { IonSkeletonText } from '@ionic/react'
import styled from 'styled-components'

import { ValueType } from '../types/Loan.d'

const ValueTypeWidth = {
  [ValueType.Image]: '120px',
  [ValueType.Amount]: '108px',
  [ValueType.Duration]: '84px',
  [ValueType.Percentage]: '44px',
  [ValueType.Date]: '76px',
  [ValueType.DateRange]: '168px',
  [ValueType.Text]: '20px',
  [ValueType.Separator]: '100%',
  [ValueType.Unknown]: '80px',
}

export const StyledSkeletonText = styled(IonSkeletonText)<{ type?: ValueType }>`
  width: ${({ type }) => (type ? ValueTypeWidth[type] : '80px')};
  float: right;
`
