import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { validatePassword } from 'pages/utils/validate'
import { AuthenticationAttemptChallenge, CredentialsData, UserInfo } from 'types/Authentication'

const registrationFormLens = makeFormLens<CredentialsData>()

const registrationFormFields: Array<FormField<CredentialsData>> = [
  {
    type: FormFieldType.FormSection,
    title: 'registration.formFields.signupTitle',
    fields: [
      {
        type: FormFieldType.TextInput,
        disabled: true,
        label: { label: 'registration.formFields.username.label' },
        lens: registrationFormLens(['username']),
        name: 'username',
      },
      {
        type: FormFieldType.TextInput,
        autocomplete: 'new-password',
        inputType: 'password',
        label: {
          label: 'registration.formFields.passwordNew.label',
          sublabel: 'registration.formFields.passwordNew.sublabel',
        },
        lens: registrationFormLens(['password']),
        name: 'password',
        placeholder: 'registration.formFields.passwordNew.placeholder',
        required: true,
        validate: validatePassword,
      },
    ],
  },
]

interface RegistrationDataResult {
  data: CredentialsData
  emailToken: string
  formFields: Array<FormField<CredentialsData>>
  isLoading: boolean
  name?: string
}

export const AuthRegistrationData = (params: {
  attempt: AuthenticationAttemptChallenge
  isLoading: boolean
  userInfo: UserInfo | null
}): RegistrationDataResult => {
  return params.userInfo
    ? {
        data: {
          username: params.userInfo.email || params.userInfo.name,
          password: null,
          language: '',
        },
        emailToken: params.userInfo.emailToken as string,
        formFields: registrationFormFields,
        isLoading: params.isLoading,
        name: params.userInfo.preferredUsername,
      }
    : {
        data: {
          username: null,
          password: null,
          language: '',
        },
        emailToken: '',
        formFields: registrationFormFields,
        isLoading: true,
      }
}
