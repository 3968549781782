import { ButtonType } from 'frr-web/lib/components/Button'
import { useTranslation } from 'react-i18next'

import { CardDataList } from 'components/CardDataList'
import { LayoutPage } from 'components/layout/LayoutPage'
import { OnboardingContent } from 'components/layout/OnboardingLayout'
import { Loan, LoanDataRow, ValueType } from 'types/Loan.d'

type Props = {
  loan: Loan
}

export const OnboardingContractOfferTemplate = (props: Props) => {
  const { t } = useTranslation()

  const detailList: Array<LoanDataRow> = []

  detailList.push({
    label: 'loanDetail.details.amount.label',
    value: props.loan?.principalAmount,
    type: ValueType.Amount,
  })

  detailList.push({
    label: 'loanDetail.details.insurance.label',
    value: props.loan ? (props.loan.hasPpi && 'yes') || 'no' : undefined,
    type: ValueType.Text,
  })
  detailList.push({
    label: 'loanDetail.details.interestRate.label',
    value: props.loan?.interestRate,
    type: ValueType.Percentage,
  })
  detailList.push({
    label: props.loan?.hasPpi
      ? 'loanDetail.details.installmentAmountInclPpi.label'
      : 'loanDetail.details.installmentAmount.label',
    value: props.loan?.installmentAmount,
    type: ValueType.Amount,
  })

  detailList.push({
    label: 'loanDetail.details.interestRateAmount.label',
    value: props.loan?.totalCostAmount,
    type: ValueType.Amount,
  })

  if (props.loan.applicationAt && props.loan?.nextPaymentAt) {
    detailList.push({
      label: 'loanDetail.details.period.label',
      value: [new Date(props.loan.applicationAt), new Date(props.loan.nextPaymentAt)],
      type: ValueType.DateRange,
    })
  } else {
    detailList.push({
      label: 'loanDetail.details.period.label',
      value: null,
      type: ValueType.DateRange,
    })
  }

  return (
    <LayoutPage>
      <OnboardingContent
        style={{ padding: '0 24px' }}
        title={t('contractOffer.title')}
        description={t('contractOffer.description')}
        leftButton={{
          label: t('contractOffer.button.rejectLoan'),
          type: ButtonType.Chromeless,
          onClick: () => {
            // TODO: handle back button
          },
        }}
        rightButton={{
          label: t('contractOffer.button.acceptLoan'),
          type: ButtonType.Primary,
          onClick: () => {},
          override: { marginLeft: 'auto' },
        }}
      >
        <CardDataList list={detailList}></CardDataList>
      </OnboardingContent>
    </LayoutPage>
  )
}
