// TEST
import { FrontendConfig } from 'types/FrontendConfig'
import { AuthenticationApi } from './authenticationapi/apis/AuthenticationApi'
import { AuthenticationTestApi } from './authenticationapi/apis/AuthenticationTestApi'
import { ResetPasswordApi } from './authenticationapi/apis/ResetPasswordApi'
import { get } from './authenticationapi/runtime'
import { CustomerApi } from './openapi/backend/apis/CustomerApi'
import { LoanApi } from './openapi/backend/apis/LoanApi'
import { DocumentApi } from './openapi/backend/apis/DocumentApi'
import { Configuration as APIConfiguration } from './openapi/backend/runtime'

const MY_COCKPIT_API_PROXY_PATH = '/mycockpitapi'

const loansApi = new LoanApi(
  new APIConfiguration({
    basePath: MY_COCKPIT_API_PROXY_PATH,
  }),
)
const loanDocumentsApi = new DocumentApi(
  new APIConfiguration({
    basePath: MY_COCKPIT_API_PROXY_PATH,
  }),
)
const customerRelationshipApi = new CustomerApi(
  new APIConfiguration({
    basePath: MY_COCKPIT_API_PROXY_PATH,
  }),
)

export enum Query {
  FetchConfig = 'FetchConfig',

  // MyCockpit Api
  FetchLoanDetails = 'FetchLoanDetails',
  FetchLoanList = 'FetchLoanList',
  FetchMe = 'FetchMe',
  FetchAccountStatement = 'FetchAccountStatement',
  FetchLoanDocuments = 'FetchLoanDocuments',
  FetchLoanDocument = 'FetchLoanDocument',
  FetchPaymentSlip = 'FetchPaymentSlip',

  // Authentication Api
  FetchAuthenticationAttempt = 'FetchAuthenticationAttempt',
  FetchCustomerState = 'FetchCustomerState',
  FetchUserInfo = 'FetchUserinfo',

  // Reset Password Api
  FetchResetPasswordAttempt = 'FetchResetPasswordAttempt',
}

export enum Mutation {
  // Authentication Api
  Authorize = 'Authorize',
  ChangePassword = 'ChangePassword',
  CustomerDataChangeRequest = 'CustomerDataChangeRequest',
  EndSession = 'EndSession',
  LoginEnd = 'LoginEnd',
  LoginResendOTPCode = 'LoginResendOTPCode',
  LoginStart = 'LoginStart',
  LoginSubmitCredentials = 'LoginSubmitCredentials',
  LoginSubmitOTPCode = 'LoginSubmitOTPCode',
  Logout = 'Logout',
  RegistrationInitalizePassword = 'RegistrationInitalizePassword',
  RegistrationStart = 'RegistrationStart',

  // Authentication Test Api
  TestSetEmail = 'TestSetEmail',

  // Reset Password Api
  ResetPasswortSendOTPCode = 'ResetPasswordSendOTPCode',
  ResetPasswortStart = 'ResetPasswordStart',
  ResetPasswortSubmitOTPCodeAndPassword = 'ResetPasswordSubmitOTPCodeAndPassword',

  // Test Reset Password Api
  TestResetPasswortEnd = 'TestResetPasswortEnd',
}

export const api = {
  /*
   * Queries
   */

  [Query.FetchConfig]: async (): Promise<FrontendConfig> => {
    const response = await get(`/config`)
    return (await response.json()) as FrontendConfig
  },

  // MyCockpit Api

  [Query.FetchLoanList]: loansApi.loanGetLoans.bind(loansApi),
  [Query.FetchLoanDetails]: loansApi.loanGetLoan.bind(loansApi),
  [Query.FetchAccountStatement]: loansApi.loanGetAccountStatement.bind(loansApi),

  [Query.FetchLoanDocuments]: loanDocumentsApi.documentGetDocuments.bind(loanDocumentsApi),
  [Query.FetchLoanDocument]: loanDocumentsApi.documentGetDocumentAsset.bind(loanDocumentsApi),
  [Query.FetchPaymentSlip]: loanDocumentsApi.documentGetPaymentSlip.bind(loanDocumentsApi),

  [Query.FetchMe]: customerRelationshipApi.customerGetCustomerByMe.bind(customerRelationshipApi),

  // Authentication
  [Query.FetchAuthenticationAttempt]: AuthenticationApi.FetchAuthenticationAttempt,
  [Query.FetchUserInfo]: AuthenticationApi.FetchUserInfo,
  [Query.FetchCustomerState]: AuthenticationApi.FetchCustomerState,

  // Reset password
  [Query.FetchResetPasswordAttempt]: ResetPasswordApi.FetchResetPasswordAttempt,

  /*
   * Mutations
   */

  // MyCockpit Api

  [Mutation.CustomerDataChangeRequest]:
    customerRelationshipApi.customerCreateCustomerChangeRequest.bind(customerRelationshipApi),

  // Authentication Api

  [Mutation.LoginStart]: AuthenticationApi.LoginStart,
  [Mutation.LoginEnd]: AuthenticationApi.LoginEnd,
  [Mutation.Logout]: AuthenticationApi.Logout,

  /*
   * Customer IdP
   */

  // Authentication
  [Mutation.ChangePassword]: AuthenticationApi.ChangePassword,
  [Mutation.LoginResendOTPCode]: AuthenticationApi.ResendOTPCode,
  [Mutation.LoginSubmitCredentials]: AuthenticationApi.LoginSubmitCredentials,
  [Mutation.LoginSubmitOTPCode]: AuthenticationApi.SubmitOTPCode,
  [Mutation.RegistrationInitalizePassword]: AuthenticationApi.RegistrationInitalizePassword,
  [Mutation.RegistrationStart]: AuthenticationApi.RegistrationStart,

  // Authentication Test
  [Mutation.TestSetEmail]: AuthenticationTestApi.TestSetEmail,

  // Reset password
  [Mutation.ResetPasswortStart]: ResetPasswordApi.ResetPasswordStart,
  [Mutation.ResetPasswortSendOTPCode]: ResetPasswordApi.SendOTPCode,
  [Mutation.ResetPasswortSubmitOTPCodeAndPassword]: ResetPasswordApi.SubmitOTPCodeAndPassword,
}
