import { useTranslation } from 'react-i18next'

import { CustomerRelationship } from 'types/CustomerRelationship'
import { CustomerDataPage } from 'pages/profile/customerData/CustomerData.page'

import { Props as ModalProps } from './Modal'
import { ModalWithContent } from './ModalWithContent'

type Props = {
  modal: Omit<ModalProps, 'children'>
  user: CustomerRelationship
}

export const ChangeCustomerDataModal = (props: Props) => {
  const { t } = useTranslation()

  return (
    <ModalWithContent
      title={t('changeCustomerData.modal.title')}
      modal={{
        ...props.modal,
      }}
    >
      <CustomerDataPage user={props.user} onClose={props.modal.onClose} />
    </ModalWithContent>
  )
}
