/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    DocumentTypeEnum,
    DocumentTypeEnumFromJSON,
    DocumentTypeEnumFromJSONTyped,
    DocumentTypeEnumToJSON,
} from './DocumentTypeEnum';

/**
 * A document associated with a Loan.
 * @export
 * @interface Document
 */
export interface Document {
    /**
     * Document's id.
     * @type {string}
     * @memberof Document
     */
    id: string;
    /**
     * Id of the customer this document belongs to.
     * @type {string}
     * @memberof Document
     */
    customerId: string;
    /**
     * Document"s id.
     * @type {DocumentTypeEnum}
     * @memberof Document
     */
    type: DocumentTypeEnum | null;
    /**
     * Locize translation key.
     * @type {string}
     * @memberof Document
     */
    translationKey: string;
    /**
     * The loan this document belongs to.
     * @type {string}
     * @memberof Document
     */
    loanId: string;
    /**
     * When the Document was created.
     * @type {Date}
     * @memberof Document
     */
    createdAt: Date;
    /**
     * When the Document was dated for.
     * @type {Date}
     * @memberof Document
     */
    datedAt: Date;
}

export function DocumentFromJSON(json: any): Document {
    return DocumentFromJSONTyped(json, false);
}

export function DocumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Document {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'customerId': json['customerId'],
        'type': DocumentTypeEnumFromJSON(json['type']),
        'translationKey': json['translationKey'],
        'loanId': json['loanId'],
        'createdAt': (new Date(json['createdAt'])),
        'datedAt': (new Date(json['datedAt'])),
    };
}

export function DocumentToJSON(value?: Document | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'customerId': value.customerId,
        'type': DocumentTypeEnumToJSON(value.type),
        'translationKey': value.translationKey,
        'loanId': value.loanId,
        'createdAt': (value.createdAt.toISOString()),
        'datedAt': (value.datedAt.toISOString()),
    };
}

