import styled from 'styled-components'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

export const Card = styled.div<{ reverse?: boolean }>`
  background: var(--card-background-color, white);
  border-radius: var(--card-border-radius, 8px);
  box-shadow: var(--card-shadow);
  margin: 24px 0;
  max-width: 600px;
  overflow: hidden;
  width: 100%;
  display: ${(props) => (props.reverse ? 'flex' : undefined)};

  @media ${DesktopStyle} {
    padding: 16px;
    flex-direction: ${(props) => (props.reverse ? 'column' : undefined)};
  }

  @media ${MobileStyle} {
    padding: 16px 0px;
    flex-direction: ${(props) => (props.reverse ? 'column-reverse' : undefined)};
  }
`
