import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { ChangePasswordRequestParams } from 'data/authenticationapi/models/ChangePassword'
import { validatePassword } from 'pages/utils/validate'
import { ChangePassword } from 'types/Authentication'

const securityFormLens = makeFormLens<ChangePassword>()

export interface SecurityDataResult {
  data: ChangePasswordRequestParams
  formFields: (data: ChangePasswordRequestParams) => Array<FormField<ChangePasswordRequestParams>>
  isLoading: boolean
  name?: string
}

export const SecurityData = (): SecurityDataResult => {
  const signupFormFields = (
    data: ChangePasswordRequestParams,
  ): Array<FormField<ChangePasswordRequestParams>> => {
    const validateNewPassword = (value: string) => {
      if (value === data.currentPassword) {
        return 'formFields.error.passwordRepeated'
      } else {
        return validatePassword(value)
      }
    }
    const validationRetype = (value: string) =>
      value !== data.newPassword ? 'formFields.error.passwordMismatch' : null

    return [
      {
        type: FormFieldType.FormSection,
        fields: [
          {
            label: {
              label: 'security.formFields.currentPassword.label',
            },
            placeholder: 'security.formFields.currentPassword.placeholder',
            lens: securityFormLens(['currentPassword']),
            name: 'currentPassword',
            dataTestId: 'currentPassword',
            autocomplete: 'nope',
            type: FormFieldType.TextInput,
            inputType: 'password',
            required: true,
          },
          {
            label: {
              label: 'security.formFields.newPassword.label',
              sublabel: 'security.formFields.newPassword.sublabel',
            },
            placeholder: 'security.formFields.newPassword.placeholder',
            lens: securityFormLens(['newPassword']),
            name: 'newPassword',
            dataTestId: 'newPassword',
            autocomplete: 'nope',
            type: FormFieldType.TextInput,
            inputType: 'password',
            required: true,
            validate: validateNewPassword,
          },
          {
            label: {
              label: 'security.formFields.newPasswordRetyped.label',
            },
            placeholder: 'security.formFields.newPasswordRetyped.placeholder',
            lens: securityFormLens(['newPasswordRetyped']),
            name: 'newPasswordRetyped',
            dataTestId: 'newPasswordRetyped',
            autocomplete: 'nope',
            type: FormFieldType.TextInput,
            inputType: 'password',
            disabled: validatePassword(data.newPassword) !== null,
            required: true,
            validate: validationRetype,
          },
        ],
      },
    ]
  }

  return {
    isLoading: false,
    formFields: signupFormFields,
    data: {
      currentPassword: null,
      newPassword: null,
      newPasswordRetyped: null,
    },
  }
}
