import styled from 'styled-components'

import { ProfileCard } from 'components/card/ProfileCard'
import { DesktopStyle } from 'styles/theme/IonicMediaQuery'
import { LoadingOverlayModal } from 'components/overlay/LoadingOverlayModal'

import { CustomerDataChangeValues, CustomerDataResult } from './CustomerData.data'

export type Props = {
  values: CustomerDataChangeValues
  setValues: (values: CustomerDataChangeValues) => void
  customerData: CustomerDataResult
  onSubmit: () => void
  isLoading: boolean
}

export const CustomerDataTemplate = (props: Props) => {
  return props.isLoading ? (
    <LoadingOverlayModal />
  ) : (
    <StyledProfileCard
      data={props.values}
      formFields={props.customerData.formFields}
      onChange={(formState: CustomerDataChangeValues) =>
        props.setValues({ ...props.values, ...formState })
      }
      onSubmit={props.onSubmit}
      buttonDisabled={JSON.stringify(props.customerData.data) === JSON.stringify(props.values)}
      buttonLabel={'address.actions.send.label'}
    />
  )
}

const StyledProfileCard = styled(ProfileCard)`
  margin-left: 0px;
  padding: 12px;
  height-max: 500px;
  overflow-y: scroll;

  @media ${DesktopStyle} {
    margin-left: 24px;
    padding: 48px;
  }
`
