import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Card } from 'components/card/Card'
import { LoanCard } from 'components/card/LoanCard'
import { LoanStateEnum } from 'data/openapi/backend'
import { loanActiveStates } from 'shared/constants'
import { LoanDataItem } from 'types/Loan.d'
import { MobileStyle } from 'styles/theme/IonicMediaQuery'

export type LoanListTemplateProps = {
  applications: Array<LoanDataItem>
  loans: Array<LoanDataItem>
  isLoading: boolean
}

export const LoanListTemplate = (props: LoanListTemplateProps) => {
  const { t } = useTranslation()

  const cooloffLoans = props.loans
    .filter((l) => l.state === LoanStateEnum.Cooloff)
    .sort((a, b) => (new Date(`${a.disbursedAt}`) < new Date(`${b.disbursedAt}`) ? 1 : -1))
  const activeLoans = props.loans
    .filter((l) => l.state && loanActiveStates.includes(l.state))
    .sort((a, b) => (new Date(`${a.disbursedAt}`) < new Date(`${b.disbursedAt}`) ? 1 : -1))
  const closedLoans = props.loans
    .filter((l) => l.state === LoanStateEnum.Closed)
    .sort((a, b) => (new Date(`${a.disbursedAt}`) < new Date(`${b.disbursedAt}`) ? 1 : -1))

  return (
    <Wrapper>
      {cooloffLoans.length > 0 && (
        <StyledActiveLoanContainer>
          <GroupTitle>{t('overview.cooloffLoanList.title')}</GroupTitle>
          {cooloffLoans.map((loan) => (
            <LoanCard key={loan.contractId} loan={loan} />
          ))}
        </StyledActiveLoanContainer>
      )}
      {activeLoans.length > 0 && (
        <StyledActiveLoanContainer>
          <GroupTitle>{t('overview.activeLoanList.title')}</GroupTitle>
          {activeLoans.map((loan) => (
            <LoanCard key={loan.contractId} loan={loan} />
          ))}
        </StyledActiveLoanContainer>
      )}
      {closedLoans.length > 0 && (
        <StyledActiveLoanContainer>
          <GroupTitle>{t('overview.closedLoans.title')}</GroupTitle>
          {closedLoans.map((loan) => (
            <LoanCard key={loan.contractId} loan={loan} />
          ))}
        </StyledActiveLoanContainer>
      )}
      {!props.isLoading &&
      closedLoans.length === 0 &&
      activeLoans.length === 0 &&
      cooloffLoans.length === 0 ? (
        <StyledCard>
          <h3>{t('loanList.noLoansTitle')}</h3>
          <NoLoansDescription>{t('loanList.noLoansDescription')}</NoLoansDescription>
        </StyledCard>
      ) : (
        <></>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const NoLoansDescription = styled.p`
  margin-top: 8px;
`

const GroupTitle = styled.p`
  font-size: 16px;
  opacity: 0.7;
`

const StyledActiveLoanContainer = styled.div`
  margin: 16px 0 64px 0;
  max-width: 800px;
  width: 100%;
`
const StyledCard = styled(Card)`
  @media ${MobileStyle} {
    padding: 0 24px;
  }
`
