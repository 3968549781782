import { DocumentTypeEnum, LoanStateEnum } from 'data/openapi/backend'

export const loanActiveStates: Array<string> = [
  LoanStateEnum.ActiveNew,
  LoanStateEnum.Active,
  LoanStateEnum.ActiveInArrears,
  LoanStateEnum.ActiveInClosure,
  LoanStateEnum.ActiveOverdue,
]

export const loanDocumentsListSecondaryGroups = [DocumentTypeEnum.TaxStatement]
