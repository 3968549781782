/* eslint-disable react-hooks/exhaustive-deps */
import { IonRouterOutlet } from '@ionic/react'
import { ButtonType } from 'frr-web/lib/components/Button'
import { useEffect } from 'react'
import { Redirect, Route, useHistory, useLocation } from 'react-router-dom'

import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { AuthInfoPage } from 'pages/authentication/authInfo/AuthInfo.page'
import { AuthLoginPage } from 'pages/authentication/authLogin/AuthLogin.page'
import { AuthOTPPage } from 'pages/authentication/authOTP/AuthOTP.page'
import { AuthRegistrationPage } from 'pages/authentication/authRegistration/AuthRegistration.page'
import { StartResetPasswordPage } from 'pages/authentication/startResetPassword/StartResetPassword.page'
import {
  AuthenticationAttempt,
  AuthenticationAttemptChallenge,
  CustomerAuthenticationState,
  UserInfo,
} from 'types/Authentication.d'

import { AuthLoginStartPage } from 'pages/authentication/authLoginStart/AuthLoginStart.page'
import { StartRegistrationPage } from 'pages/authentication/authStartRegistration/StartRegistration.page'
import { AuthResetPasswordProvider } from './AuthResetPasswordProvider'

const OTPPaths = ['/login/otp', '/register/otp']

export const AuthRoutesProvider = (props: {
  authAttempt: AuthenticationAttempt
  isLoggingOut: boolean
  isOTPPageShown: boolean
  onAuthAttemptChanged: (attempt: AuthenticationAttempt) => void
  onAuthAttemptError: (error: string) => void
  onLogout: (isRestart: boolean) => void
  onShowOTPPage: () => void
  onRestartLogin: () => void
  onRestartRegister: () => void
  userInfo: UserInfo | null
}) => {
  const history = useHistory()
  const location = useLocation()

  // Set authentication state
  const attempt = props.authAttempt as AuthenticationAttemptChallenge

  // Define step of authentication
  const isRegistration =
    props.userInfo?.state === CustomerAuthenticationState.InitializePassword &&
    attempt.state === AuthenticationAttemptState.PasswordChallenge

  const isOTP = [
    AuthenticationAttemptState.MTanCallenge,
    AuthenticationAttemptState.MTanChallengeFailLimitReached,
  ].includes(attempt.state)

  const isAttemptExpired = props.authAttempt.state === AuthenticationAttemptState.Expired

  const isAuthenticated = props.authAttempt.state === AuthenticationAttemptState.Succeeded

  // Handle routing including on browser go back from OTP page
  const { isLoggingOut, isOTPPageShown, onLogout, onShowOTPPage } = props

  useEffect(() => {
    if (isAuthenticated) {
      // Authenticated user is redirected to main app
      history.replace('/')
    } else if (isOTPPageShown) {
      // Restart log in on browser bo back
      if (!location.pathname.endsWith('otp')) {
        !isLoggingOut && onLogout(!isAttemptExpired)
      }
    } else if (!isAttemptExpired) {
      if (isOTP) {
        if (location.pathname === '/') {
          // Special case if a user does a re-copy/paste of the basic URL during OTP
          !isLoggingOut && onLogout(true)
        } else {
          // Redirect to OTP page if not already there
          if (!OTPPaths.includes(location.pathname)) {
            history.push(`${location.pathname}/otp`)
          }
          onShowOTPPage()
        }
      } else if (OTPPaths.includes(location.pathname)) {
        // Redirect to login page if not already there and not in OTP state
        history.push('/login')
      }
    }
  }, [
    isAttemptExpired,
    isAuthenticated,
    isLoggingOut,
    isOTP,
    isOTPPageShown,
    location.pathname,
    history,
  ])

  // Show session expired pages
  useEffect(() => {
    if (isAttemptExpired) {
      history.push(isRegistration ? '/registration/session-expired' : '/login/session-expired')
    }
  }, [isAttemptExpired, isRegistration, history])

  return (
    <IonRouterOutlet id="authentication-pages">
      {/* Login routes */}
      <Route exact path="/login">
        <AuthLoginPage
          onAuthStepSuccess={props.onAuthAttemptChanged}
          onAuthError={props.onAuthAttemptError}
        />
      </Route>

      <Route path="/login/otp">
        <AuthOTPPage
          onAuthStepSuccess={props.onAuthAttemptChanged}
          onAuthError={props.onAuthAttemptError}
        />
      </Route>

      <Route path="/login/session-expired">
        <AuthInfoPage
          title="authentication.loginSessionExpired.title"
          errorText="authentication.loginSessionExpired.errorText"
          onFinishLabel="authentication.actions.restartLogin.label"
          onFinish={props.onRestartLogin}
        />
      </Route>

      {/* Start registration as new customer without registration email */}
      <Route exact path="/login/register" render={() => <StartRegistrationPage />} />

      {/* Forgot password routes */}
      <Route exact path="/login/forgot-password" render={() => <StartResetPasswordPage />} />

      <Route path="/login/reset-password" render={() => <AuthResetPasswordProvider />} />

      {/* Registration routes used in email link */}
      <Route exact path="/register">
        <AuthRegistrationPage
          onAuthStepSuccess={props.onAuthAttemptChanged}
          onAuthError={props.onAuthAttemptError}
          userInfo={props.userInfo}
        />
      </Route>

      <Route exact path="/register/otp">
        <AuthOTPPage
          onAuthStepSuccess={props.onAuthAttemptChanged}
          onAuthError={props.onAuthAttemptError}
        />
      </Route>

      <Route path="/register/session-expired">
        <AuthInfoPage
          title="authentication.registerSessionExpired.title"
          infoText="authentication.registerSessionExpired.errorText"
          onFinish={props.onRestartRegister}
          onFinishLabel="authentication.actions.gotoLoginFromRegister.label"
          onFinishButtonType={ButtonType.Primary}
        />
      </Route>

      {/* Logout routes */}
      <Route exact path="/logout">
        <AuthInfoPage title="authentication.logout.title" text="authentication.logout.text" />
      </Route>

      <Route exact path="/logout/session-expired">
        <AuthInfoPage
          title="authentication.sessionExpired.title"
          errorText="authentication.sessionExpired.errorText"
        />
      </Route>

      {/* Landing page */}
      <Route path="/info/login">
        <AuthLoginStartPage />
      </Route>

      {/* Redirects */}
      <Route render={() => <Redirect to={isRegistration ? '/register' : '/login'} />} />
    </IonRouterOutlet>
  )
}
