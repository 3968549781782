import { createRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonType } from 'frr-web/lib/components/Button'
import styled from 'styled-components'
import { ComponentTheme } from 'frr-web/lib/theme/theme.components'

import { DocumentType } from '../types/Loan.d'

import { Modal } from './modal/Modal'
import { Card } from './card/Card'

export type UploadRowT = {
  label: string
  type?: DocumentType
  uploadLabel?: string
  required: boolean
  onUploadClick?: () => void
  removeClick?: boolean
  file?: File
}

export type Props = {
  label?: string
  type?: DocumentType
  uploadLabel?: string
  isDownload?: boolean
  onRemove?: () => void
  removeClick?: boolean
  onChange: (files: Array<{ file: File; type: DocumentType }>) => void
  onTakePhoto: ({ type }: { type: DocumentType }) => void
  onUploadClick?: () => void
  file?: File
}

export const UploadFileRow = (props: Props) => {
  const { t } = useTranslation()
  const inputRef = createRef<HTMLInputElement>()
  const [showModal, setShowModal] = useState(false)

  function clickHandler() {
    if (inputRef.current) {
      inputRef.current.focus()
      inputRef.current.click()
    }
  }
  const changeHandler = () => {
    if (inputRef.current) {
      inputRef.current.focus()
      if (inputRef.current.files) {
        const files = inputRef.current.files
        const file = files.item(0)
        if (file && props.type) {
          props.onChange([{ type: props.type, file: file }])
          setShowModal(false)
        }
      }
    }
  }

  return (
    <>
      {showModal && (
        <Modal
          onClose={() => {
            setShowModal(false)
          }}
          isOpen={showModal}
          style={{
            maxWidth: '400px',
            maxHeight: '600px',
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
          }}
        >
          <>
            <Card>
              <Button
                label={t('documentUpload.camera')}
                type={ButtonType.Chromeless}
                onClick={() => {
                  if (props.type) {
                    props.onTakePhoto({ type: props.type })
                    setShowModal(false)
                  }
                }}
                style={{
                  ...UploadButtonStyle,
                }}
              />
              <Button
                label={t('documentUpload.file')}
                onClick={clickHandler}
                type={ButtonType.Chromeless}
                style={{
                  ...UploadButtonStyle,
                }}
              />
            </Card>
            <Card style={{ marginTop: 16 }}>
              <Button
                label={t('documentUpload.close')}
                onClick={() => {
                  setShowModal(false)
                }}
                type={ButtonType.Chromeless}
                style={{ ...UploadButtonStyle }}
              />
            </Card>
          </>
        </Modal>
      )}
      <FileItem>
        <input
          type="file"
          id="fileupload"
          style={{ display: 'none' }}
          ref={inputRef}
          onChange={changeHandler}
        />
        <p>{props.label || t(`documentType.${props.type}`)}</p>
        {!(props.file && !props.onRemove) && (
          <Button
            label={
              props.file
                ? props.isDownload
                  ? t('documentUpload.download')
                  : t('documentUpload.remove')
                : props.uploadLabel || t('documentUpload.upload')
            }
            style={{
              chromeless: {
                height: 32,
              },
            }}
            type={props.file || props.removeClick ? ButtonType.Chromeless : ButtonType.Secondary}
            onClick={() => {
              if (props.file && props.onRemove) {
                props.onRemove()
              } else {
                if (props.onUploadClick) {
                  props.onUploadClick()
                } else {
                  setShowModal(true)
                }
              }
            }}
          />
        )}
      </FileItem>
    </>
  )
}

const UploadButtonStyle: Partial<ComponentTheme['button']> = {
  chromeless: {
    width: '100%',
    height: 56,
    border: 0,

    ':hover': {
      opacity: 0.9,
    },
  },
  secondary: {
    height: 32,
  },
  chromelessLabel: {
    color: 'black',
  },
}

const FileItem = styled.div`
  display: flex;
  padding: 0 24px;
  height: 64px;
  border-bottom: 1px solid var(--neutral-gray-2);
  align-items: center;
  justify-content: space-between;
`
