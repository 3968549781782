/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    AccountTransaction,
    AccountTransactionFromJSON,
    AccountTransactionFromJSONTyped,
    AccountTransactionToJSON,
} from './AccountTransaction';

/**
 * Account statement information.
 * @export
 * @interface AccountStatement
 */
export interface AccountStatement {
    /**
     * Id of the contract.
     * @type {string}
     * @memberof AccountStatement
     */
    contractId: string;
    /**
     * Id of the customer.
     * @type {string}
     * @memberof AccountStatement
     */
    customerId: string;
    /**
     * Timestamp of remaining balance.
     * @type {Date}
     * @memberof AccountStatement
     */
    remainingBalanceAt?: Date | null;
    /**
     * Amount still pending to pay of the total loan amount.
     * @type {number}
     * @memberof AccountStatement
     */
    remainingAmount?: number | null;
    /**
     * Total loan cost, the amount borrowed plus interest.
     * @type {Array<AccountTransaction>}
     * @memberof AccountStatement
     */
    transactions: Array<AccountTransaction>;
}

export function AccountStatementFromJSON(json: any): AccountStatement {
    return AccountStatementFromJSONTyped(json, false);
}

export function AccountStatementFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountStatement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'contractId': json['contractId'],
        'customerId': json['customerId'],
        'remainingBalanceAt': !exists(json, 'remainingBalanceAt') ? undefined : (json['remainingBalanceAt'] === null ? null : new Date(json['remainingBalanceAt'])),
        'remainingAmount': !exists(json, 'remainingAmount') ? undefined : json['remainingAmount'],
        'transactions': ((json['transactions'] as Array<any>).map(AccountTransactionFromJSON)),
    };
}

export function AccountStatementToJSON(value?: AccountStatement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'contractId': value.contractId,
        'customerId': value.customerId,
        'remainingBalanceAt': value.remainingBalanceAt === undefined ? undefined : (value.remainingBalanceAt === null ? null : value.remainingBalanceAt.toISOString()),
        'remainingAmount': value.remainingAmount,
        'transactions': ((value.transactions as Array<any>).map(AccountTransactionToJSON)),
    };
}

