import styled from 'styled-components'
import { Loading } from 'frr-web/lib/components/Loading'

import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

type LoadingOverlayModalProps = {
  embedded?: boolean
}

export const LoadingOverlayModal = (props: LoadingOverlayModalProps) => {
  return (
    <LoadingWrapper embedded={!!props.embedded}>
      <Loading style={{ transform: 'scale(0.4)', marginTop: 64 }} />
    </LoadingWrapper>
  )
}

const LoadingWrapper = styled.div<{ embedded: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  @media ${DesktopStyle} {
    height: calc(var(--vh, 1vh) * ${(props) => (props.embedded ? 30 : 60)});
  }

  @media ${MobileStyle} {
    height: calc(var(--vh, 1vh) * ${(props) => (props.embedded ? 50 : 80)});
  }
`
