/* eslint-disable react-hooks/exhaustive-deps */
import { api, Query } from 'data/api'
import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthenticationAttempt, CustomerAuthenticationState, UserInfo } from 'types/Authentication.d'
import { AuthDelegator, AuthLoadingOverlay } from './AuthDelegator'

interface AuthConfig {
  showLoginStartPage: boolean
  discardLoginStartPage: boolean
  setDiscardLoginStartPage: (discard: boolean) => void
}
const AuthConfigContext = createContext<AuthConfig>({
  showLoginStartPage: true,
  discardLoginStartPage: false,
  setDiscardLoginStartPage: () => {},
})
AuthConfigContext.displayName = 'Authorization Configuration'
export const useAuthConfigContext = (): AuthConfig => useContext(AuthConfigContext)

export const AuthProvider = (props: { children: ReactNode }) => {
  const history = useHistory()
  const location = useLocation()
  const urlSearchParams = new URLSearchParams(location.search)

  // Start login page
  const [showLoginStartPage, setShowLoginStartPage] = useState(
    localStorage.getItem('discardLoginStartPage') !== '1',
  )
  const [discardLoginStartPage, setDiscardLoginStartPage] = useState(
    localStorage.getItem('discardLoginStartPage') === '1',
  )

  // Initialize authentication attempt state
  const [authState, setAuthState] = useState<{
    attempt: AuthenticationAttempt
    isLoading: boolean
    userInfo: UserInfo | null
  }>({
    attempt: { state: AuthenticationAttemptState.None },
    isLoading: true,
    userInfo: null,
  })

  // Fetch user info and check registration
  const emailToken = urlSearchParams.get('emailCtaToken')
  const [isRegistration] = useState(!!emailToken)

  const fetchUserInfo = useCallback(
    (attempt?: AuthenticationAttempt) =>
      api[Query.FetchUserInfo]()
        .then((userInfo) => {
          let mappedAttempt = attempt || authState.attempt

          if (!userInfo && isRegistration && authState.isLoading) {
            api[Query.FetchCustomerState](emailToken as string)
              .then((customerState) => {
                if (customerState.userInfo?.state === CustomerAuthenticationState.Active) {
                  history.replace('/login')
                }

                if (customerState.state === AuthenticationAttemptState.Expired) {
                  history.replace('/register/session-expired')
                }

                if (customerState.state === AuthenticationAttemptState.Error) {
                  mappedAttempt = {
                    state: AuthenticationAttemptState.Error,
                    error: 'Customer state error',
                  }
                }

                setAuthState({
                  attempt: mappedAttempt,
                  isLoading: false,
                  userInfo: customerState.userInfo,
                })
              })
              .catch(() =>
                setAuthState({
                  attempt: {
                    state: AuthenticationAttemptState.Error,
                    error: 'Error while checking customer state',
                  },
                  isLoading: false,
                  userInfo: null,
                }),
              )
          } else {
            setAuthState({
              attempt: mappedAttempt,
              isLoading: false,
              userInfo,
            })
          }
        })
        .catch(() =>
          setAuthState({
            attempt: {
              state: AuthenticationAttemptState.Error,
              error: 'Error while fetching user info',
            },
            isLoading: false,
            userInfo: null,
          }),
        ),
    [authState.attempt, authState.isLoading, emailToken, isRegistration, history],
  )

  // Fetch authentication attempt info
  useEffect(() => {
    api[Query.FetchAuthenticationAttempt]().then(fetchUserInfo)
  }, [])

  if (authState.isLoading) return <AuthLoadingOverlay label={'authentication.loadingLabel'} />

  return (
    <AuthConfigContext.Provider
      value={{
        showLoginStartPage,
        discardLoginStartPage,
        setDiscardLoginStartPage: (discard) => {
          setShowLoginStartPage(false)
          if (discard) {
            localStorage.setItem('discardLoginStartPage', '1')
            setDiscardLoginStartPage(true)
          }
        },
      }}
    >
      <AuthDelegator
        initialAttempt={authState.attempt}
        refetch={fetchUserInfo}
        userInfo={authState?.userInfo || null}
      >
        {props.children}
      </AuthDelegator>
    </AuthConfigContext.Provider>
  )
}
