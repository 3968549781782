import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route, Switch } from 'react-router-dom'

import { LoanDetailPage } from 'pages/loanDetail/LoanDetail.page'
import { LoanListPage } from 'pages/loanList/LoanList.page'
import { LoanOnboardingPage } from 'pages/loanOnboarding/LoanOnboarding.page'
import { ProfilePage } from 'pages/profile/Profile.page'

export const RoutesProvider = () => {
  return (
    <IonRouterOutlet id="main-content">
      <Switch>
        <Route path="/loans" exact>
          <LoanListPage />
        </Route>
        <Route path="/loan/:loanId/detail">
          <LoanDetailPage />
        </Route>
        <Route path="/loan/:loanId/onboarding">
          <LoanOnboardingPage />
        </Route>
        <Route path="/profile">
          <ProfilePage />
        </Route>
        <Route render={() => <Redirect to="/loans" />} />
      </Switch>
    </IonRouterOutlet>
  )
}
