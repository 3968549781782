/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum LoanStateEnum {
    ActiveNew = 'ActiveNew',
    Active = 'Active',
    ActiveOverdue = 'ActiveOverdue',
    ActiveInArrears = 'ActiveInArrears',
    ActiveInClosure = 'ActiveInClosure',
    Hidden = 'Hidden',
    Closed = 'Closed',
    Cooloff = 'Cooloff'
}

export function LoanStateEnumFromJSON(json: any): LoanStateEnum {
    return LoanStateEnumFromJSONTyped(json, false);
}

export function LoanStateEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoanStateEnum {
    return json as LoanStateEnum;
}

export function LoanStateEnumToJSON(value?: LoanStateEnum | null): any {
    return value as any;
}

