/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOverlay } from 'components/overlay/LoadingOverlay'
import { api, Mutation } from 'data/api'
import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { createContext, ReactNode, useContext, useEffect, useReducer } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import { AuthenticationAttempt, CustomerAuthenticationState, UserInfo } from 'types/Authentication.d'
import { useConfigContext } from '../ConfigProvider'
import { AuthenticatedUserProvider } from './AuthenticatedUserProvider'
import { AuthRoutesProvider } from './AuthRoutesProvider'

/*
 * Auth attempt context declaration
 */

const AuthAttemptContext = createContext<AuthenticationAttempt | null>(null)
AuthAttemptContext.displayName = 'Auth Attempt Context'

export const useAuthAttemptContext = (): AuthenticationAttempt => {
  const authAttempt = useContext(AuthAttemptContext)
  if (!authAttempt) {
    throw new Error(`Auth attempt not found`)
  }
  return authAttempt
}

/*
 * Auth loading overlay
 */

export const AuthLoadingOverlay = (props: { label: string }) => {
  const { t } = useTranslation()
  return <LoadingOverlay label={t(props.label)} isLoading />
}

/*
 * Delegator state: types and initial state
 */

interface DelegatorState {
  attempt: AuthenticationAttempt
  isError: boolean
  isLoading: boolean
  isLoggingOut: boolean
  isOTPPageShown: boolean
  isRestart: boolean
}
enum DelegatorActionType {
  SetAuthAttempt = 'SetAuthAttempt',
  SetLogout = 'SetLogout',
  SetLogoutFinished = 'SetLogoutFinished',
  SetIsOTPPageShown = 'SetIsOTPPageShown',
}

type DelegatorAction =
  | {
      type: DelegatorActionType.SetAuthAttempt
      attempt: AuthenticationAttempt
      isLoading: boolean
      isError?: boolean
      isLoggingOut?: boolean
      isOTPPageShown?: boolean
      isRestart?: boolean
    }
  | {
      type: DelegatorActionType.SetLogout
      isRestart: boolean
    }
  | {
      type: DelegatorActionType.SetLogoutFinished
    }
  | {
      type: DelegatorActionType.SetIsOTPPageShown
    }

const getInitialDelegatorState = (params: {
  attempt: AuthenticationAttempt
  isReady: boolean
}): DelegatorState => ({
  attempt: params.attempt,
  isError: params.attempt.state === AuthenticationAttemptState.Error,
  isLoading: !params.isReady,
  isLoggingOut: false,
  isOTPPageShown: false,
  isRestart: false,
})

/*
 * Delegator reducer definition
 */

const DelegatorReducer = (state: DelegatorState, action: DelegatorAction) => {
  const { type, ...payload } = action
  switch (type) {
    case DelegatorActionType.SetAuthAttempt:
      return {
        ...state,
        ...payload,
      }
    case DelegatorActionType.SetLogout:
      return {
        ...state,
        isLoggingOut: true,
        isRestart: action.isRestart,
      }
    case DelegatorActionType.SetLogoutFinished:
      return {
        ...state,
        isLoggingOut: false,
      }
    case DelegatorActionType.SetIsOTPPageShown:
      return {
        ...state,
        isOTPPageShown: true,
      }
    default:
      return state
  }
}

/*
 * Main component: Authentication delegator
 */

export const AuthDelegator = (props: {
  children: ReactNode
  initialAttempt: AuthenticationAttempt
  userInfo: UserInfo | null
  refetch: () => void
}) => {
  const config = useConfigContext()
  const history = useHistory()
  const location = useLocation()

  const isReady =
    [
      AuthenticationAttemptState.MTanCallenge,
      AuthenticationAttemptState.Succeeded,
      AuthenticationAttemptState.Expired,
    ].includes(props.initialAttempt.state) ||
    props.userInfo?.state === CustomerAuthenticationState.InitializePassword
  const isSessionExpired = location.pathname.endsWith('session-expired')

  // Reducer
  const [delegatorState, dispatch] = useReducer(
    DelegatorReducer,
    getInitialDelegatorState({ attempt: props.initialAttempt, isReady }),
  )

  // Log in and sign up start mutations
  const onAuthenticationSuccess = (attempt: AuthenticationAttempt) => {
    dispatch({
      type: DelegatorActionType.SetAuthAttempt,
      attempt,
      isError: false,
      isLoading: false,
      isLoggingOut: false,
      isOTPPageShown: false,
      isRestart: true,
    })
    props.refetch()
    if (delegatorState.isOTPPageShown) history.replace('/login')
  }

  const onAuthenticationError = (error: string) => {
    dispatch({
      type: DelegatorActionType.SetAuthAttempt,
      attempt: {
        state: AuthenticationAttemptState.Error,
        error,
      },
      isLoading: true,
      isError: true,
    })
  }

  const { mutateAsync: startLogin } = useMutation(api[Mutation.LoginStart], {
    onSuccess: onAuthenticationSuccess,
    onError: onAuthenticationError,
  })

  const { mutateAsync: endLogin } = useMutation(api[Mutation.LoginEnd], {
    onSuccess: () => {
      props.refetch()
      history.replace('/')
    },
    onError: onAuthenticationError,
  })

  // Kick-off and finalise login flow effect hooks
  useEffect(() => {
    if (
      delegatorState.isLoading &&
      // delegatorState.attempt.state === AuthenticationAttemptState.PasswordChallenge &&
      props.userInfo?.state !== CustomerAuthenticationState.InitializePassword &&
      !isSessionExpired
    ) {
      startLogin()
    }
  }, [delegatorState.isLoading, delegatorState.attempt, props.userInfo, startLogin, isSessionExpired])

  useEffect(() => {
    if (
      delegatorState.attempt.state === AuthenticationAttemptState.Succeeded &&
      delegatorState.attempt.returnUrl
    ) {
      const returnUrl = delegatorState.attempt.returnUrl
      endLogin(returnUrl)
    }
  }, [delegatorState.attempt, endLogin])

  // Set email in test
  useEffect(() => {
    if (config.useMockedTestData && props.userInfo?.name) {
      api[Mutation.TestSetEmail](props.userInfo.name)
    }
  }, [props.userInfo?.name, config.useMockedTestData])

  // Check if user is in authenticated state
  const isAuthenticated = delegatorState.attempt.state === AuthenticationAttemptState.Succeeded

  // Remove query params and redirect to authenticated page for successfully logged in user
  useEffect(() => {
    if (isAuthenticated) history.replace(location.pathname)
  }, [isAuthenticated, history, location.pathname])

  // Throw technical error
  useEffect(() => {
    if (delegatorState.isError && delegatorState.attempt.state === AuthenticationAttemptState.Error) {
      throw new Error(delegatorState.attempt.error)
    }
  }, [delegatorState.isError])

  // Define change attempt and logout actions
  const onAuthAttemptChanged = (attempt: AuthenticationAttempt) =>
    dispatch({ type: DelegatorActionType.SetAuthAttempt, attempt, isLoading: false, isError: false })

  useEffect(() => {
    if (delegatorState.isLoggingOut) {
      api[Mutation.Logout]().then(() => {
        if (delegatorState.isRestart) {
          startLogin()
        } else {
          dispatch({ type: DelegatorActionType.SetLogoutFinished })
        }
      })
    }
  }, [delegatorState.isLoggingOut])

  const onRestartLogin = () => {
    startLogin()
    history.replace('/login')
  }

  const onRestartRegister = () => {
    history.replace('/login/register')
  }

  // Show Loading spinner if authentication data are still loading or the user is logged out
  if ((delegatorState.isLoading || delegatorState.isLoggingOut) && !isSessionExpired)
    return <AuthLoadingOverlay label="authentication.loadingLabel" />

  // Show loading spinner for successfully authenticated user with not yet all data loaded
  if (
    isAuthenticated &&
    (!props.userInfo || props.userInfo.state !== CustomerAuthenticationState.Active)
  )
    return <AuthLoadingOverlay label="user.loadingLabel" />

  return isAuthenticated ? (
    <AuthenticatedUserProvider userInfo={props.userInfo as UserInfo}>
      {props.children}
    </AuthenticatedUserProvider>
  ) : (
    <AuthAttemptContext.Provider value={delegatorState.attempt}>
      <AuthRoutesProvider
        authAttempt={delegatorState.attempt}
        isOTPPageShown={delegatorState.isOTPPageShown}
        isLoggingOut={delegatorState.isLoggingOut}
        onAuthAttemptChanged={onAuthAttemptChanged}
        onAuthAttemptError={onAuthenticationError}
        onLogout={(isRestart: boolean) => dispatch({ type: DelegatorActionType.SetLogout, isRestart })}
        onShowOTPPage={() => dispatch({ type: DelegatorActionType.SetIsOTPPageShown })}
        onRestartLogin={onRestartLogin}
        onRestartRegister={onRestartRegister}
        userInfo={props.userInfo}
      />
    </AuthAttemptContext.Provider>
  )
}
