import { ButtonType } from 'frr-web/lib/components/Button'
import { Form } from 'frr-web/lib/form/components/Form'
import { FormField } from 'frr-web/lib/form/components/types'
import { CSSProperties } from 'styled-components'

import { CardContent } from './CardContent'

type Props = {
  data: any
  formFields: Array<FormField<any>>
  onChange: (formState: any) => void
  buttonLabel: string
  buttonDisabled: boolean
  onSubmit: () => void
  className?: string
  style?: {
    form?: {
      wrapper?: CSSProperties
      content?: CSSProperties
    }
    row?: {
      wrapper?: CSSProperties
      item?: CSSProperties
    }
  }
  disableDirtyValidation?: boolean
}

export const ProfileCard = (props: Props) => {
  return (
    <CardContent>
      <Form
        style={{
          form: {
            wrapper: { ...props.style?.form?.wrapper },
            content: { ...props.style?.form?.content },
            buttonContainer: { position: 'inherit', '@media-mobile': { marginBottom: 24 } },
          },
          row: {
            wrapper: { ...props.style?.row?.wrapper },
            wrapperReadOnly: {},
            item: { ...props.style?.row?.item },
          },
        }}
        data={props.data}
        formFields={props.formFields}
        onChange={props.onChange}
        onSubmit={props.onSubmit}
        buttons={[
          {
            type: ButtonType.Primary,
            disabled: props.buttonDisabled,
            label: props.buttonLabel,
            onClick: ({ submit }) => {
              submit()
            },
          },
        ]}
        disableDirtyValidation={props.disableDirtyValidation}
      />
    </CardContent>
  )
}
