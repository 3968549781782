import CookieBot from 'react-cookiebot'
import '../../styles/cookiebot/cookiebot.css'

type Props = {
  domainGroupId: string
  language: string
}

export const CookieModal = ({ domainGroupId, language }: Props) => {
  return <CookieBot domainGroupId={domainGroupId} language={language} />
}
