import { IonLoading } from '@ionic/react'

export type LoadingOverlayProps = {
  className?: string
  label?: string
  isLoading: boolean
  spinner?:
    | 'bubbles'
    | 'circles'
    | 'circular'
    | 'crescent'
    | 'dots'
    | 'lines'
    | 'lines-small'
    | null
    | undefined
}

export const LoadingOverlay = (props: LoadingOverlayProps) => {
  return (
    <IonLoading
      cssClass={props.className}
      isOpen={props.isLoading}
      message={props.label}
      spinner={props.spinner || 'bubbles'}
    />
  )
}
