import { format as formatDate, isValid as isDateValid } from 'date-fns'
import i18next from 'i18next'
import Backend from 'i18next-locize-backend'
import { locizePlugin } from 'locize'
import { initReactI18next } from 'react-i18next'

import { mapCurrency } from 'components/utils/format'
import { FrontendConfig } from 'types/FrontendConfig'
import { Language } from 'types/Language.d'

import { getLanguage } from './Languages'

// Set (default) locale
const locizeBackendOptions: any = (config: FrontendConfig) => ({
  projectId: config.locizeProductId,
  apiKey: config.locizeApiKey,
  referenceLng: Language.DE_CH,
  version: config.locizeVersion,
})

const ns = ['common', 'accountStatement']
const userLanguage = localStorage.getItem('userLanguage')
const browserLanguage = window.navigator.language as Language

const preselectedLanguage = getLanguage(browserLanguage)

export const i18n = i18next.createInstance()

export const i18nextInit = (config: FrontendConfig) =>
  i18n
    // load translation using the locize service
    .use(Backend)
    .use(locizePlugin)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
      debug: false,

      lng: userLanguage === null ? preselectedLanguage : userLanguage,

      ns,
      fallbackLng: Language.DE_CH,
      fallbackNS: 'common',
      load: 'all', // strategy to define which language codes to lookup

      backend: locizeBackendOptions(config),

      interpolation: {
        escapeValue: false, // react already safes from xss

        format: (value, format) => {
          let formattedValue = value

          if (format === 'Date') {
            formattedValue = isDateValid(value) ? formatDate(value, 'P') : ''
          } else if (format === 'Amount') {
            formattedValue = new Intl.NumberFormat(i18n.language, {
              style: 'currency',
              currency: mapCurrency[i18n.language as Language],
              minimumIntegerDigits: 1,
            }).format(value.amount)
          }

          return formattedValue
        },
      },

      react: {
        bindI18n: 'languageChanged editorSaved',
      },
    })

export const i18nextChangeLanguage = (language: string) => {
  i18n.changeLanguage(language as string)
  localStorage.setItem('userLanguage', language as string)
}
