import { IonPage } from '@ionic/react'
import { api, Mutation } from 'data/api'
import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { useResetPasswordAttemptContext } from 'scaffold/providers/authentication/AuthResetPasswordProvider'
import {
  ResetPasswordAttempt,
  ResetPasswordAttemptChallenge,
  SubmitResetPasswordData,
} from 'types/ResetPassword'
import { ResetPasswordAttemptState } from 'data/authenticationapi/models/ResetPasswordAttempt'

import { ResetPasswordOTPData } from './ResetPasswordOTP.data'
import { ResetPasswordOTPTemplate } from './ResetPasswordOTP.template'
import { useTranslation } from 'react-i18next'

interface OTPPageProps {
  password: string
  resetPasswordToken: string
  onResetPasswordStep: (attempt: ResetPasswordAttempt) => void
  onResetPasswordError: () => void
}

export const ResetPasswordOTPPage = (props: OTPPageProps) => {
  const { i18n } = useTranslation()

  const attempt = useResetPasswordAttemptContext() as
    | ResetPasswordAttemptChallenge
    | { state: ResetPasswordAttemptState.Error; error: string }

  const { mutateAsync: submitCodeAndPassword, isLoading } = useMutation(
    api[Mutation.ResetPasswortSubmitOTPCodeAndPassword],
    {
      onSuccess: props.onResetPasswordStep,
      onError: props.onResetPasswordError,
    },
  )

  const { mutateAsync: resendCode } = useMutation(api[Mutation.ResetPasswortSendOTPCode], {
    onSuccess: props.onResetPasswordStep,
    onError: props.onResetPasswordError,
  })

  const onSubmitCode = (values: SubmitResetPasswordData) => {
    submitCodeAndPassword(values)
  }
  const onResendCode = () => {
    resendCode({ resetPasswordToken: props.resetPasswordToken, language: i18n.language })
  }

  const [otpError, setOTPError] = useState<string | null>(null)
  const otpData = ResetPasswordOTPData({
    attempt: attempt as ResetPasswordAttemptChallenge,
    password: props.password,
    resetPasswordToken: props.resetPasswordToken,
  })

  useEffect(() => {
    if (attempt.state === ResetPasswordAttemptState.Error) {
      setOTPError(attempt.error)
    } else if (
      attempt.currentMTanFailCount >= attempt.maxMTanFailCount ||
      attempt.state === ResetPasswordAttemptState.MTanChallengeFailLimitReached
    ) {
      setOTPError('authentication.errors.maxSubmitOTPCount')
    } else if (!isLoading && attempt.currentMTanFailCount > 0) {
      setOTPError('authentication.errors.invalidCode')
    }
  }, [attempt, isLoading])

  return (
    <IonPage id="otp">
      <ResetPasswordOTPTemplate
        data={otpData.data}
        error={otpError}
        formFields={otpData.formFields}
        isBlocked={otpData.isBlocked}
        isResendDisabled={otpData.isResendDisabled}
        onChange={() => otpError && setOTPError(null)}
        onSubmitCode={(values) => {
          onSubmitCode(values)
          setOTPError(null)
        }}
        onResendCode={() => {
          onResendCode()
          setOTPError(null)
        }}
        phoneNr={otpData.phoneNr}
      />
    </IonPage>
  )
}
