import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api, Query } from 'data/api'
import { downloadFileFromBlob } from 'pages/utils/downloadFile'
import { Loan } from 'types/Loan'

import { PaymentSlipData, PaymentSlipDataProps } from './PaymentSlip.data'
import { PaymentSlipTemplate } from './PaymentSlip.template'

type Props = {
  closeButtonAction: () => void
  loan: Loan
}

export const PaymentSlipPage = (props: Props) => {
  const { t, i18n } = useTranslation()
  const paymentSlipData = PaymentSlipData(
    parseFloat(props.loan.installmentAmount ? `${props.loan.installmentAmount.toFixed(2)}` : '0'),
  )

  const [values, setValues] = useState<PaymentSlipDataProps>(paymentSlipData.data)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const download = () => {
    setIsLoading(true)

    api[Query.FetchPaymentSlip]({
      loanId: props.loan.id,
      amount: parseFloat(`${values.amount}`),
      acceptLanguage: i18n.language,
    })
      .then((blob: Blob) => {
        downloadFileFromBlob(
          blob,
          `${t(
            `paymentSlip.document.download.product_${
              !!props.loan.product?.productId ? props.loan.product.productId.replace(' ', '') : 'default'
            }`,
            { loanId: props.loan.id },
          )}`,
        ).finally(() => {
          setIsLoading(false)
        })
      })
      .catch(() => {
        setIsLoading(false)
      })
  }

  const onSubmit = () => {
    download()
  }

  const paymentSlipTemplateProps = {
    values,
    setValues,
    paymentSlipData,
    onSubmit,
    buttonDisabled: isLoading || parseFloat(`${values.amount}`) === 0,
    style: {
      form: { wrapper: { paddingTop: '12px' } },
      row: { item: { display: 'flex', placeContent: 'space-between' } },
    },
    isLoading,
  }

  return <PaymentSlipTemplate {...paymentSlipTemplateProps} />
}
