/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Address,
    AddressFromJSON,
    AddressFromJSONTyped,
    AddressToJSON,
} from './Address';

/**
 * A bob customer.
 * @export
 * @interface Customer
 */
export interface Customer {
    /**
     * BaseId of the customer.
     * @type {string}
     * @memberof Customer
     */
    id: string;
    /**
     * Language of the customer.
     * @type {string}
     * @memberof Customer
     */
    language?: string | null;
    /**
     * PhoneMobile of the customer.
     * @type {string}
     * @memberof Customer
     */
    phoneMobile?: string | null;
    /**
     * Email of the customer.
     * @type {string}
     * @memberof Customer
     */
    email?: string | null;
    /**
     * Name of the customer.
     * @type {string}
     * @memberof Customer
     */
    firstName?: string | null;
    /**
     * Surname of the customer.
     * @type {string}
     * @memberof Customer
     */
    lastName?: string | null;
    /**
     * Gender of the customer.
     * @type {string}
     * @memberof Customer
     */
    gender?: string | null;
    /**
     * DateOfBirth of the customer.
     * @type {Date}
     * @memberof Customer
     */
    dateOfBirth?: Date | null;
    /**
     * Customer's address.
     * @type {Address}
     * @memberof Customer
     */
    address: Address | null;
}

export function CustomerFromJSON(json: any): Customer {
    return CustomerFromJSONTyped(json, false);
}

export function CustomerFromJSONTyped(json: any, ignoreDiscriminator: boolean): Customer {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'language': !exists(json, 'language') ? undefined : json['language'],
        'phoneMobile': !exists(json, 'phoneMobile') ? undefined : json['phoneMobile'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'gender': !exists(json, 'gender') ? undefined : json['gender'],
        'dateOfBirth': !exists(json, 'dateOfBirth') ? undefined : (json['dateOfBirth'] === null ? null : new Date(json['dateOfBirth'])),
        'address': AddressFromJSON(json['address']),
    };
}

export function CustomerToJSON(value?: Customer | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'language': value.language,
        'phoneMobile': value.phoneMobile,
        'email': value.email,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'gender': value.gender,
        'dateOfBirth': value.dateOfBirth === undefined ? undefined : (value.dateOfBirth === null ? null : value.dateOfBirth.toISOString()),
        'address': AddressToJSON(value.address),
    };
}

