import { IonPage } from '@ionic/react'
import { ButtonType } from 'frr-web/lib/components/Button'
import { useHistory } from 'react-router-dom'

import { AuthInfoTemplate } from './AuthInfo.template'

interface AuthInfoPageProps {
  errorText?: string
  infoText?: string
  onFinish?: () => void // Per default redirect to login
  onFinishLabel?: string
  onFinishButtonType?: ButtonType
  text?: string
  title: string
}

export const AuthInfoPage = (props: AuthInfoPageProps) => {
  const history = useHistory()

  const onFinish = props.onFinish !== undefined ? props.onFinish : () => history.replace('/login')

  return (
    <IonPage id="logout-error-page">
      <AuthInfoTemplate
        title={props.title}
        text={props.text}
        errorText={props.errorText}
        infoText={props.infoText}
        onFinishLabel={props.onFinishLabel || 'authentication.actions.gotoLogin.label'}
        onFinish={onFinish}
        onFinishButtonType={props.onFinishButtonType}
      />
    </IonPage>
  )
}
