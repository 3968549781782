import { UserProps, withUser } from 'data/hocs/withUser'
import React, { ReactNode, useContext } from 'react'

import { CustomerAuthenticationState, UserInfo } from 'types/Authentication.d'
import { CustomerRelationship } from 'types/CustomerRelationship'

const UserContext = React.createContext<CustomerRelationship | null>(null)
UserContext.displayName = 'User Context'

export const useUserContext = (): CustomerRelationship => {
  const user = useContext(UserContext)
  if (!user) {
    throw new Error(`User not found`)
  }
  return user
}

export const useMaybeUserContext = (): CustomerRelationship | null => {
  const user = useContext(UserContext)
  return user
}

const UserInfoContext = React.createContext<UserInfo>({
  name: '',
  preferredUsername: '',
  state: CustomerAuthenticationState.Active,
  sub: '',
})
UserContext.displayName = 'User Info Context'

export const useUserInfoContext = (): UserInfo => {
  const userInfo = useContext(UserInfoContext)
  return userInfo
}

const AuthenticatedUserProviderComponent = (
  props: UserProps & { userInfo: UserInfo; children: ReactNode },
) => {
  return (
    <UserContext.Provider value={props.user}>
      <UserInfoContext.Provider value={props.userInfo}>{props.children}</UserInfoContext.Provider>
    </UserContext.Provider>
  )
}
export const AuthenticatedUserProvider = withUser<{ userInfo: UserInfo; children: ReactNode }>(
  AuthenticatedUserProviderComponent,
)
