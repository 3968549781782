import { ElementType } from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

import { api, Query } from 'data/api'
import { Document } from 'data/openapi/backend'

export interface LoanDocumentsProps {
  documents: Document[]
  isLoadingDocuments: boolean
}

export const withLoanDocuments = <T extends {}>(Component: ElementType) => {
  return (props: T) => {
    const match = useRouteMatch<{ loanId: string }>()

    const { data, isLoading } = useQuery([Query.FetchLoanDocuments, match.params.loanId], () =>
      api[Query.FetchLoanDocuments]({ loanId: match.params.loanId }),
    )

    const componentData = data

    return <Component documents={componentData} isLoadingDocuments={isLoading} {...props} />
  }
}
