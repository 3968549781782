import { renderHtml } from 'frr-web/lib/utils/renderHtml'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { AppHeader } from '../header/AppHeader'
import { Footer } from './Footer'

export type Props = {
  children: ReactNode
  title: string
  description?: string
  descriptionData?: Record<string, any>
}

export const LayoutPageLandingContent = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <AppHeader />

      <ContentOuterWrapper>
        <Content>
          <LeftContent>
            <LeftImage alt="bob" src="/assets/bob-login-persona.png" />
          </LeftContent>
          <RightContent>
            <Card>
              <CardTitleWrapper>
                <CardTitle data-test-id={props.title}>{t(props.title)}</CardTitle>
                {props.description && (
                  <p data-test-id={props.description}>
                    {renderHtml(t(props.description, props.descriptionData))}
                  </p>
                )}
              </CardTitleWrapper>
              <CardContent>{props.children}</CardContent>
            </Card>
          </RightContent>
        </Content>
      </ContentOuterWrapper>

      <Footer
        isOverlay={true}
        textColor='white'
        backgroundColor='var(--primary-blue-5)'
      />
    </Wrapper>
  )
}

const MAX_HEIGHT = 1000
const MIN_HEIGHT = 720

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;

  @media ${DesktopStyle} {
    max-height: ${MAX_HEIGHT}px;
  }
`

const ContentOuterWrapper = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  background: var(--card-background-color, white);

  @media ${MobileStyle} {
    min-height: calc(var(--vh, 1vh) * 100 - 72px);
  }

  @media ${DesktopStyle} {
    max-height: ${MAX_HEIGHT}px;
    height: calc(100% - 168px);
    background: var(--primary-blue-4);
    min-height: ${MIN_HEIGHT}px;
  }
`

const Content = styled.div`
  display: flex;
  flex: 1;

  @media ${MobileStyle} {
    flex-direction: column;
  }

  @media ${DesktopStyle} {
    justify-content: center;
    margin: 0 auto;
    width: 100%;
    flex-direction: row;
    background: var(--primary-blue-4);
    justify-content: space-between;
    max-width: 1200px;
    padding: 32px 48px 0 48px;
  }
`

const LeftContent = styled.div`
  @media ${MobileStyle} {
    display: none;
  }

  @media ${DesktopStyle} {
    flex: 1;
    display: flex;
    flex-direction: column;

    height: 100%;
  }
`

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MobileStyle} {
    flex: 1;
  }

  @media ${DesktopStyle} {
    flex: 0 0 400px;
    height: 100%;
    max-height: 680px;
    padding-bottom: 24px;
  }
`

const LeftImage = styled.img`
  margin-top: auto;
  width: 600px;
`

const Card = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: var(--card-background-color, white);
  max-width: 540px;
  width: 100%;
  height: 100%;

  @media ${DesktopStyle} {
    border-radius: var(--card-border-radius);
    box-shadow: var(--card-shadow);
  }
`

const CardTitle = styled.h3`
  padding-left: 0px;
`

export const CardTitleWrapper = styled.div`
  @media ${DesktopStyle} {
    margin-bottom: 32px;

    h3 {
      margin-bottom: 32px;
    }
  }

  @media ${MobileStyle} {
    margin-bottom: 24px;

    h3 {
      margin-bottom: 24px;
    }
  }
`

const CardContent = styled.div`
  width: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
`
