import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { ChangePasswordRequestParams } from 'data/authenticationapi/models/ChangePassword'
import {
  LoginSubmitCredentialsRequestParams,
  SubmitOTPCodeRequesetParams,
  RegistrationInitalizePasswordRequesetParams,
} from 'data/authenticationapi/models/CustomerAuthentication'
import { ResetPasswordStartRequestParams as RegisterCustomerStartRequestParams } from 'data/authenticationapi/models/ResetPassword'

export enum CustomerAuthenticationState {
  InitializePassword = 'initialize-password', // New customer in sign up process with email token
  Active = 'active', // Existing / Logged in user
}

export type ChangePassword = ChangePasswordRequestParams & {
  newPasswordRetyped: string | null
}

export type CredentialsData = LoginSubmitCredentialsRequestParams
export type InitializePasswordData = RegistrationInitalizePasswordRequesetParams
export type SubmitCodeData = SubmitOTPCodeRequesetParams
export type StartRegistrationFormData = RegisterCustomerStartRequestParams

export interface UserInfo {
  email?: string
  emailToken?: string | null
  language?: string
  name: string
  phoneMobile?: string
  preferredUsername: string
  state: CustomerAuthorizationState
  sub: string
}

export type AuthenticationAttemptChallenge = {
  currentMTanFailCount: number
  currentMTanSendCount: number
  currentPasswordFailCount: number
  email?: string
  id?: string
  isMTanExpired: boolean
  maxMTanFailCount: number
  maxMTanSendCount: number
  maxPasswordFailCount: number
  phoneNr?: string
  state:
    | AuthenticationAttemptState.MTanCallenge
    | AuthenticationAttemptState.MTanChallengeFailLimitReached
    | AuthenticationAttemptState.PasswordChallenge
    | AuthenticationAttemptState.PasswordChallengeFailLimitReached
}

export type AuthenticationAttemptSuccess = {
  email?: string
  id?: string
  returnUrl?: string
  state: AuthenticationAttemptState.Succeeded
}

export type AuthenticationAttempt =
  | AuthenticationAttemptChallenge
  | AuthenticationAttemptSuccess
  | {
      state: AuthenticationAttemptState.None
    }
  | {
      state: AuthenticationAttemptState.Expired
    }
  | {
      state: AuthenticationAttemptState.Error
      error: string
    }

export interface Authentication {}
