import { useTranslation } from 'react-i18next'

import { PaymentSlipPage } from 'pages/loanDetail/paymentSlip/PaymentSlip.page'
import { Loan } from 'types/Loan'

import { Props as ModalProps } from './Modal'
import { ModalWithContent } from './ModalWithContent'

type Props = {
  modal: Omit<ModalProps, 'children'> & {
    title: string
    loan: Loan
  }
}

export const PaymentSlipModal = (props: Props) => {
  const { t } = useTranslation()
  return (
    <ModalWithContent
      title={t(props.modal.title)}
      modal={{
        ...props.modal,
        style: {
          maxWidth: 400,
        },
      }}
      style={{ header: { mobile: { marginLeft: '-16px', marginRight: '-16px' } } }}
    >
      <PaymentSlipPage loan={props.modal.loan} closeButtonAction={props.modal.onClose} />
    </ModalWithContent>
  )
}
