import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import React, { useState } from 'react'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { CredentialsData } from 'types/Authentication'
import { PartnerVariant } from 'types/Loan'

export const AuthRegistrationTemplate = (props: {
  data: CredentialsData
  formFields: Array<FormField<CredentialsData>>
  isLoading: boolean
  name?: string
  onRegistration: (values: CredentialsData) => void
  partnerVariant?: PartnerVariant
}) => {
  const [values, setValues] = useState(props.data)
  const onRegistration = () => {
    props.onRegistration(values)
  }
  return (
    <LayoutPageLandingContent
      title={
        props.partnerVariant ? 'authentication.signup.titleWithPartner' : 'authentication.signup.title'
      }
      description={`authentication.signup.text1`}
    >
      <AuthenticationForm
        data={values}
        formFields={props.formFields}
        onChange={setValues}
        onSubmit={onRegistration}
        buttons={[
          {
            isDisabled: (data) => props.isLoading || data.password === null || data.password === '',
            label: 'authentication.actions.signup.label',
            onClick: ({ submit }) => submit(),
            type: ButtonType.Primary,
          },
        ]}
      />
    </LayoutPageLandingContent>
  )
}
