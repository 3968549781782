import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { LayoutPage, Props as PageProps } from 'components/layout/LayoutPage'
import { formatAmount } from 'components/utils/format'
import { LoanStateEnum } from 'data/openapi/backend'
import { i18n } from 'scaffold/translation/initI18next'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { Loan } from '../../types/Loan.d'

export type Props = {
  loan: Loan
  children: ReactNode
  pageProps?: Omit<PageProps, 'children'>
}

export const LoanDetailContent = (props: Props) => {
  const { t } = useTranslation()

  /*   const navItems: Array<{
    label: string
    path?: string
    onClick?: () => void
  }> = [
    {
      label: 'loanDetail.actions.overview.label',
      path: 'overview',
    },
    {
      label: 'loanDetail.actions.documents.label',
      path: 'documents',
    },
    {
      label: 'loanDetail.actions.accountStatement.label',
      onClick: () => showToast('Preparing account statement ...', ToastType.Info),
    },
    {
      label: 'loanDetail.actions.paymentSlips.label',
      onClick: () => showToast('Go to page to make a payment ...', ToastType.Info),
    },
    {
      label: 'loanDetail.actions.finalStatement.label',
      onClick: () => showToast('Preparing final statement ...', ToastType.Info),
    },
  ] */

  // TODO: rollback changes when Story 7274 enters in phase 2 -> https://dev.azure.com/bob-finance/Bob%20Credit%20Relaunch/_workitems/edit/7274
  return (
    <LayoutPage {...(props.pageProps || {})}>
      {/* {props.loan.state === LoanStateEnum.ActiveInArrears && props.loan.overdueAmount && (
        <Notification>
          <h5>
            {t('loan.notification.inArrears', {
              amount: formatAmount(i18n)({ value: props.loan.overdueAmount }),
            })}
          </h5>
        </Notification>
      )} */}
      <DetailWrapper>
        {/* <NavigationColumn list={navItems} basePath={`/loan/${props.loan.id}/detail`} /> */}
        {props.children}
      </DetailWrapper>
    </LayoutPage>
  )
}

const DetailWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;

  @media ${DesktopStyle} {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
`

const Notification = styled.div`
  background-color: var(--error-red-1);
  display: flex;
  height: 80px;
  margin: 0 -16px;
  justify-content: center;
  align-items: center;

  > h5 {
    color: white;

    @media ${MobileStyle} {
      font-size: unset;
    }
  }

  @media ${DesktopStyle} {
    margin: 0;
  }
`
