import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { AuthenticationAttemptChallenge, SubmitCodeData } from 'types/Authentication'
import { PartnerVariant } from 'types/Loan'

const otpFormLens = makeFormLens<SubmitCodeData>()

const otpFormFields: Array<FormField<SubmitCodeData>> = [
  {
    type: FormFieldType.FormSection,
    fields: [
      {
        type: FormFieldType.CodeInput,
        label: { label: 'authentication.formFields.code.label' },
        length: 6,
        lens: otpFormLens(['code']),
        required: true,
        isAutoFocus: true,
      },
    ],
  },
]

interface OTPDataResult {
  data: SubmitCodeData
  formFields: Array<FormField<SubmitCodeData>>
  isExpired: boolean
  isBlocked: boolean
  isResendDisabled: boolean
  phoneNr?: string
  partnerVariant?: PartnerVariant
}

export const AuthOTPData = (params: { attempt: AuthenticationAttemptChallenge }): OTPDataResult => {
  const isBlocked =
    params.attempt.state !== AuthenticationAttemptState.MTanCallenge ||
    params.attempt.currentMTanFailCount >= params.attempt.maxMTanFailCount

  return {
    data: { code: '' },
    formFields: otpFormFields,
    isBlocked,
    isExpired: !isBlocked && params.attempt.isMTanExpired,
    isResendDisabled: params.attempt.currentMTanSendCount >= params.attempt.maxMTanSendCount,
    phoneNr: params.attempt.phoneNr?.replaceAll('#', '*'),
  }
}
