import { Card } from 'components/card/Card'
import { CardContent } from 'components/card/CardContent'
import { DocumentsList } from 'components/DocumentsList'
import { DocumentTypeEnum } from 'data/openapi/backend'
import { DocumentsList as DocumentsListProps } from 'pages/loanDetail/documentsDownload/DocumentsDownload.page'

type Props = {
  onDownload: (key: DocumentTypeEnum) => void
  documentsList: DocumentsListProps
}

export const DocumentsDownloadTemplate = (props: Props) => {
  const { documentsList, onDownload } = props

  return (
    <>
      <CardContent>
        <Card>
          <DocumentsList documentsList={documentsList} onDownload={onDownload} />
        </Card>
      </CardContent>
    </>
  )
}
