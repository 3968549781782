import { IonRouterOutlet } from '@ionic/react'
import { useTranslation } from 'react-i18next'

import { PageContent } from 'components/layout/PageContent'

type Props = {
  children: React.ReactNode
}

export const ProfileTemplate = (props: Props) => {
  const { t } = useTranslation()
  return (
    <IonRouterOutlet id="profile-pages">
      <PageContent
        pageProps={{
          header: {
            title: t('profile.details.title.label'),
            goBack: {
              to: '/loans',
            },
          },
        }}
      >
        {props.children}
      </PageContent>
    </IonRouterOutlet>
  )
}
