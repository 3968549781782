import { Language } from 'types/Language.d'

export const mapCurrency: Record<Language, string> = {
  [Language.DE_CH]: 'CHF',
  [Language.FR_CH]: 'CHF',
  [Language.IT_CH]: 'CHF',
  [Language.EN]: 'CHF',
}

export const formatAmount =
  (i18n: { language: string }) =>
  (params: {
    value: number
    maximumFractionDigits?: number
    exclCurrency?: boolean
    showSign?: boolean
  }) => {
    const { value, maximumFractionDigits, exclCurrency, showSign } = params

    const numberFormatter = new Intl.NumberFormat(i18n.language, {
      style: 'currency',
      currency: mapCurrency[i18n.language as Language],
      currencyDisplay: 'code',
      maximumFractionDigits,
    })

    let formattedAmount = numberFormatter
      .formatToParts(value)
      .filter((part) => !exclCurrency || part.type !== 'currency')
      .map((part) => part.value)
      .join('')
      .trim()

    if (showSign && formattedAmount.substring(0, 1) !== '-') {
      formattedAmount = `+${formattedAmount}`
    }

    return formattedAmount
  }

// Business requiremnt is to always use swiss format for dates
export const formatDate = (i18n: { language: string }) => (date: string | Date) =>
  new Intl.DateTimeFormat('de-CH', {
    dateStyle: 'medium',
  }).format(new Date(date))

export const formatPercentage = (value: number) => `${value.toFixed(2)}%`
