import format from 'date-fns/format'

export const downloadFile = async (url: string, fileName: string) => {
  const response = await fetch(url, {
    mode: 'no-cors',
    method: 'GET',
    headers: { 'Content-Type': 'application/pdf' },
  })
  const blob = await response.blob()

  const link = document.createElement('a')
  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute('download', fileName)
  link.click()
  window.URL.revokeObjectURL(url)
}

export const downloadFileFromBlob = async (blob: Blob, fileName: string) => {
  const link = document.createElement('a')
  link.setAttribute('href', window.URL.createObjectURL(blob))
  link.setAttribute('download', fileName)
  link.click()
}

export const getDateAndTimeAsString = (date: Date) =>
  `${format(date, 'yyyy-MM-dd')}T${format(date, 'HH:mm:ss')}`

export const getDateAsString = (date: Date) => `${format(date, 'dd.MM.yyyy')}`
