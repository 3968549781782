import { AuthenticationAttempt } from 'types/Authentication.d'
import {
  AuthenticationAttempt as AuthenticationAttemptBackend,
  AuthenticationAttemptState,
} from '../../models/AuthenticationAttempt'

export const mapAuthenticationAttempt = async (response: Response) => {
  let mappedAttempt

  if (response.status === 401) {
    // Status 401 = Token is expired
    mappedAttempt = {
      state: AuthenticationAttemptState.Expired,
    }
  } else if (response.status === 400) {
    // Status 400 (as response) = Special case of "successful" password challenge with blocked user after reload or in a new browser
    mappedAttempt = {
      currentMTanFailCount: 0,
      currentMTanSendCount: 0,
      currentPasswordFailCount: 5,
      phoneNr: '',
      email: '',
      id: 'none',
      maxMTanFailCount: 5,
      maxMTanSendCount: 5,
      maxPasswordFailCount: 5,
      state: AuthenticationAttemptState.PasswordChallengeFailLimitReached,
    }
  } else {
    const attempt = (await response.json()) as AuthenticationAttemptBackend

    mappedAttempt = ([
      AuthenticationAttemptState.MTanCallenge,
      AuthenticationAttemptState.MTanChallengeFailLimitReached,
      AuthenticationAttemptState.PasswordChallenge,
      AuthenticationAttemptState.PasswordChallengeFailLimitReached,
    ].includes(attempt.state) && {
      currentMTanFailCount: attempt.mTanFailCount,
      currentMTanSendCount: attempt.mTanSendCount,
      currentPasswordFailCount: attempt.passwordFailCount,
      phoneNr: attempt.phone,
      email: attempt.email,
      id: attempt.id,
      isMTanExpired: false,
      maxMTanFailCount: attempt.maxMTanFailCount,
      maxMTanSendCount: attempt.maxMTanSendCount,
      maxPasswordFailCount: attempt.maxPasswordFailCount,
      state: attempt.state,
    }) || {
      email: attempt.email,
      id: attempt.id,
      returnUrl: attempt.returnUrl,
      state: attempt.state,
      version: attempt.version,
    }
  }
  return mappedAttempt as AuthenticationAttempt
}
