import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ButtonType } from 'frr-web/lib/components/Button'
import styled from 'styled-components'

import {
  DocumentNonSwissNationalityOptions,
  DocumentSwissNationalityOptions,
  DocumentType,
} from 'types/Loan.d'
import { UploadFileRow } from 'components/UploadFileRow'
import { usePhotoGallery } from 'hooks/usePhotoGallery.hook'

import { ModalWithContent } from './ModalWithContent'
import { Props as ModalProps } from './Modal'

type Props = {
  idDocuments: {
    documentsOptions: Array<DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions>
    documentOptionSelected: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null
    setDocumentOptionSelected: (
      v: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null,
    ) => void
    documents: Array<DocumentType>
  }
  modal: Omit<ModalProps, 'children'>
  onChange: (files: Record<string, File | undefined>) => void
  onRemove: (itemType: string) => void
}

enum IdentityType {
  GovernmentId = 'GovernmentId',
  DriverLicense = 'DriverLicense',
  ResidencyPermit = 'ResidencyPermit',
  Passport = 'Passport',
}

const mapOptionToIdentityType: Record<
  DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions,
  IdentityType
> = {
  [DocumentSwissNationalityOptions.SwissID]: IdentityType.GovernmentId,
  [DocumentSwissNationalityOptions.SwissPassport]: IdentityType.Passport,
  [DocumentSwissNationalityOptions.SwissDrivingLicense]: IdentityType.DriverLicense,
  [DocumentNonSwissNationalityOptions.Passport]: IdentityType.Passport,
  [DocumentNonSwissNationalityOptions.IdentityCard]: IdentityType.GovernmentId,
  [DocumentNonSwissNationalityOptions.IdentityPaper]: IdentityType.GovernmentId,
  [DocumentNonSwissNationalityOptions.ResidencePermit]: IdentityType.ResidencyPermit,
}

const mapIdentityTypeToIcon: Record<IdentityType, string> = {
  [IdentityType.DriverLicense]: '/assets/identity/drivers-license.svg',
  [IdentityType.GovernmentId]: '/assets/identity/government-id.svg',
  [IdentityType.Passport]: '/assets/identity/passport.svg',
  [IdentityType.ResidencyPermit]: '/assets/identity/residency-permit.svg',
}

type IdentityOption = {
  type: IdentityType
  documentType: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions
  label: string
  icon: string
}

export const IdentityModal = (props: Props) => {
  const { t } = useTranslation()
  const [type, setType] = useState<IdentityOption>()
  const { takePhoto, files, setFiles } = usePhotoGallery()

  const options: Array<IdentityOption> = props.idDocuments.documentsOptions.map((type) => ({
    type: mapOptionToIdentityType[type],
    documentType: type,
    label: `document.type.${type}`,
    icon: mapIdentityTypeToIcon[mapOptionToIdentityType[type]],
  }))

  const items: Array<{
    label: string
    type: DocumentType
    uploadLabel?: string
    required: boolean
    onUploadClick?: () => void
    file?: File
  }> = props.idDocuments.documents.map((document) => ({
    label: `${document}`,
    type: document,
    required: true,
    file: files[document],
  }))

  return (
    <ModalWithContent
      title={t('identity.modal.title')}
      description={t('identity.modal.description')}
      modal={{
        ...props.modal,
      }}
    >
      <div style={{ marginTop: 16 }}>
        {type === undefined ? (
          <OptionsWrapper>
            {options.map((option, key) => (
              <OptionItem
                key={key}
                onClick={() => {
                  setType(option)
                  props.idDocuments.setDocumentOptionSelected(option.documentType)
                }}
              >
                <img alt={option.icon} src={option.icon} style={{ width: 80 }}></img>
                <h5 style={{ marginTop: 24 }}>{t(option.label)}</h5>
              </OptionItem>
            ))}
          </OptionsWrapper>
        ) : (
          <div>
            <h5 style={{ marginBottom: 16 }}> {t(type.label)}</h5>
            {props.idDocuments.documents.map((document: DocumentType, index: number) => (
              <UploadFileRow
                key={`${document}_${index}`}
                file={files[document]}
                type={document}
                onRemove={() => {
                  setFiles({ ...files, [document]: undefined })
                }}
                onChange={(newFiles) => {
                  setFiles({
                    ...files,
                    ...newFiles.reduce((obj, i) => ({ ...obj, [i.type]: i.file }), {}),
                  })
                }}
                onTakePhoto={takePhoto}
              />
            ))}
            <ButtonWrapper>
              <Button
                label="Back"
                onClick={() => {
                  setType(undefined)
                  items.forEach((document) => setFiles({ ...files, [document.type]: undefined }))
                  props.idDocuments.setDocumentOptionSelected(null)
                }}
                type={ButtonType.Chromeless}
              />
              <Button
                label="Submit"
                onClick={() => {
                  props.onChange(files)
                  props.modal.onClose()
                }}
                type={ButtonType.Primary}
              />
            </ButtonWrapper>
          </div>
        )}
      </div>
    </ModalWithContent>
  )
}

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 48px;
  justify-content: space-between;
`

const OptionItem = styled.div`
  width: 200px;
  height: 200px;
  border-radius: 8px;
  flex-direction: column;
  border: 1px solid var(--neutral-gray-2);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all ease 0.3s;
  margin: 16px;

  &:hover {
    border-color: var(--primary-blue-5);
    background: var(--primary-blue-1);
    cursor: pointer;
  }
`

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`
