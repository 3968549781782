import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { DeprecatedLanguage } from 'frr-web/lib/theme/language'

import { CardDataList } from 'components/CardDataList'
import { ChangeCustomerDataModal } from 'components/modal/ChangeCustomerData.modal'
import { ChangePasswordModal } from 'components/modal/ChangePassword.modal'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { CustomerRelationship } from 'types/CustomerRelationship'

import { LoanDataRow, ValueType } from 'types/Loan.d'
import { mapLanguageToLocizeKey } from 'pages/utils/mapLanguageToLocizeKey'

export type Props = {
  user: CustomerRelationship
}

export const Information = (props: Props) => {
  const { t } = useTranslation()
  const [passwordModal, setPasswordModal] = useState(false)
  const [customerDataModal, setCustomerDataModal] = useState(false)

  const detailList: Array<LoanDataRow> = [
    {
      dataTestId: 'salutation',
      label: 'profile.details.salutation.label',
      value:
        props.user.gender === 'Male'
          ? t('profile.salutation.male.label')
          : t('profile.salutation.female.label'),
      type: ValueType.Text,
    },

    {
      dataTestId: 'firstName',
      label: 'profile.details.firstName.label',
      value: props.user.firstName,
      type: ValueType.Text,
    },

    {
      dataTestId: 'lastName',
      label: 'profile.details.lastName.label',
      value: props.user.lastName,
      type: ValueType.Text,
    },

    {
      dataTestId: 'dateOfBirth',
      label: 'profile.details.dateOfBirth.label',
      value: props.user.dateOfBirth,
      type: ValueType.Date,
    },

    {
      dataTestId: 'streetWithNumber',
      label: 'profile.details.streetWithNumber.label',
      value: `${props.user.address?.street ? props.user.address.street : ' '}`,
      type: ValueType.Text,
    },

    {
      dataTestId: 'zipAndCity',
      label: 'profile.details.zipAndCity.label',
      value: `${props.user.address?.zipCode ? props.user.address.zipCode : ''} ${
        props.user.address?.city ? props.user.address.city : ''
      }`,
      type: ValueType.Text,
    },

    {
      dataTestId: 'language',
      label: 'profile.details.language.label',
      value: props.user.language
        ? mapLanguageToLocizeKey[props.user.language as DeprecatedLanguage]
        : 'profile.details.language.value',
      type: ValueType.Text,
    },

    {
      dataTestId: 'email',
      label: 'profile.details.email.label',
      labelStyle: { width: 220 },
      value: props.user.email,
      type: ValueType.Text,
      style: { overflow: 'hidden' },
    },

    {
      dataTestId: 'phoneMobile',
      label: 'profile.details.phoneMobile.label',
      value: props.user.phoneMobile,
      type: ValueType.Text,
    },
  ]

  return (
    <>
      <ChangePasswordModal
        user={props.user}
        modal={{
          isOpen: passwordModal,
          onClose: () => {
            setPasswordModal(false)
          },
        }}
      />
      <ChangeCustomerDataModal
        user={props.user}
        modal={{
          isOpen: customerDataModal,
          onClose: () => {
            setCustomerDataModal(false)
          },
        }}
      />
      <CardDataList list={detailList} reverse>
        <ButtonWrapper>
          <LeftButtonWrapper>
            <Button
              override={{ maxWidth: '100%' }}
              label="profile.button.changeAddress"
              type={ButtonType.Chromeless}
              onClick={() => {
                setCustomerDataModal(true)
              }}
            />
          </LeftButtonWrapper>
          <RightButtonWrapper>
            <Button
              override={{ maxWidth: '100%' }}
              label="profile.button.changePassword"
              type={ButtonType.Chromeless}
              onClick={() => {
                setPasswordModal(true)
              }}
            />
          </RightButtonWrapper>
        </ButtonWrapper>
      </CardDataList>
    </>
  )
}

const ButtonWrapper = styled.div`
  margin-top: 16px;
  padding: 16px;

  @media ${DesktopStyle} {
    display: flex;
    width: 100%;
  }

  @media ${MobileStyle} {
    display: flex;
    flex-direction: column-reverse;
  }
`

const LeftButtonWrapper = styled.div`
  @media ${DesktopStyle} {
    width: 100%;
    float: left;
  }
  @media ${MobileStyle} {
  }
`
const RightButtonWrapper = styled.div`
  @media ${DesktopStyle} {
    margin-left: 8px;
    width: 100%;
    float: left;
  }
  @media ${MobileStyle} {
    margin-bottom: 8px;
  }
`
