import { OnboardingRoute } from 'pages/loanOnboarding/LoanOnboarding.page'
import { Loan, LoanApplicationState } from 'types/Loan.d'

export const mapLoanLink: Record<LoanApplicationState, (loan: Loan) => string> = {
  [LoanApplicationState.UploadApplicationDocuments]: ({ id }) =>
    `/loan/${id}/onboarding/${OnboardingRoute.UploadDocuments}`,
  [LoanApplicationState.ContractReady]: ({ id }) =>
    `/loan/${id}/onboarding/${OnboardingRoute.ContractOffer}`,
  [LoanApplicationState.ContactDocumentsApproved]: ({ id }) => `/loan/${id}`,
  [LoanApplicationState.UploadSignedContract]: ({ id }) =>
    `/loan/${id}/onboarding/${OnboardingRoute.ContractUpload}`,
  [LoanApplicationState.LoanFinished]: () => '/404',
  [LoanApplicationState.LoanPaymentOverdue]: () => '/404',
  [LoanApplicationState.AwaitingContractDocumentsReview]: () => '/404',
  [LoanApplicationState.LoanActive]: () => '/404',
}
