// TEST
import {
  ResetPasswordAttempt,
  ResetPasswordAttemptChallenge,
  ResetPasswordAttemptSuccess,
} from 'types/ResetPassword.d'
import {
  ResetPasswordStartRequestParams,
  ResetTokenRequestParams,
  SubmitOTPCodeAndPasswordRequestParams,
} from '../models/ResetPassword'
import {
  ResetPasswordAttempt as ResetPasswordAttemptBackend,
  ResetPasswordAttemptState,
} from '../models/ResetPasswordAttempt'
import { get, post } from '../runtime'

const CustomerIDPPath = {
  FetchResetPasswordAttempt: `/idp/passwordResetAttempt`,
  StartResetPasswordAttempt: `/idp/passwordResetAttempt`,
  ResendOTPCode: `/idp/passwordResetAttempt/sendMtan`,
  SubmitOTPCodeAndPassword: `/idp/passwordResetAttempt/submitMtanAndPassword`,
}

const mapResetPasswordAttempt = async (response: Response) => {
  let mappedAttempt

  // Status 401 = Token is expired
  if (response.status === 401) {
    mappedAttempt = {
      state: ResetPasswordAttemptState.Expired,
    }
  } else {
    const attempt = (await response.json()) as ResetPasswordAttemptBackend

    mappedAttempt = ([
      ResetPasswordAttemptState.MTanCallenge,
      ResetPasswordAttemptState.MTanChallengeFailLimitReached,
    ].includes(attempt.state) && {
      currentMTanFailCount: attempt.mTanFailCount,
      currentMTanSendCount: attempt.mTanSendCount,
      phoneNr: attempt.phone,
      email: attempt.email,
      id: attempt.id,
      maxMTanFailCount: attempt.maxMTanFailCount,
      maxMTanSendCount: attempt.maxMTanSendCount,
      state: attempt.state,
    }) || {
      email: attempt.email as string,
      id: attempt.id as string,
      state: attempt.state,
      version: attempt.version,
    }
  }

  return mappedAttempt as ResetPasswordAttempt
}

export const IntialResetPasswordAttempt: ResetPasswordAttemptChallenge = {
  currentMTanFailCount: 0,
  currentMTanSendCount: 0,
  maxMTanFailCount: 5,
  maxMTanSendCount: 5,
  state: ResetPasswordAttemptState.MTanCallenge,
}

export const SucceededResetPasswordAttempt: ResetPasswordAttemptSuccess = {
  state: ResetPasswordAttemptState.Succeeded,
}

export const ResetPasswordApi = {
  /*
   * Queries
   */

  FetchResetPasswordAttempt: async (resetPasswordToken: string): Promise<ResetPasswordAttempt> => {
    const idpResponse = await get(CustomerIDPPath.FetchResetPasswordAttempt, {
      Authorization: `Bearer ${resetPasswordToken}`,
    })
    if (![200, 401].includes(idpResponse.status))
      throw new Error('technical error while submitting mtan')

    return await mapResetPasswordAttempt(idpResponse)
  },

  /*
   * Customer IDP endpoints
   */

  ResetPasswordStart: async (values: ResetPasswordStartRequestParams): Promise<void> => {
    const idpResponse = await post(
      CustomerIDPPath.StartResetPasswordAttempt,
      { email: values.email },
      { 'Accept-Language': values.language },
    )

    if (![204].includes(idpResponse.status))
      throw new Error('technical error while starting reset password')

    return
  },

  SubmitOTPCodeAndPassword: async (
    values: SubmitOTPCodeAndPasswordRequestParams,
  ): Promise<ResetPasswordAttempt> => {
    const idpResponse = await post(
      CustomerIDPPath.SubmitOTPCodeAndPassword,
      {
        mtan: values.code,
        password: values.password,
      },
      { Authorization: `Bearer ${values.resetPasswordToken}` },
    )

    if (![200, 401].includes(idpResponse.status))
      throw new Error('technical error while submitting mtan and new password')

    return await mapResetPasswordAttempt(idpResponse)
  },

  SendOTPCode: async (values: ResetTokenRequestParams): Promise<ResetPasswordAttempt> => {
    const idpResponse = await post(CustomerIDPPath.ResendOTPCode, null, {
      Authorization: `Bearer ${values.resetPasswordToken}`,
      'Accept-Language': values.language,
    })

    if (![200, 401].includes(idpResponse.status)) throw new Error('technical error while sending mtan')

    return await mapResetPasswordAttempt(idpResponse)
  },
}
