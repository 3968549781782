import { ReactNode } from 'react'
import { i18nextInit } from '../translation/initI18next'
import { useConfigContext } from './ConfigProvider'

export const TranslationProvider = (props: { children: ReactNode }) => {
  const config = useConfigContext()

  i18nextInit(config)

  return <>{props.children}</>
}
