import styled, { CSSProperties } from 'styled-components'
import { Button, Props as ButtonProps } from 'frr-web/lib/components/Button'
import { ReactNode } from 'react'

import { LayoutPage } from 'components/layout/LayoutPage'
import { Card } from 'components/card/Card'

export type Props = {
  title: string
  description: string
  leftButton?: ButtonProps
  rightButton?: ButtonProps
  children: ReactNode
  style?: CSSProperties
}

export const OnboardingContent = (props: Props) => {
  return (
    <LayoutPage>
      <Card
        style={{
          width: '100%',
          margin: '48px auto 0 auto',
          display: 'flex',
          flexDirection: 'column',
          minHeight: 600,
        }}
      >
        <Header>
          <h3>{props.title}</h3>
          <p style={{ marginTop: 12 }}>{props.description}</p>
        </Header>
        <Content style={props.style}>{props.children}</Content>
        <Footer>
          {props.leftButton && <Button {...props.leftButton} />}
          {props.rightButton && <Button {...props.rightButton} />}
        </Footer>
      </Card>
    </LayoutPage>
  )
}

const Header = styled.div`
  padding: 16px 24px;
`

const Content = styled.div`
  flex: 1;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

const Footer = styled.div`
  padding: 0 24px 16px 24px;
  flex: 0 0 64px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`
