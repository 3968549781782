import { SeverityLevel } from '@microsoft/applicationinsights-common'

export enum TrackEventType {
  Exception = 'Exception',
}

export type TrackException = {
  type: TrackEventType.Exception
  exception: Error
  severity: SeverityLevel
}

export type TrackEvent = TrackException

export type TrackEventData = {
  errors?: Array<string>
}

// App Insights types
export type AppInsights_TrackProperties = {
  errors?: Array<string>
}
