// providers
import { IonApp } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import debounce from 'debounce'
import { Suspense } from 'react'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from 'react-query'

import { ErrorBoundary } from './ErrorBoundry'
import { AppInsightsTrackingProvider } from './providers/AppInsightsProvider'
import { BrowserCompatibility } from './providers/BrowserCompatibility'
import { ConfigProvider } from './providers/ConfigProvider'
import { CookieProvider } from './providers/CookieProvider'
import { LanguageProvider } from './providers/LanguageProvider'
import { RoutesProvider } from './providers/RoutesProvider'
import { ThemeProvider } from './providers/ThemeProvider'
import { TranslationProvider } from './providers/TranslationProvider'
import { AuthProvider } from './providers/authentication/AuthProvider'

const queryClient = new QueryClient()

// Adjust view height for mobile devices (2nd part for resizing)
// Source: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
const setViewHeightUnit = () => {
  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  const vh = window.innerHeight * 0.01
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`)
}

window.addEventListener('resize', debounce(setViewHeightUnit, 100))

// Set initial view height unit
setViewHeightUnit()

export const App = () => {
  return (
    <IonApp>
      <HelmetProvider>
        <AppInsightsTrackingProvider>
          <ErrorBoundary>
            <Suspense fallback={null}>
              <QueryClientProvider client={queryClient}>
                <ConfigProvider>
                  <TranslationProvider>
                    <BrowserCompatibility>
                      <ThemeProvider>
                        <IonReactRouter>
                          <LanguageProvider>
                            <CookieProvider />
                            <AuthProvider>
                              <RoutesProvider />
                            </AuthProvider>
                          </LanguageProvider>
                        </IonReactRouter>
                      </ThemeProvider>
                    </BrowserCompatibility>
                  </TranslationProvider>
                </ConfigProvider>
              </QueryClientProvider>
            </Suspense>
          </ErrorBoundary>
        </AppInsightsTrackingProvider>
      </HelmetProvider>
    </IonApp>
  )
}
