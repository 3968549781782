import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { NewPassword, SubmitPasswordData } from 'types/ResetPassword'

export const ResetPasswordTemplate = (props: {
  data: NewPassword
  error: string | null
  formFields: (data: NewPassword) => Array<FormField<NewPassword>>
  isError: boolean
  isSuccess: boolean
  buttonDisabled: boolean
  onChange: (data: NewPassword) => void
  onFinish: () => void
  onSetPassword: (values: SubmitPasswordData) => void
  successFormFields: Array<FormField<{}>>
  disableDirtyValidation?: boolean
  setDisableDirtyValidation?: (value: boolean) => void
}) => {
  const onSetPassword = () => {
    !!props.data.newPassword && props.onSetPassword({ password: props.data.newPassword })
  }

  const onChange = (values: NewPassword) => {
    props.onChange(values)
    if (values.newPassword === null || values.newPassword === '') {
      props.setDisableDirtyValidation?.(true)
    }
    if (!!values.newPassword && values.newPassword !== '') {
      props.setDisableDirtyValidation?.(false)
    }
  }

  return (
    <LayoutPageLandingContent
      title={`authentication.resetPassword.${
        (props.isSuccess && 'successTitle') || (props.isError && 'errorTitle') || 'title'
      }`}
      description={
        props.isError
          ? undefined
          : (props.isSuccess && 'authentication.resetPassword.successText1') ||
            `authentication.resetPassword.text1`
      }
    >
      {props.isSuccess || props.isError ? (
        <AuthenticationForm
          data={{ newPassword: null, newPasswordRetyped: null }}
          dataTestId={props.isSuccess ? 'success-form' : 'error-form'}
          error={props.error}
          formFields={props.isSuccess ? props.successFormFields : []}
          onSubmit={props.onFinish}
          buttons={[
            {
              label: 'authentication.actions.gotoLogin.label',
              onClick: ({ submit }) => submit(),
              type: ButtonType.Primary,
            },
          ]}
        />
      ) : (
        <AuthenticationForm
          data={props.data}
          error={props.error}
          formFields={props.formFields(props.data)}
          onChange={onChange}
          onSubmit={onSetPassword}
          buttons={[
            {
              isDisabled: () => props.buttonDisabled,
              label: 'authentication.actions.resetPassword.label',
              onClick: ({ submit }) => submit(),
              type: ButtonType.Primary,
            },
          ]}
          disableDirtyValidation={props.disableDirtyValidation}
        />
      )}
    </LayoutPageLandingContent>
  )
}
