import { Loan, LoanDataRow, ValueType } from 'types/Loan.d'
import { LoanStateEnum } from 'data/openapi/backend'

enum DataRow {
  ContractId = 'ContractId',
  Amount = 'Amount',
  InterestRate = 'InterestRate',
  Duration = 'Duration',
  InterestAmount = 'InterestAmount',
  InterestRateAmount = 'InterestRateAmount',
  Insurance = 'Insurance',
  InstallmentAmount = 'InstallmentAmount',
  RemainingAmount = 'RemainingAmount',
  NextInstallmentDate = 'NextInstallmentDate',
  InArrearsAmount = 'InArrearsAmount',
  PaidAmount = 'PaidAmount',
  TotalAmount = 'TotalAmount',
  Separator = 'Separator',
}

export const dataRows: Record<DataRow, (loan: Loan) => LoanDataRow> = {
  [DataRow.ContractId]: (loan) => ({
    label: 'loanDetail.details.contractId.label',
    value: loan?.contractId,
    type: ValueType.Text,
  }),
  [DataRow.Amount]: (loan) => ({
    label: 'loanDetail.details.amount.label',
    value: loan?.principalAmount,
    type: ValueType.Amount,
  }),
  [DataRow.InterestRate]: (loan) => ({
    label: 'loanDetail.details.interestRate.label',
    value: loan?.interestRate,
    type: ValueType.Percentage,
  }),
  [DataRow.Duration]: (loan) => ({
    label: 'loanDetail.details.duration.label',
    value: loan?.durationMonths,
    type: ValueType.Duration,
  }),
  [DataRow.InterestAmount]: (loan) => ({
    label: 'loanDetail.details.interestAmount.label',
    value: (loan?.totalCostAmount || 0) - (loan?.principalAmount || 0),
    type: ValueType.Amount,
  }),
  [DataRow.TotalAmount]: (loan) => ({
    label: 'loanDetail.details.totalAmount.label',
    value: loan?.totalCostAmount,
    type: ValueType.Amount,
  }),
  [DataRow.InterestRateAmount]: (loan) => ({
    label: 'loanDetail.details.interestRateAmount.label',
    value: loan?.totalCostAmount,
    type: ValueType.Amount,
  }),
  [DataRow.Insurance]: (loan) => ({
    label: 'loanDetail.details.insurance.label',
    value: loan ? (loan.hasPpi && 'yes') || 'no' : undefined,
    type: ValueType.Text,
  }),
  [DataRow.InstallmentAmount]: (loan) => ({
    label: loan?.hasPpi
      ? 'loanDetail.details.installmentAmountInclPpi.label'
      : 'loanDetail.details.installmentAmount.label',
    value: loan?.installmentAmount,
    type: ValueType.Amount,
  }),
  [DataRow.RemainingAmount]: (loan) => ({
    label: 'loanDetail.details.repayment.remainingAmount.label',
    value: loan?.remainingAmount,
    type: ValueType.Amount,
  }),
  [DataRow.NextInstallmentDate]: (loan) => ({
    label: 'loanDetail.details.arrears.nextDate.label',
    value: loan?.nextPaymentAt,
    type: ValueType.Date,
  }),
  [DataRow.InArrearsAmount]: (loan) => ({
    label: 'loanDetail.details.arrears.amount.label',
    value: loan?.overdueAmount,
    type: ValueType.Amount,
    style: {
      color:
        loan.state === LoanStateEnum.ActiveInArrears
          ? 'var(--error-red-1)'
          : 'var(  --secondary-yellow-2)',
      fontWeight: 600,
    },
  }),
  [DataRow.PaidAmount]: (loan) => ({
    label: 'loanDetail.details.repayment.paidAmount.label',
    value: (loan?.totalCostAmount || 0) - (loan?.remainingAmount || 0),
    type: ValueType.Amount,
  }),
  [DataRow.Separator]: () => ({ type: ValueType.Separator }),
}

// TODO: rollback changes when Story 7274 enters in phase 2 -> https://dev.azure.com/bob-finance/Bob%20Credit%20Relaunch/_workitems/edit/7274
export const mapLoanStateToDataRows: Record<LoanStateEnum, Array<DataRow>> = {
  [LoanStateEnum.Active]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
    DataRow.Insurance,
    DataRow.InstallmentAmount,
    DataRow.Separator,
    DataRow.RemainingAmount,
    // DataRow.NextInstallmentDate,
  ],
  [LoanStateEnum.ActiveInArrears]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
    DataRow.Insurance,
    DataRow.InstallmentAmount,
    DataRow.Separator,
    // DataRow.InArrearsAmount,
    DataRow.RemainingAmount,
    // DataRow.NextInstallmentDate,
  ],
  [LoanStateEnum.ActiveInClosure]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
    DataRow.Separator,
    DataRow.RemainingAmount,
  ],
  [LoanStateEnum.ActiveNew]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
    DataRow.Insurance,
    DataRow.InstallmentAmount,
    DataRow.Separator,
    DataRow.RemainingAmount,
    // DataRow.NextInstallmentDate,
  ],
  [LoanStateEnum.ActiveOverdue]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
    DataRow.Insurance,
    DataRow.InstallmentAmount,
    DataRow.Separator,
    // DataRow.InArrearsAmount,
    DataRow.RemainingAmount,
    // DataRow.NextInstallmentDate,
  ],
  [LoanStateEnum.Closed]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
  ],
  [LoanStateEnum.Cooloff]: [
    DataRow.ContractId,
    DataRow.Amount,
    DataRow.Duration,
    DataRow.InterestRate,
    DataRow.TotalAmount,
    DataRow.Insurance,
    DataRow.InstallmentAmount,
  ],
  [LoanStateEnum.Hidden]: [],
}
