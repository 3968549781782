/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Document,
    DocumentFromJSON,
    DocumentToJSON,
} from '../models';

export interface DocumentGetDocumentRequest {
    documentId: string | null;
    acceptLanguage?: string;
}

export interface DocumentGetDocumentAssetRequest {
    documentId: string | null;
    acceptLanguage?: string;
}

export interface DocumentGetDocumentsRequest {
    loanId: string | null;
    acceptLanguage?: string;
}

export interface DocumentGetPaymentSlipRequest {
    loanId?: string | null;
    amount?: number;
    acceptLanguage?: string;
}

/**
 * 
 */
export class DocumentApi extends runtime.BaseAPI {

    /**
     * Get a loan document.
     */
    async documentGetDocumentRaw(requestParameters: DocumentGetDocumentRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Document>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGetDocument.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/documents/{documentId}`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => DocumentFromJSON(jsonValue));
    }

    /**
     * Get a loan document.
     */
    async documentGetDocument(requestParameters: DocumentGetDocumentRequest, initOverrides?: RequestInit): Promise<Document> {
        const response = await this.documentGetDocumentRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the asset of a document.
     */
    async documentGetDocumentAssetRaw(requestParameters: DocumentGetDocumentAssetRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters.documentId === null || requestParameters.documentId === undefined) {
            throw new runtime.RequiredError('documentId','Required parameter requestParameters.documentId was null or undefined when calling documentGetDocumentAsset.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/documents/{documentId}/asset`.replace(`{${"documentId"}}`, encodeURIComponent(String(requestParameters.documentId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get the asset of a document.
     */
    async documentGetDocumentAsset(requestParameters: DocumentGetDocumentAssetRequest, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.documentGetDocumentAssetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get documents associated with a loan.
     */
    async documentGetDocumentsRaw(requestParameters: DocumentGetDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Document>>> {
        if (requestParameters.loanId === null || requestParameters.loanId === undefined) {
            throw new runtime.RequiredError('loanId','Required parameter requestParameters.loanId was null or undefined when calling documentGetDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.loanId !== undefined) {
            queryParameters['loanId'] = requestParameters.loanId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/documents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(DocumentFromJSON));
    }

    /**
     * Get documents associated with a loan.
     */
    async documentGetDocuments(requestParameters: DocumentGetDocumentsRequest, initOverrides?: RequestInit): Promise<Array<Document>> {
        const response = await this.documentGetDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a payment slip.
     */
    async documentGetPaymentSlipRaw(requestParameters: DocumentGetPaymentSlipRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Blob>> {
        const queryParameters: any = {};

        if (requestParameters.loanId !== undefined) {
            queryParameters['loanId'] = requestParameters.loanId;
        }

        if (requestParameters.amount !== undefined) {
            queryParameters['amount'] = requestParameters.amount;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/documents/paymentSlip`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a payment slip.
     */
    async documentGetPaymentSlip(requestParameters: DocumentGetPaymentSlipRequest = {}, initOverrides?: RequestInit): Promise<Blob> {
        const response = await this.documentGetPaymentSlipRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
