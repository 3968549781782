import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { validateEmail } from 'pages/utils/validate'
import { StartRegistrationFormData } from 'types/Authentication'

const startRegistrationFormLens = makeFormLens<StartRegistrationFormData>()

export const startRegistrationFormFields: Array<FormField<StartRegistrationFormData>> = [
  {
    type: FormFieldType.FormSection,
    description: 'authentication.startRegistration.text2',
    fields: [
      {
        type: FormFieldType.TextInput,
        label: { label: 'authentication.formFields.email.label' },
        lens: startRegistrationFormLens(['email']),
        name: 'email',
        placeholder: 'authentication.formFields.email.placeholder',
        validate: validateEmail,
      },
    ],
  },
]

export interface StartRegistrationDataResult {
  data: StartRegistrationFormData
  formFields: Array<FormField<StartRegistrationFormData>>
}

export const StartRegistrationData = (): StartRegistrationDataResult => ({
  data: {
    email: null,
    language: '',
  },
  formFields: startRegistrationFormFields,
})
