import { useTranslation } from 'react-i18next'
import { ButtonType } from 'frr-web/lib/components/Button'

import { OnboardingContent } from 'components/layout/OnboardingLayout'
import { usePhotoGallery } from 'hooks/usePhotoGallery.hook'
import { DocumentType, Loan } from '../../../types/Loan.d'
import { UploadFileRow, UploadRowT } from '../../../components/UploadFileRow'

export type Props = {
  loan: Loan
}

export const OnboardingContractUploadTemplate = (props: Props) => {
  const { takePhoto, files, setFiles } = usePhotoGallery()
  const { t } = useTranslation()

  const items: Array<UploadRowT> = [
    {
      label: t('documentType.contractSigned'),
      type: DocumentType.ContractSigned,
      required: true,
    },
    {
      label: t('documentType.generalCreditConditionsAKB'),
      type: DocumentType.GeneralCreditConditionsAKB,
      required: true,
    },
    {
      label: t('documentType.budgetCalculation'),
      type: DocumentType.BudgetCalculation,
      required: true,
    },
  ]

  const upload = () => {
    // TODO
    // Do the server upload
  }

  // TODO: this logic does not yet consider identity file upload
  const isDisabled = items.some((f) => f.type && f.required && !files[f.type])
  const missingFile = items.some((f) => f.type && !files[f.type])

  return (
    <OnboardingContent
      title={t('contractUpload.title')}
      description={t('contractUpload.description')}
      leftButton={{
        label: t('contractUpload.back'),
        type: ButtonType.Chromeless,
        onClick: () => {
          // TODO: handle back button
        },
        override: { width: 80 },
      }}
      rightButton={{
        label: t('contractUpload.next'),
        type: ButtonType.Primary,
        onClick: () => {
          if (missingFile) {
          } else {
            upload()
          }
        },
        override: { marginLeft: 'auto' },
        disabled: isDisabled,
      }}
    >
      <>
        {items.map((item, key) => (
          <UploadFileRow
            {...item}
            key={key}
            onRemove={() => {
              if (item.type) {
                setFiles({ ...files, [item.type]: undefined })
              } else {
                // TODO handle identity remove case
              }
            }}
            onChange={(newFiles) => {
              setFiles({ ...files, ...newFiles.reduce((obj, i) => ({ ...obj, [i.type]: i.file }), {}) })
            }}
            onTakePhoto={takePhoto}
          />
        ))}
      </>
    </OnboardingContent>
  )
}
