import { IonRouterOutlet } from '@ionic/react'
import { Route, useRouteMatch } from 'react-router-dom'

import { OnboardingContractOfferTemplate } from 'pages/loanOnboarding/contractOffer/ContractOffer.template'
import { OnboardingUploadDocumentsTemplate } from 'pages/loanOnboarding/uploadDocuments/UploadDocuments.template'
import { OnboardingWelcomeTemplate } from 'pages/loanOnboarding/welcome/WelcomeTemplate'
import { LoanDetailProps, withLoanDetail } from 'data/hocs/withLoanDetail'
import { withUser } from 'data/hocs/withUser'

import { OnboardingContractUploadTemplate } from './contractUpload/ContractUpload.template'
import { LoanOnboardingData } from './LoanOnboarding.data'

export enum OnboardingRoute {
  ContractUpload = 'contract-upload',
  ContractOffer = 'contract-offer',
  UploadDocuments = 'upload-documents',
  ContractDownload = 'contract-download',
  Welcome = 'welcome',
}

const LoanOnboardingPageComponent = (props: LoanDetailProps) => {
  const data = LoanOnboardingData(props)

  const match = useRouteMatch()

  if (!data.loan) {
    return <div />
  }

  return (
    <IonRouterOutlet id="onboarding-pages">
      <Route path={`${match.url}/${OnboardingRoute.UploadDocuments}`} exact>
        <OnboardingUploadDocumentsTemplate
          loan={data.loan}
          requiredDocuments={data.requiredDocuments}
          idDocuments={data.idDocuments}
        />
      </Route>
      <Route path={`${match.url}/${OnboardingRoute.Welcome}`} exact>
        <OnboardingWelcomeTemplate loan={data.loan} />
      </Route>
      <Route path={`${match.url}/${OnboardingRoute.ContractOffer}`} exact>
        <OnboardingContractOfferTemplate loan={data.loan} />
      </Route>
      <Route path={`${match.url}/${OnboardingRoute.ContractUpload}`} exact>
        <OnboardingContractUploadTemplate loan={data.loan} />
      </Route>
      <Route path={`${match.url}/${OnboardingRoute.ContractDownload}`} exact>
        <OnboardingContractUploadTemplate loan={data.loan} />
      </Route>
    </IonRouterOutlet>
  )
}

export const LoanOnboardingPage = withLoanDetail(withUser(LoanOnboardingPageComponent))
