import { Language } from 'types/Language.d'

export const Languages = [Language.DE_CH, Language.FR_CH, Language.IT_CH]

/* Maps a language string (e.g. fr) to a well-known locale (e.g. fr-CH) */
export const getLanguage = (language: string): Language => {
  let mappedLanguage = language
    ? `${language.substring(0, 2).toLowerCase()}${
        language.length > 0 ? language.substring(2).toUpperCase() : ''
      }`
    : Language.DE_CH

  if (mappedLanguage.startsWith('fr')) mappedLanguage = Language.FR_CH
  if (mappedLanguage.startsWith('it')) mappedLanguage = Language.IT_CH
  if (!Languages.includes(mappedLanguage as Language)) mappedLanguage = Language.DE_CH

  return mappedLanguage as Language
}
