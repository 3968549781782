import { ReactNode } from 'react'
import styled from 'styled-components'

import { LayoutPage, Props as PageProps } from 'components/layout/LayoutPage'
import { DesktopStyle } from 'styles/theme/IonicMediaQuery'
// import { ProfileRoute } from 'pages/profile/Profile.page'

export type Props = {
  children: ReactNode
  pageProps?: Omit<PageProps, 'children'>
}

export const PageContent = (props: Props) => {
  /* const navItems: Array<{
    label: string
    path?: string
    onClick?: () => void
  }> = [
    {
      label: 'profile.navigation.menu.information',
      path: ProfileRoute.Information,
    },
    {
      label: 'profile.navigation.menu.security',
      path: ProfileRoute.Security,
    },
    {
      label: 'profile.navigation.menu.address',
      path: ProfileRoute.Address,
    },
  ] */

  return (
    <LayoutPage {...(props.pageProps || {})}>
      <DetailWrapper>
        {/* <NavigationColumn list={navItems} basePath={`/profile`} /> */}
        {props.children}
      </DetailWrapper>
    </LayoutPage>
  )
}

const DetailWrapper = styled.div`
  display: flex;
  margin-top: 16px;
  width: 100%;
  flex-direction: column;

  @media ${DesktopStyle} {
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
  }
`
