import { useTranslation } from 'react-i18next'
import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { validateSwissZip } from 'pages/utils/validate'
import { useUserContext } from 'scaffold/providers/authentication/AuthenticatedUserProvider'

export type CustomerDataChangeValues = {
  salutation: string
  firstName: string
  lastName: string
  street: string
  zipCode: string
  city: string
  phone: string
  email: string
  comment: string
}

const customerDataFormLens = makeFormLens<CustomerDataChangeValues>()

export interface CustomerDataResult {
  data: CustomerDataChangeValues
  formFields: Array<FormField<CustomerDataChangeValues>>
  isLoading: boolean
  name?: string
}

export const CustomerData = (): CustomerDataResult => {
  const { t } = useTranslation()
  const user = useUserContext()

  const customerDataFormFields: Array<FormField<CustomerDataChangeValues>> = [
    {
      type: FormFieldType.FormSection,
      fields: [
        {
          label: {
            label: 'profile.formFields.salutation.label',
          },
          placeholder: 'profile.formFields.salutation.placeholder',
          lens: customerDataFormLens(['salutation']),
          name: 'salutation',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
          disabled: true,
        },
        {
          label: {
            label: 'profile.formFields.firstName.label',
          },
          placeholder: 'profile.formFields.firstName.placeholder',
          lens: customerDataFormLens(['firstName']),
          name: 'firstName',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
          disabled: true,
        },
        {
          label: {
            label: 'profile.formFields.lastName.label',
          },
          placeholder: 'profile.formFields.lastName.placeholder',
          lens: customerDataFormLens(['lastName']),
          name: 'lastName',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
          disabled: true,
        },
        {
          label: {
            label: 'profile.formFields.streetWithNumber.label',
          },
          placeholder: 'profile.formFields.streetWithNumber.placeholder',
          lens: customerDataFormLens(['street']),
          name: 'streetWithNumber',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
        },
        {
          label: {
            label: 'profile.formFields.zip.label',
          },
          placeholder: 'profile.formFields.zip.placeholder',
          lens: customerDataFormLens(['zipCode']),
          name: 'zip',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
          validate: validateSwissZip,
        },
        {
          label: {
            label: 'profile.formFields.city.label',
          },
          placeholder: 'profile.formFields.city.placeholder',
          lens: customerDataFormLens(['city']),
          name: 'city',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
        },
        {
          label: {
            label: 'profile.formFields.phoneMobile.label',
          },
          placeholder: 'profile.formFields.phoneMobile.placeholder',
          lens: customerDataFormLens(['phone']),
          name: 'phone',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
        },
        {
          label: {
            label: 'profile.formFields.email.label',
          },
          placeholder: 'profile.formFields.email.placeholder',
          lens: customerDataFormLens(['email']),
          name: 'email',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'text',
        },
        {
          label: {
            label: 'profile.formFields.comment.label',
          },
          lens: customerDataFormLens(['comment']),
          type: FormFieldType.TextArea,
        },
      ],
    },
  ]

  return {
    isLoading: false,
    formFields: customerDataFormFields,
    data: {
      salutation:
        user.gender === 'Male'
          ? t('profile.salutation.male.label')
          : t('profile.salutation.female.label'),
      firstName: user.firstName || '',
      lastName: user.lastName || '',
      street: (user.address && user.address.street) || '',
      zipCode: (user.address && user.address.zipCode) || '',
      city: (user.address && user.address.city) || '',
      phone: user.phoneMobile || '',
      email: user.email || '',
      comment: '',
    },
  }
}
