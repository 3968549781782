import { ComponentThemeContext, configureComponentTheme } from 'frr-web/lib/theme/theme.components'
import { configureFormTheme, FormThemeContext } from 'frr-web/lib/theme/theme.form'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { createGlobalStyle, ThemeProvider as StyledThemeProvider } from 'styled-components'

import { ModalAnimation, ModalAnimations } from 'styles/animations/ModalAnimations'
import { baseStyle } from 'styles/baseStyle'
import { componentTheme } from 'styles/theme/frr/component.theme'
import { formTheme } from 'styles/theme/frr/form.theme'

export const configureBaseStyle = (config: string) => createGlobalStyle`
  ${config}

  li, p, a, span {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  ${ModalAnimations[ModalAnimation.Two].Content}
  ${ModalAnimations[ModalAnimation.Five].Content}
  ${ModalAnimations[ModalAnimation.Seven].Content}
`

type CustomThemeType = {
  appBackgroundHeader: string
  appBackgroundFooter?: string
  appTextColorFooter?: string
  buttonBackgroundColor: string
  buttonTextColor: string
  hasEffects?: boolean
}
type CustomThemeContextType = {
  animation: ModalAnimation
  theme: CustomThemeType
}
const DefaultTheme: CustomThemeType = {
  appBackgroundHeader: 'var(--primary-blue-4)',
  buttonBackgroundColor: 'var(--secondary-yellow-1-default)',
  buttonTextColor: 'var(--secondary-yellow-3-default)',
}
const CustomThemeContext = React.createContext<CustomThemeContextType>({
  animation: ModalAnimation.Two,
  theme: DefaultTheme,
})
CustomThemeContext.displayName = 'ThemeContext'

export const useCustomThemeContext = (): CustomThemeContextType => {
  const context = useContext(CustomThemeContext)
  if (!context) {
    throw new Error(`Animation Context not found`)
  }
  return context
}

// Create getter and setter for CSS variable value
const setCSSVariable = (varName: string, value: string) => {
  const root = document.querySelector(':root') as HTMLElement
  if (root) {
    root.style.setProperty(varName, value)
  }
}

// Create base style
const BaseStyle = configureBaseStyle(baseStyle)

let typedText = ''

const AnimationProvider = (props: { children: ReactNode }) => {
  // Handle keypress
  const [newKey, setNewKey] = useState('')

  useEffect(() => {
    const setKey = (e: KeyboardEvent) => {
      setTimeout(() => setNewKey(e.key), 0)
    }
    document.addEventListener('keypress', setKey)

    return () => {
      document.removeEventListener('keypress', setKey)
    }
  }, [])

  // Handle custom theme
  const [customTheme, setCustomTheme] = useState<Omit<CustomThemeContextType, 'setTheme'>>({
    animation: ModalAnimation.Two,
    theme: DefaultTheme,
  })

  useEffect(() => {
    setCSSVariable('--secondary-yellow-1', customTheme.theme.buttonBackgroundColor)
    setCSSVariable('--secondary-yellow-3', customTheme.theme.buttonTextColor)
  }, [customTheme.theme.buttonBackgroundColor, customTheme.theme.buttonTextColor])

  useEffect(() => {
    typedText += newKey
    if (
      typedText.length >= CheckBondChars.length &&
      typedText.slice(-CheckBondChars.length).toLowerCase() === CheckBond
    ) {
      setCustomTheme({
        animation: ModalAnimation.Seven,
        theme: {
          appBackgroundHeader: '#000000',
          appBackgroundFooter: '#000000',
          appTextColorFooter: '#ffffff',
          buttonBackgroundColor: '#000000',
          buttonTextColor: '#ffffff',
        },
      })
      document.body.style.cursor = 'url(/assets/cursor/bond-cursor.svg), auto'
      typedText = ''
    } else if (
      typedText.length >= CheckTeamChars.length &&
      typedText.slice(-CheckTeamChars.length).toLowerCase() === CheckTeam
    ) {
      setCustomTheme({
        animation: ModalAnimation.Five,
        theme: {
          appBackgroundHeader: 'var(--primary-blue-4)',
          buttonBackgroundColor: 'var(--secondary-yellow-1-default)',
          buttonTextColor: 'var(--secondary-yellow-3-default)',
        },
      })
      typedText = ''
    } else if (
      typedText.length >= CheckBobChars.length &&
      typedText.slice(-CheckBobChars.length).toLowerCase() === CheckBob
    ) {
      setCustomTheme({
        animation: ModalAnimation.Five,
        theme: {
          appBackgroundHeader: '#BD0019',
          appBackgroundFooter: '#BD0019',
          appTextColorFooter: '#ffffff',
          buttonBackgroundColor: '#BD0019',
          buttonTextColor: '#ffffff',
          hasEffects: true,
        },
      })
      document.body.style.cursor = 'url(/assets/cursor/santa-bob-cursor.png), auto'
      typedText = ''
    } else if (typedText.length >= 3 && typedText.slice(-3).toLowerCase() === 'esc') {
      setCustomTheme({
        animation: ModalAnimation.Two,
        theme: {
          appBackgroundHeader: 'var(--primary-blue-4)',
          buttonBackgroundColor: 'var(--secondary-yellow-1-default)',
          buttonTextColor: 'var(--secondary-yellow-3-default)',
        },
      })
      document.body.style.cursor = 'auto'
      typedText = ''
      // Clear cache after 100 typings
    } else if (typedText.length > 100) {
      typedText = ''
    }
  }, [newKey])

  return (
    <StyledThemeProvider theme={customTheme?.theme}>
      <CustomThemeContext.Provider value={customTheme}>{props.children}</CustomThemeContext.Provider>
    </StyledThemeProvider>
  )
}

export const ThemeProvider = (props: { children: ReactNode }) => {
  return (
    <ComponentThemeContext.Provider value={configureComponentTheme(componentTheme)}>
      <BaseStyle />

      <FormThemeContext.Provider value={configureFormTheme(formTheme)}>
        <AnimationProvider>{props.children}</AnimationProvider>
      </FormThemeContext.Provider>
    </ComponentThemeContext.Provider>
  )
}

const CheckBondChars = ['b', 'o', 'b', 'j', 'a', 'm', 'e', 's', 'b', 'o', 'b']
const CheckBond = CheckBondChars.join('')
const CheckBobChars = ['s', 'e', 'c', 'r', 'e', 't', 's', 'a', 'n', 't', 'a']
const CheckBob = CheckBobChars.join('')
const CheckTeamChars = [
  'c',
  'a',
  'r',
  'l',
  'o',
  's',
  'j',
  'o',
  'e',
  'l',
  'l',
  'u',
  'k',
  'e',
  'z',
  'o',
  'f',
  'i',
  'a',
]
const CheckTeam = CheckTeamChars.join('')
