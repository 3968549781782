import { api, Mutation } from 'data/api'
import { OptionGroup } from 'frr-web/lib/components/OptionGroup'
import { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router-dom'
import styled, { css, keyframes } from 'styled-components'

import { useMaybeUserContext } from 'scaffold/providers/authentication/AuthenticatedUserProvider'
import { i18nextChangeLanguage } from 'scaffold/translation/initI18next'
import { Languages } from 'scaffold/translation/Languages'
import { DeviceHorizontalHeight } from 'styles/theme/IonicMediaQuery'
import { Language } from 'types/Language.d'

type Props = {
  isAuthenticated?: boolean
  open: boolean
  close: () => void
}

export const getLanguageOptions = (): Array<{
  label: string
  value: Language
}> => Languages.map((k) => ({ value: k, label: k }))

export const AppSidebar = (props: Props) => {
  const history = useHistory()
  const { t, i18n } = useTranslation()

  const location = useLocation()

  const user = useMaybeUserContext()

  const sidebarItems = user
    ? [
        {
          label: 'menu.item.profile',
          link: '/profile/information',
        },
        {
          label: 'menu.item.legal',
          externalLink: 'menu.item.legalLink',
        },
        {
          label: 'menu.item.aboutBobFinance',
          externalLink: 'menu.item.aboutBobFinanceLink',
        },
        {
          label: 'menu.item.support',
          externalLink: 'menu.item.supportLink',
        },
        {
          label: 'menu.item.logout',
          onClick: () => {
            api[Mutation.Logout]().then(() => {
              // TODO: Clear browser history
              // Use window.location here to enforce browser refresh
              window.location.href = `${document.baseURI}logout`
            })
          },
          type: 'danger',
        },
      ]
    : [
        {
          label: 'menu.item.legal',
          externalLink: 'menu.item.legalLink',
        },
        {
          label: 'menu.item.aboutBobFinance',
          externalLink: 'menu.item.aboutBobFinanceLink',
        },
        {
          label: 'menu.item.support',
          externalLink: 'menu.item.supportLink',
        },
      ]

  // to able to change the cookie banner language, we need to know in which language
  // is app set and in which language is the cookie banner set

  const languageOptions = getLanguageOptions()

  const onChangeLanguage = (language: string | number) => {
    i18nextChangeLanguage(language as string)
    history.push(`${location.pathname}?lang=${String(language).replace('-CH', '')}`)
  }

  const cookieLanguage = localStorage.getItem('cookieLanguage')
  const userLanguage = localStorage.getItem('userLanguage')
  const modalRoot = document.getElementById('modal-root')

  const [cookieAccepted, setCookieAccepted] = useState(false)

  // to be able to change the cookie banner language, we need to re-render the page
  // for that, if the app language changes, we need to refresh the page

  useEffect(() => {
    if (userLanguage !== undefined && String(userLanguage) !== cookieLanguage) {
      localStorage.setItem('cookieLanguage', String(userLanguage))

      if (!cookieAccepted) {
        history.go(0)
      }
    }
  }, [userLanguage, i18n.language])

  // whatever the button the user clicks, we need to set the cookieAccepted to true
  // so we know there was an interaction with the banner

  const cookieSelectionButton = document.getElementById(
    'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowallSelection',
  )
  const cookieAllButton = document.getElementById(
    'CybotCookiebotDialogBodyLevelButtonLevelOptinAllowAll',
  )

  const handleMouseDown = () => {
    setCookieAccepted(true)
  }

  // if both buttons are not present, we need to set the cookieAccepted to true

  useEffect(() => {
    if (!!cookieSelectionButton === false && !!cookieAllButton === false) {
      handleMouseDown()
    }

    if (!!cookieSelectionButton) {
      cookieSelectionButton.addEventListener('mousedown', handleMouseDown)
    }
    if (!!cookieAllButton) {
      cookieAllButton.addEventListener('mousedown', handleMouseDown)
    }

    // cleanup this component
    return () => {
      if (!!cookieSelectionButton) {
        cookieSelectionButton.removeEventListener('mousedown', handleMouseDown)
      }
      if (!!cookieAllButton) {
        cookieAllButton.removeEventListener('mousedown', handleMouseDown)
      }
    }
  }, [])

  // to handle the animation of the sidebar menu, we need to know if the user
  // clicked to close the menu either on the X button or on the background

  const onClose = () => {
    props.close()
  }

  const [show, setShow] = useState(false)
  const [close, setClose] = useState(false)

  useEffect(() => {
    if (props.open && !show) {
      setShow(true)
    } else if (!props.open && show) {
      setClose(true)
      setTimeout(() => {
        setShow(false)
        setClose(false)
      }, 500)
    }
  }, [props.open, show, close])

  return show
    ? ReactDOM.createPortal(
        <Wrapper show={show}>
          <OpacityScreen onMouseDown={onClose} className="background">
            <SidebarWrapper
              open={show}
              className={!close ? 'content' : 'content_close'}
              onMouseDown={(e) => {
                e.stopPropagation()
              }}
            >
              <SidebarHeader>
                <h4>
                  {(user?.firstName && user?.lastName && `${user.firstName} ${user.lastName}`) ||
                    user?.email || <br />}
                </h4>
                <SidebarCloseIcon onClick={onClose} data-test-id="sidebar-close">
                  <img src="/assets/close.svg" alt="arrow" />
                </SidebarCloseIcon>
              </SidebarHeader>

              <SidebarItems className="SidebarItems">
                {sidebarItems.map((item, key) => (
                  <SidebarItem
                    key={key}
                    onClick={() => {
                      if (item.link) {
                        history.push(item.link || '/')
                      } else if (item.onClick) {
                        item.onClick()
                      } else if (item.externalLink) {
                        window.open(t(item.externalLink), '_blank')
                      }
                    }}
                    type={item.type}
                  >
                    <p>{t(item.label)}</p>
                    <img src="/assets/chevron.svg" alt="arrow" />
                  </SidebarItem>
                ))}
              </SidebarItems>

              <SidebarFooter className="footer">
                <OptionGroup
                  onChange={onChangeLanguage}
                  options={languageOptions}
                  value={i18n.language}
                />
              </SidebarFooter>
            </SidebarWrapper>
          </OpacityScreen>
        </Wrapper>,
        modalRoot as Element,
      )
    : null
}

const backgroundAppear = keyframes`
  from {
    opacity: 0%;
  }

  to {
    opacity: 100%;
  }
`
const contentAppear = keyframes`
  from {
    transform: translateX(${312 + 48}px);
  }

  to {
    transform: translateX(0px);
  }
`
const contentDisappear = keyframes`
  from {
    transform: translateX(0px);
    
  }

  to {
    transform: translateX(${312 + 48}px);
  }
`

const pageAnimation = css`
  transform: scale(1);
  .background {
    animation: ${backgroundAppear} 0.3s ease;
    .content {
      animation: ${contentAppear} 0.7s ease;
    }
    .content_close {
      animation: ${contentDisappear} 0.7s ease;
    }
  }
`

const Wrapper = styled.div<{ show: boolean }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  transform: scale(0);

  ${({ show }) => show && pageAnimation}
`

const OpacityScreen = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: 99;
`

const SidebarWrapper = styled.div<{ open: boolean }>`
  background: white;
  bottom: 0;
  box-shadow: 0px 4px 32px 4px rgba(0, 0, 0, 0.25);
  position: fixed;
  right: 0px;
  top: 0;
  width: 312px;
  z-index: 100;

  @media (max-height: ${DeviceHorizontalHeight}) {
    overflow-y: scroll;
  }
`

const SidebarItems = styled.div``

const SidebarItem = styled.div<{ type?: string }>`
  align-items: center;
  border-bottom: 1px solid var(--neutral-gray-2);
  cursor: pointer;
  display: flex;
  height: 64px;
  justify-content: space-between;
  padding: 0 24px;
  transition: background 0.2s;

  :hover {
    background: var(--neutral-gray-2);
  }

  p {
    color: ${({ type }) => (type === 'danger' ? 'var(--error-red-1)' : 'var(--ion-text-color)')};
  }

  img {
    width: 8px;
    opacity: 0.5;
  }
`

const SidebarHeader = styled.div`
  align-items: center;
  border-bottom: 1px solid var(--neutral-gray-2);
  display: flex;
  height: 72px;
  justify-content: space-between;
  padding: 0 16px 0 24px;
`
const SidebarFooter = styled.div`
  display: flex;
  justify-content: center;
  padding: 24px;

  @media (min-height: ${DeviceHorizontalHeight}) {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
`

const SidebarCloseIcon = styled.div`
  align-items: center;
  border-radius: var(--card-border-radius);
  cursor: pointer;
  display: flex;
  height: 36px;
  justify-content: center;
  opacity: 0.7;
  transition: background 0.2s;
  width: 36px;

  :hover {
    background-color: var(--neutral-gray-2);
  }

  img {
    width: 20px;
    height: 20px;
  }
`
