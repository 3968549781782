import { useState } from 'react'
import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import { TextInput } from 'frr-web/lib/components/TextInput'

import { DocumentType } from 'types/Loan.d'
import { UploadFileRow } from 'components/UploadFileRow'
import { usePhotoGallery } from 'hooks/usePhotoGallery.hook'

import { ModalWithContent } from './ModalWithContent'
import { Props as ModalProps } from './Modal'

type Props = {
  onChange: (files: Record<string, File | undefined>) => void
  files: Record<string, File | undefined>
  modal: Omit<ModalProps, 'children'>
}

type DocumentGroupT = {
  label: string
  items: Array<DocumentType>
}

const documentGroups: Array<DocumentGroupT> = [
  {
    label: 'identityDocuments',
    items: [
      DocumentType.SwissIDFront,
      DocumentType.SwissIDBack,
      DocumentType.SwissPassport,
      DocumentType.SwissDriverLicense,
      DocumentType.ForeignPassport,
      DocumentType.ForeignerIdentityCardBCFront,
      DocumentType.ForeignerIdentityCardBCBack,
      DocumentType.ForeignerIdentityPaper,
      DocumentType.ResidencePermit,
    ],
  },
  {
    label: 'paySlips',
    items: [
      DocumentType.PaySlipLastMonth,
      DocumentType.PaySlipMonthBeforeLast,
      DocumentType.PaySlipMonthTwiceBeforeLast,
      DocumentType.ReceiptForAdditionalIncome,
    ],
  },

  {
    label: 'partnerDocuments',
    items: [
      DocumentType.CopyOfPartnerIDCard,
      DocumentType.WageStatementPartnerLastMonth,
      DocumentType.WageStatementPartnerMonthBeforeLast,
      DocumentType.WageStatementPartnerMonthTwiceBeforeLast,
      DocumentType.ReceiptForAdditionalIncomePartner,
      DocumentType.BankReceiptForIncomingWagesPartner,
    ],
  },
  {
    label: 'bankDocuments',
    items: [
      DocumentType.CopyOfBankCardForCreditDisbursement,
      DocumentType.LoanFinalStatementThirdPartyBank,
      DocumentType.LeasingFinalStatementThirdPartyBank,
      DocumentType.PayInSlipForLoanRedemption,
    ],
  },
  {
    label: 'proofOfCosts',
    items: [
      DocumentType.ApartmentLeaseAgreement,
      DocumentType.MortgageAgreement,
      DocumentType.HealthInsurancePolicy,
      DocumentType.PremiumReductionHealthInsurance,
      DocumentType.ProofOfAlimonyOrCreditNote,
      DocumentType.DivorceDecree,
      DocumentType.Miscellaneous,
    ],
  },
  {
    label: 'contracts',
    items: [
      DocumentType.Contract,
      DocumentType.ContractFeeOverview,
      DocumentType.ContractAdditionalInformation,
      DocumentType.ContractSigned,
    ],
  },
]

export const UploadDocumentModal = (props: Props) => {
  const { t } = useTranslation()
  // TODO - search not yet working
  const [search, setSearch] = useState('')
  const [type, setType] = useState<DocumentType>()
  const { takePhoto, files, setFiles } = usePhotoGallery()

  if (files !== props.files && !type) {
    setFiles(props.files)
  }

  return (
    <ModalWithContent
      title={t('uploadDocumentModal.title')}
      description={t('uploadDocumentModal.description')}
      modal={{ ...props.modal, style: { maxWidth: 500 } }}
    >
      {!type ? (
        <>
          <TextInput
            value={search}
            onChange={setSearch}
            placeholder={t('uploadDocumentModal.searchPlaceholder')}
            style={{
              wrapper: {
                margin: '16px 0 ',
              },
            }}
          />
          <List>
            {documentGroups.map((group, key) => (
              <DocumentGroup group={group} key={key} setType={setType} />
            ))}
          </List>
        </>
      ) : (
        <>
          <h5 style={{ margin: '16px 0' }}>{t(`documentType.${type}`)}</h5>
          <UploadFileRow
            file={files[type]}
            onChange={(newFiles) => {
              setFiles({
                ...files,
                ...newFiles.reduce((obj, i) => ({ ...obj, [i.type]: i.file }), {}),
              })
            }}
            onRemove={() => {
              if (type) setFiles({ ...files, [type]: undefined })
            }}
            type={type}
            onTakePhoto={takePhoto}
          />
        </>
      )}

      <ButtonWrapper>
        {type && (
          <Button
            label={t('uploadDocumentModal.button.back')}
            onClick={() => {
              if (type) setFiles({ ...files, [type]: undefined })
              setType(undefined)
            }}
            type={ButtonType.Chromeless}
          />
        )}
        <Button
          label={type ? t('uploadDocumentModal.button.submit') : t('uploadDocumentModal.button.next')}
          onClick={() => {
            props.onChange(files)
            setType(undefined)
            props.modal.onClose()
          }}
          type={ButtonType.Primary}
          override={{ width: '100%', marginTop: 32 }}
        />
      </ButtonWrapper>
    </ModalWithContent>
  )
}

const DocumentGroup = (props: { group: DocumentGroupT; setType: (type: DocumentType) => void }) => {
  const { t } = useTranslation()
  const [open, setOpen] = useState(false)
  return (
    <DocumentGroupWrapper>
      <DocumentGroupHeader
        onClick={() => {
          setOpen(!open)
        }}
      >
        <h5>{t(`documentGroup.${props.group.label}`)}</h5>
        <img
          src="/assets/chevron.svg"
          style={{ width: 6, transform: open ? 'rotate(-90deg)' : 'rotate(90deg)' }}
          alt="arrow"
        />
      </DocumentGroupHeader>
      {open && (
        <DocumentItems>
          {props.group.items.map((item, key) => (
            <DocumentItem
              key={key}
              onClick={() => {
                props.setType(item)
              }}
            >
              <p>{t(`documentType.${item}`)}</p>
            </DocumentItem>
          ))}
        </DocumentItems>
      )}
    </DocumentGroupWrapper>
  )
}

const DocumentItem = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid var(--neutral-gray-2);
  cursor: pointer;
  padding: 0 8px;

  &:hover > p {
    color: var(--primary-blue-5);
  }
`

const DocumentGroupWrapper = styled.div``

const DocumentGroupHeader = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  justify-content: space-between;
  cursor: pointer;
`

const DocumentItems = styled.div``

const List = styled.div`
  flex: 1;
`

const ButtonWrapper = styled.div`
  flex: 0 0 72px;
`
