import { useState } from 'react'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router-dom'
import { IonPage } from '@ionic/react'

import { api, Mutation } from 'data/api'

import { StartRegistrationData } from './StartRegistration.data'
import { StartRegistrationTemplate } from './StartRegistration.template'
import { useTranslation } from 'react-i18next'
import { RegistrationStartRequestParams } from 'data/authenticationapi/models/Registration'
import { useAuthConfigContext } from 'scaffold/providers/authentication/AuthProvider'

export const StartRegistrationPage = () => {
  const history = useHistory()
  const { i18n } = useTranslation()
  const { showLoginStartPage } = useAuthConfigContext()

  const [disableDirtyValidation, setDisableDirtyValidation] = useState<boolean>(true)

  const [state, setState] = useState<{ isSuccess: boolean; error: string | null }>({
    isSuccess: false,
    error: null,
  })
  const { mutateAsync: startRegistration, isLoading } = useMutation(api[Mutation.RegistrationStart], {
    onSuccess: () => setState({ isSuccess: true, error: null }),
    onError: () =>
      setState({
        isSuccess: false,
        error: 'authentication.errors.technicalError',
      }),
  })

  const onStartRegistration = (values: RegistrationStartRequestParams) =>
    startRegistration({ ...values, language: i18n.language })

  const startRegistrationData = StartRegistrationData()

  return (
    <IonPage id="start-registration">
      <StartRegistrationTemplate
        data={startRegistrationData.data}
        error={state.error}
        formFields={startRegistrationData.formFields}
        isLoading={isLoading}
        isSuccess={state.isSuccess}
        onStartRegistration={onStartRegistration}
        onGoBack={() => history.replace(showLoginStartPage ? '/info/login' : '/login')}
        disableDirtyValidation={disableDirtyValidation}
        setDisableDirtyValidation={setDisableDirtyValidation}
      />
    </IonPage>
  )
}
