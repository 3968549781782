// Source: https://codepen.io/designcouch/pen/obvKxm/

import { css, keyframes } from 'styled-components'

export const unfoldIn = keyframes`
  0% {
    transform:scaleY(.005) scaleX(0);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(1) scaleX(1);
  }
`

export const unfoldOut = keyframes`
  0% {
    transform:scaleY(1) scaleX(1);
  }
  50% {
    transform:scaleY(.005) scaleX(1);
  }
  100% {
    transform:scaleY(.005) scaleX(0);
  }
`

export const zoomIn = keyframes`
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
`

export const zoomOut = keyframes`
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
`

export const fadeIn = keyframes`
  0% {
    background: var(--modal-backdrop-background-color-closed);
  }
  100% {
    background: var(--modal-backdrop-background-color);
  }
`
export const fadeInDark = keyframes`
  0% {
    background: rgba(0,0,0,.0);
  }
  100% {
    background: rgba(0,0,0,.8);
  }
`

export const fadeOut = keyframes`
  0% {
    background: var(--modal-backdrop-background-color);
  }
  100% {
    background: var(--modal-backdrop-background-color-closed);
  }
`

export const scaleUp = keyframes`
  0% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
  100% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
`

export const scaleDown = keyframes`
  0% {
    transform:scale(1) translateY(0px);
    opacity:1;
  }
  100% {
    transform:scale(.8) translateY(1000px);
    opacity:0;
  }
`

export const scaleBack = keyframes`
  0% {
    transform:scale(1);
  }
  100% {
    transform:scale(.85);
  }
`

export const scaleForward = keyframes`
  0% {
    transform:scale(.85);
  }
  100% {
    transform:scale(1);
  }
`

export const quickScaleDown = keyframes`
  0% {
    transform:scale(1);
  }
  99.9% {
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
`

export const slideUpLarge = keyframes`
  0% {
    transform:translateY(0%);
  }
  100% {
    transform:translateY(-100%);
  }
`

export const slideDownLarge = keyframes`
  0% {
    transform:translateY(-100%);
  }
  100% {
    transform:translateY(0%);
  }
`

export const moveUp = keyframes`
  0% {
    transform:translateY(150px);
  }
  100% {
    transform:translateY(0);
  }
`

export const moveDown = keyframes`
  0% {
    transform:translateY(0px);
  }
  100% {
    transform:translateY(150px);
  }
`

export const blowUpContent = keyframes`
  0% {
    transform:scale(1);
    opacity:1;
  }
  99.9% {
    transform:scale(2);
    opacity:0;
  }
  100% {
    transform:scale(0);
  }
`

export const blowUpContentTwo = keyframes`
  0% {
    transform:scale(2);
    opacity:0;
  }
  100% {
    transform:scale(1);
    opacity:1;
  }
`

export const blowUpModal = keyframes`
  0% {
    transform:scale(0);
  }
  100% {
    transform:scale(1);
  }
`

export const blowUpModalTwo = keyframes`
  0% {
    transform:scale(1);
    opacity:1;
  }
  100% {
    transform:scale(0);
    opacity:0;
  }
`

export const roadRunnerIn = keyframes`
  0% {
    transform:translateX(-1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform:translateX(30px) skewX(0deg) scaleX(.9);
  }
  100% {
    transform:translateX(0px) skewX(0deg) scaleX(1);
  }
`

export const roadRunnerOut = keyframes`
  0% {
    transform:translateX(0px) skewX(0deg) scaleX(1);
  }
  30% {
    transform:translateX(-30px) skewX(-5deg) scaleX(.9);
  }
  100% {
    transform:translateX(1500px) skewX(30deg) scaleX(1.3);
  }
`

export const sketchIn = keyframes`
	0% {
		stroke-dashoffset: 778;
	}
	100% {
		stroke-dashoffset: 0;
	}
`

export const sketchOut = keyframes`
	0% {
		stroke-dashoffset: 0;
	}
	100% {
		stroke-dashoffset: 778;
	}
`

export const modalFadeIn = keyframes`
  0% {
    background-color:transparent;
  }
  100% {
    background-color:white;
  }
`

export const modalFadeOut = keyframes`
  0% {
    background-color: white;
  }
  100% {
    background-color: transparent;
  }
`

export const modalContentFadeIn = keyframes`
  0% {
    opacity:0;
    top:-20px;
  }
  100% {
    opacity:1;
    top:0;
  }
`

export const modalContentFadeOut = keyframes`
  0% {
    opacity:1;
    top:0px;
  }
  100% {
    opacity:0;
    top:-20px;
  }
`

export const bondJamesBond = keyframes`
  0% {
    transform:translateX(1000px);
    overflow: hidden; 
  }
  80% {
    transform:translateX(0px);
    border-radius:75px;
    height:75px;
    width:75px;
    overflow: hidden; 
  }
  90% {
    border-radius: var(--modal-border-radius);
    height:110%;
    width:110%;
    overflow: hidden; 
  }
  100% {
    border-radius: var(--modal-border-radius);
    height:100%;
    width:100%;
    overflow-y: auto; 
  }
`

export const killShot = keyframes`
  0% {
    transform:translateY(0) rotate(0deg);
    opacity:1;
    height: 100%;
    width: 100%;
  }
  10% {
    transform:translateY(0) rotate(22.5deg);
    opacity: 0.8;
    height: 30%;
    width: 30%;
  }
  100% {
    transform:translateY(300px) rotate(45deg);
    opacity:0;
    height: 30%;
    width: 30%;
  }
`

export const fadeToRed = keyframes`
  0% {
    background: var(--modal-backdrop-background-color);
  }
  100% {
    background: rgba(207, 60, 79, 0.9);
  }
`

export const slowFade = keyframes`
  0% {
    opacity:1;
  }
  99.9% {
    opacity:0;
    transform:scale(1);
  }
  100% {
    transform:scale(0);
  }
`
export enum ModalAnimation {
  Two = 'animation-two',
  Five = 'animation-five',
  Seven = 'animation-seven',
}
export const ModalAnimations = {
  [ModalAnimation.Two]: {
    Modal: css`
      transform: scale(1);
      .modal-background {
        animation: ${fadeIn} 0.55s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          opacity: 0;
          animation: ${scaleUp} 0.55s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
      &.out {
        .modal-background {
          animation: ${fadeOut} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          .modal {
            animation: ${scaleDown} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          }
        }
      }
    `,

    Content: css`
      .${ModalAnimation.Two}__content {
        animation: ${scaleBack} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        &.out {
          animation: ${scaleForward} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
    `,
  },

  [ModalAnimation.Five]: {
    Modal: css`
      transform: scale(1);
      .modal-background {
        background: rgba(0, 0, 0, 0);
        animation: ${fadeIn} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          transform: translateX(-1500px);
          animation: ${roadRunnerIn} 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        }
      }
      &.out {
        animation: ${quickScaleDown} 0s 0.5s linear forwards;
        .modal-background {
          animation: ${fadeOut} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          .modal {
            animation: ${roadRunnerOut} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          }
        }
      }
    `,
    Content: css`
      .${ModalAnimation.Five}__content {
      }
    `,
  },

  [ModalAnimation.Seven]: {
    Modal: css`
      transform: scale(1);
      .modal-background {
        background: rgba(0, 0, 0, 0);
        align-items: center;
        animation: ${fadeInDark} 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
        .modal {
          height: 75px;
          width: 75px;
          border-radius: 75px;
          overflow: hidden; 
          animation: ${bondJamesBond} 1.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

          .modal-content {
            opacity: 0;
            position: relative;
            animation: ${modalContentFadeIn} 0.5s 1.4s linear forwards;
          }
        }
      }
      &.out {
        animation: ${slowFade} 0.5s 1.5s linear forwards;
        .modal-background {
          animation: ${fadeToRed} 2s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
          .modal {
            border-radius: var(--modal-border-radius);
            height: 100%;
            width: 100%;
            animation: ${killShot} 1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            .modal-content {
              animation: ${modalContentFadeOut} 0.5s 0.5 cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
            }
          }
        }
      }
    `,

    Content: css`
      .${ModalAnimation.Seven}__content {
      }
    `,
  },
}
