export enum IonicMediaQuery {
  Sm = '(min-width: 576px)',
  Md = '(min-width: 768px)',
  Lg = '(min-width: 992px)',
  Xl = '(min-width: 1200px)',
}

export const MobileWidth = 991

export const DesktopStyle = '(min-width: 992px)'
export const MobileStyle = '(max-width: 991px)'

export const DeviceHorizontalHeight = '490px'
