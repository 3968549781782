import {
  Loan,
  LoanActionState,
  LoanApplicationState,
  LoanNotification,
  LoanNotificationType,
} from 'types/Loan.d'
import { mapLoanNotificationAction } from './mapLoanNotificationAction'

const InfoLifecycleStateList = [LoanApplicationState.LoanActive]
const DangerLifecycleStateList = [LoanApplicationState.LoanPaymentOverdue]

export const mapLoanNotification = (loan: Loan): LoanNotification | null => {
  let notification = null

  if (loan.status.currentState && loan.status.currentState !== LoanActionState.NoActionRequired) {
    notification = {
      type: LoanNotificationType.Warning,
      localeInfoText: `loanNotification.${loan.status.currentState}.infoText`,
      action: mapLoanNotificationAction(loan),
    }
  } else if (InfoLifecycleStateList.includes(loan.status.customer.applicationState)) {
    notification = {
      type: LoanNotificationType.Info,
      localeInfoText: `loanNotification.${loan.status.customer.applicationState}.infoText`,
      localeInfoData: { date: loan.nextPaymentAt },
      action: null,
    }
  } else if (DangerLifecycleStateList.includes(loan.status.customer.applicationState)) {
    notification = {
      type: LoanNotificationType.Danger,
      localeInfoText: `loanNotification.${loan.status.customer.applicationState}.infoText`,
      localeInfoData: { date: loan.nextPaymentAt, amount: loan.installmentAmount },
      action: null,
    }
  }

  return notification
}
