import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import { useState } from 'react'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { validateEmail } from 'pages/utils/validate'
import { StartRegistrationFormData } from 'types/Authentication'

export const StartRegistrationTemplate = (props: {
  data: StartRegistrationFormData
  error: string | null
  formFields: Array<FormField<StartRegistrationFormData>>
  isSuccess: boolean
  isLoading: boolean
  onStartRegistration: (values: StartRegistrationFormData) => void
  onGoBack: () => void
  disableDirtyValidation?: boolean
  setDisableDirtyValidation?: (value: boolean) => void
}) => {
  const [values, setValues] = useState(props.data)
  const onStartRegistration = () => {
    props.onStartRegistration(values)
  }

  const onChange = (values: StartRegistrationFormData) => {
    setValues(values)
    if (values.email === null || values.email === '') {
      props.setDisableDirtyValidation?.(true)
    }
    if (!!values.email && values.email !== '') {
      props.setDisableDirtyValidation?.(false)
    }
  }

  return (
    <LayoutPageLandingContent
      title={'authentication.startRegistration.title'}
      description={`authentication.startRegistration.${props.isSuccess ? 'successText1' : 'text1'}`}
      descriptionData={values}
    >
      {props.isSuccess ? (
        <AuthenticationForm
          data={{}}
          formFields={[]}
          buttons={[
            {
              label: 'authentication.actions.gotoLogin.label',
              onClick: props.onGoBack,
              type: ButtonType.Chromeless,
            },
          ]}
        />
      ) : (
        <AuthenticationForm
          data={values}
          error={props.error}
          formFields={props.formFields}
          onChange={onChange}
          onSubmit={onStartRegistration}
          style={{
            form: {
              wrapper: {},
              content: {},
              buttonContainer: {
                flexDirection: 'column',
              },
            },
          }}
          buttons={[
            {
              label: 'authentication.actions.gotoLogin.label',
              onClick: props.onGoBack,
              type: ButtonType.Chromeless,
            },
            {
              isDisabled: (data) =>
                props.isLoading ||
                data.email === null ||
                data.email === '' ||
                validateEmail(data.email) !== null,
              label: 'authentication.actions.startRegistration.label',
              onClick: ({ submit }) => submit(),
              override: { marginTop: 16, width: '100%' },
              type: ButtonType.Primary,
            },
          ]}
          disableDirtyValidation={props.disableDirtyValidation}
        />
      )}
    </LayoutPageLandingContent>
  )
}
