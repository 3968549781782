import { useUserContext } from 'scaffold/providers/authentication/AuthenticatedUserProvider'
import { CustomerRelationship } from 'types/CustomerRelationship'

export interface ProfileDataResult {
  user: CustomerRelationship
}

export const ProfileData = (): ProfileDataResult => {
  const user = useUserContext()
  const data = { user: user }
  return data
}
