import { createContext, ReactNode, useContext, useEffect, useState } from 'react'

import { LoadingOverlay } from 'components/overlay/LoadingOverlay'
import { api, Query } from 'data/api'
import { FrontendConfig } from 'types/FrontendConfig'

const ConfigContext = createContext<FrontendConfig | null>(null)
ConfigContext.displayName = 'ConfigContext'

export const useConfigContext = (): FrontendConfig => {
  const config = useContext(ConfigContext)
  if (!config) {
    throw new Error(`Config not found`)
  }
  return config
}

export const ConfigProvider = (props: { children: ReactNode }) => {
  const [config, setConfig] = useState<FrontendConfig>()

  useEffect(() => {
    api[Query.FetchConfig]().then(setConfig)
  }, [])

  return config ? (
    <ConfigContext.Provider value={config}>{props.children}</ConfigContext.Provider>
  ) : (
    <LoadingOverlay isLoading />
  )
}
