import { useTranslation } from 'react-i18next'
import { useCustomThemeContext } from 'scaffold/providers/ThemeProvider'
import styled from 'styled-components'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

export const Footer = (props: { backgroundColor?: string; textColor?: string; isOverlay?: boolean }) => {
  const { t } = useTranslation()
  const customTheme = useCustomThemeContext()
  const textColor = { color: customTheme.theme.appTextColorFooter || props.textColor }
  return (
    <FooterWrapper backgroundColor={props.backgroundColor} isOverlay={props.isOverlay}>
      <FooterInner>
        <p style={textColor}>{t('footer.copyright')}</p>
        <FooterAddress style={textColor}>{t('footer.address')}</FooterAddress>
        <FooterPolicy>
          <a
            style={textColor}
            href={t('footer.policyLink')}
            rel="noreferrer"
            target="_blank"
            tabIndex={-1}
          >
            {t('footer.policy')}
          </a>
        </FooterPolicy>
      </FooterInner>
    </FooterWrapper>
  )
}

const FooterAddress = styled.p`
  @media ${DesktopStyle} {
    margin-left: 12px;
  }
`

const FooterPolicy = styled.div`
  font-size: 12px;

  @media ${DesktopStyle} {
    margin-left: 48px;
  }
`

const FooterWrapper = styled.div<{ isOverlay?: boolean; backgroundColor?: string }>`
  width: 100%;
  background-color: ${({ backgroundColor, theme }) => theme.appBackgroundFooter || backgroundColor};

  @media ${DesktopStyle} {
    display: ${(props) => (props.isOverlay ? 'inline-block' : 'block')};
  }
`

const FooterInner = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;

  p {
    line-height: 24px;
    font-size: 12px;
  }

  @media ${DesktopStyle} {
    flex-direction: row;
    align-items: center;
    height: 72px;
    padding: 48px;
  }

  @media ${MobileStyle} {
    flex-direction: column;
    padding: 24px;
  }
`
