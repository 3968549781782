import { IonRouterOutlet } from '@ionic/react'
import { LoadingOverlay } from 'components/overlay/LoadingOverlay'
import { api, Mutation, Query } from 'data/api'
import { ResetPasswordAttemptState } from 'data/authenticationapi/models/ResetPasswordAttempt'
import { ResetPasswordPage } from 'pages/authentication/resetPassword/ResetPassword.page'
import { ResetPasswordOTPPage } from 'pages/authentication/resetPasswordOTP/ResetPasswordOTP.page'
import { createContext, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { Redirect, Route, useHistory, useLocation, useRouteMatch } from 'react-router-dom'
import { ResetPasswordAttempt, SubmitPasswordData } from 'types/ResetPassword'

/*
 * Reset password attempt context declaration
 */

const ResetPasswordAttemptContext = createContext<ResetPasswordAttempt | null>(null)
ResetPasswordAttemptContext.displayName = 'ResetPassword Attempt Context'

export const useResetPasswordAttemptContext = (): ResetPasswordAttempt => {
  const authAttempt = useContext(ResetPasswordAttemptContext)
  if (!authAttempt) {
    throw new Error(`ResetPassword attempt not found`)
  }
  return authAttempt
}

export const AuthResetPasswordProvider = () => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  const { t, i18n } = useTranslation()

  // Set reset password state
  const urlSearchParams = new URLSearchParams(location.search)
  const urlQueryResetToken = urlSearchParams.get('resetPasswordToken')
  const tokenMatch = useRouteMatch<{ token: string }>(`${match.path}/:token`)
  const resetPasswordToken = tokenMatch?.params.token || ''

  const [password, setPassword] = useState('')
  const [resetPasswordState, setResetPasswordState] = useState<{
    attempt: ResetPasswordAttempt
    isReady: boolean
  }>({
    attempt: { state: ResetPasswordAttemptState.None },
    isReady: false,
  })

  const onResetPasswordStep = (attempt: ResetPasswordAttempt) => {
    setResetPasswordState({ attempt, isReady: true })
    // On successful OTP verification navigate back to reset password page with success state
    if (attempt.state === ResetPasswordAttemptState.Succeeded) {
      history.replace(`${match.url}/${resetPasswordToken}`)
    }
  }
  const onResetPasswordError = () =>
    setResetPasswordState({
      attempt: {
        state: ResetPasswordAttemptState.Error,
        error: 'authentication.errors.technicalError',
      },
      isReady: true,
    })

  // Replace query param token with path param. Redirect to login page if no token is set
  useEffect(() => {
    if (resetPasswordToken > '') {
      api[Query.FetchResetPasswordAttempt](resetPasswordToken)
        .then((attempt) => setResetPasswordState({ attempt, isReady: true }))
        .catch(onResetPasswordError)
    } else if (urlQueryResetToken) {
      history.replace(`${match.url}/${urlQueryResetToken}`)
    } else {
      history.replace('/login')
    }
  }, [urlQueryResetToken, resetPasswordToken, history, match.url])

  // On browser reload go back to set new password
  useEffect(() => {
    if (
      resetPasswordState.isReady &&
      resetPasswordState.attempt.state !== ResetPasswordAttemptState.MTanChallengeFailLimitReached &&
      location.pathname.endsWith('/otp') &&
      !password
    ) {
      history.replace(`${tokenMatch?.url}`)
    }
  }, [resetPasswordState, location.pathname, history, password, tokenMatch?.url])

  // After entering the password send the OTP code and navigate to the OTP page
  const { mutate: sendCode } = useMutation(api[Mutation.ResetPasswortSendOTPCode], {
    onSuccess: onResetPasswordStep,
    onError: onResetPasswordError,
  })
  const onSetPassword = ({ password }: SubmitPasswordData) => {
    setPassword(password)
    sendCode({ resetPasswordToken, language: i18n.language })
    history.push(`${match.url}/${resetPasswordToken}/otp`)
  }

  if (!resetPasswordState.isReady)
    return <LoadingOverlay label={t('authentication.resetPassword.loadingLabel')} isLoading />

  return (
    <ResetPasswordAttemptContext.Provider value={resetPasswordState.attempt}>
      <IonRouterOutlet id="reset-password-pages">
        <Route exact path={`${match.url}/:token/otp`}>
          <ResetPasswordOTPPage
            onResetPasswordError={onResetPasswordError}
            onResetPasswordStep={onResetPasswordStep}
            password={password}
            resetPasswordToken={resetPasswordToken}
          />
        </Route>

        <Route exact path={`${match.url}/:token`}>
          <ResetPasswordPage
            onSetPassword={onSetPassword}
            onFinish={() => {
              history.replace('/login')
            }}
          />
        </Route>

        <Route
          render={() => (
            <Redirect
              to={
                resetPasswordToken > '' &&
                resetPasswordToken !== 'otp' &&
                resetPasswordState.attempt.state !== ResetPasswordAttemptState.Succeeded
                  ? `${match.url}/${resetPasswordToken}`
                  : '/login'
              }
            />
          )}
        />
      </IonRouterOutlet>
    </ResetPasswordAttemptContext.Provider>
  )
}
