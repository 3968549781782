import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Helmet } from 'react-helmet-async'

import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { useCustomThemeContext } from 'scaffold/providers/ThemeProvider'
import { ModalAnimation } from 'styles/animations/ModalAnimations'

import { AppSidebar } from './AppSidebar'
import { AppHeaderEffects } from './AppHeaderEffects'

export type Props = {
  className?: string
  goBack?: {
    localeLabel?: string
    to: string
  }
  title?: string
  titleDesktop?: string
}

export const AppHeader = (props: Props) => {
  const { t } = useTranslation()
  const [showSidebar, setShowSidebar] = useState(false)
  const history = useHistory()

  const customTheme = useCustomThemeContext()

  return (
    <HeaderWrapper>
      <Helmet>
        <title>{t('application.title')}</title>
      </Helmet>
      <AppSidebar open={showSidebar} close={() => setShowSidebar(false)} />

      <TopHeader animation={customTheme.animation}>
        {customTheme.theme.hasEffects && <AppHeaderEffects />}
        <HeaderContent>
          <img src="/assets/bob-logo-white.svg" style={{ width: 144 }} alt="bob Finance" />
          <Menu onClick={() => setShowSidebar(!showSidebar)} data-test-id="menu">
            <img src="/assets/menu.svg" style={{ width: 24 }} alt="Menu" />
          </Menu>
        </HeaderContent>
      </TopHeader>

      {(props.title || props.titleDesktop) && (
        <BottomHeader>
          <HeaderContent>
            {props.goBack && (
              <GoBackButton
                onClick={() => {
                  history.push(props.goBack?.to || '')
                }}
              >
                <ArrowImg src="/assets/chevron.svg" alt="arrow" />
              </GoBackButton>
            )}
            <Title
              data-test-id="header-title"
              style={{
                paddingLeft: props.goBack ? 32 : 0,
              }}
            >
              {props.title || props.titleDesktop}
            </Title>
          </HeaderContent>
        </BottomHeader>
      )}
    </HeaderWrapper>
  )
}

const Title = styled.h4`
  width: 100%;
  text-align: left;
`

const GoBackButton = styled.div`
  width: 64px;
  height: 48px;
  position: absolute;
  left: 0;
  top: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin-right: 32px;

  @media ${DesktopStyle} {
    margin-left: 24px;
  }
`

const ArrowImg = styled.img`
  width: 8px;
  margin-right: 8px;
  transform: rotate(180deg);
`

const Menu = styled.div`
  display: flex;
  padding: 12px;
  margin-left: auto;
  cursor: pointer;

  &:hover {
  }
`

const TopHeader = styled.div<{ animation: ModalAnimation }>`
  position: relative;
  background-color: ${({ theme }) => theme.appBackgroundHeader};
  height: 72px;
  transition: background-color 0.3s ease-out;

  ${({ animation }) =>
    animation === ModalAnimation.Seven &&
    css`
      cursor: url(/assets/cursor/bond-cursor-white.svg), auto;
    `}
`

const BottomHeader = styled.div`
  background-color: white;
  border-bottom: 1px solid var(--neutral-gray-2);
  height: 72px;
  width: 100%;
`

const HeaderWrapper = styled.div``

const HeaderContent = styled.div`
  max-width: 1200px;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  position: relative;
  display: flex;
  align-items: center;

  p {
    font-size: 12px;
  }

  @media ${MobileStyle} {
    padding: 0 16px 0 24px;
  }

  @media ${DesktopStyle} {
    padding: 0 48px;
  }
`
