/* tslint:disable */
/* eslint-disable */
/**
 * WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AuthorizationAttemptDTO,
    AuthorizationAttemptDTOFromJSON,
    AuthorizationAttemptDTOToJSON,
    DomainExceptionProblemDetails,
    DomainExceptionProblemDetailsFromJSON,
    DomainExceptionProblemDetailsToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
    SubmitMTanDTO,
    SubmitMTanDTOFromJSON,
    SubmitMTanDTOToJSON,
    SubmitPasswordDTO,
    SubmitPasswordDTOFromJSON,
    SubmitPasswordDTOToJSON,
} from '../models';

export interface AuthorizationAttemptSendAuthorizationAttemptMTanRequest {
    acceptLanguage?: string;
}

export interface AuthorizationAttemptSubmitAuthorizationAttemptMTanRequest {
    submitMTanDTO: SubmitMTanDTO;
}

export interface AuthorizationAttemptSubmitPasswordRequest {
    submitPasswordDTO: SubmitPasswordDTO;
}

/**
 * 
 */
export class AuthorizationAttemptApi extends runtime.BaseAPI {

    /**
     * Get the session\'s AuthorizationAttempt.
     */
    async authorizationAttemptGetAuthorizationAttemptRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationAttemptDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/authorizationAttempt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationAttemptDTOFromJSON(jsonValue));
    }

    /**
     * Get the session\'s AuthorizationAttempt.
     */
    async authorizationAttemptGetAuthorizationAttempt(initOverrides?: RequestInit): Promise<AuthorizationAttemptDTO> {
        const response = await this.authorizationAttemptGetAuthorizationAttemptRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async authorizationAttemptSendAuthorizationAttemptMTanRaw(requestParameters: AuthorizationAttemptSendAuthorizationAttemptMTanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationAttemptDTO>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.acceptLanguage !== undefined && requestParameters.acceptLanguage !== null) {
            headerParameters['Accept-Language'] = String(requestParameters.acceptLanguage);
        }

        const response = await this.request({
            path: `/authorizationAttempt/sendMtan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationAttemptDTOFromJSON(jsonValue));
    }

    /**
     */
    async authorizationAttemptSendAuthorizationAttemptMTan(requestParameters: AuthorizationAttemptSendAuthorizationAttemptMTanRequest = {}, initOverrides?: RequestInit): Promise<AuthorizationAttemptDTO> {
        const response = await this.authorizationAttemptSendAuthorizationAttemptMTanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async authorizationAttemptSubmitAuthorizationAttemptMTanRaw(requestParameters: AuthorizationAttemptSubmitAuthorizationAttemptMTanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationAttemptDTO>> {
        if (requestParameters.submitMTanDTO === null || requestParameters.submitMTanDTO === undefined) {
            throw new runtime.RequiredError('submitMTanDTO','Required parameter requestParameters.submitMTanDTO was null or undefined when calling authorizationAttemptSubmitAuthorizationAttemptMTan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authorizationAttempt/submitMtan`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitMTanDTOToJSON(requestParameters.submitMTanDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationAttemptDTOFromJSON(jsonValue));
    }

    /**
     */
    async authorizationAttemptSubmitAuthorizationAttemptMTan(requestParameters: AuthorizationAttemptSubmitAuthorizationAttemptMTanRequest, initOverrides?: RequestInit): Promise<AuthorizationAttemptDTO> {
        const response = await this.authorizationAttemptSubmitAuthorizationAttemptMTanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Submit a email + password combination.
     */
    async authorizationAttemptSubmitPasswordRaw(requestParameters: AuthorizationAttemptSubmitPasswordRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AuthorizationAttemptDTO>> {
        if (requestParameters.submitPasswordDTO === null || requestParameters.submitPasswordDTO === undefined) {
            throw new runtime.RequiredError('submitPasswordDTO','Required parameter requestParameters.submitPasswordDTO was null or undefined when calling authorizationAttemptSubmitPassword.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/authorizationAttempt/submitPassword`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubmitPasswordDTOToJSON(requestParameters.submitPasswordDTO),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AuthorizationAttemptDTOFromJSON(jsonValue));
    }

    /**
     * Submit a email + password combination.
     */
    async authorizationAttemptSubmitPassword(requestParameters: AuthorizationAttemptSubmitPasswordRequest, initOverrides?: RequestInit): Promise<AuthorizationAttemptDTO> {
        const response = await this.authorizationAttemptSubmitPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
