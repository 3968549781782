/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * Transaction associated to an AccountStatement.
 * @export
 * @interface AccountTransaction
 */
export interface AccountTransaction {
    /**
     * 
     * @type {string}
     * @memberof AccountTransaction
     */
    type: string;
    /**
     * 
     * @type {string}
     * @memberof AccountTransaction
     */
    translationKey: string;
    /**
     * 
     * @type {number}
     * @memberof AccountTransaction
     */
    orderSequenceNr: number;
    /**
     * 
     * @type {Date}
     * @memberof AccountTransaction
     */
    transactionAt: Date;
    /**
     * 
     * @type {number}
     * @memberof AccountTransaction
     */
    transactionAmount: number;
    /**
     * 
     * @type {number}
     * @memberof AccountTransaction
     */
    amountIncrease: number;
    /**
     * 
     * @type {number}
     * @memberof AccountTransaction
     */
    amountDecrease: number;
    /**
     * 
     * @type {Date}
     * @memberof AccountTransaction
     */
    lastUpdatedAt: Date;
}

export function AccountTransactionFromJSON(json: any): AccountTransaction {
    return AccountTransactionFromJSONTyped(json, false);
}

export function AccountTransactionFromJSONTyped(json: any, ignoreDiscriminator: boolean): AccountTransaction {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'type': json['type'],
        'translationKey': json['translationKey'],
        'orderSequenceNr': json['orderSequenceNr'],
        'transactionAt': (new Date(json['transactionAt'])),
        'transactionAmount': json['transactionAmount'],
        'amountIncrease': json['amountIncrease'],
        'amountDecrease': json['amountDecrease'],
        'lastUpdatedAt': (new Date(json['lastUpdatedAt'])),
    };
}

export function AccountTransactionToJSON(value?: AccountTransaction | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'type': value.type,
        'translationKey': value.translationKey,
        'orderSequenceNr': value.orderSequenceNr,
        'transactionAt': (value.transactionAt.toISOString()),
        'transactionAmount': value.transactionAmount,
        'amountIncrease': value.amountIncrease,
        'amountDecrease': value.amountDecrease,
        'lastUpdatedAt': (value.lastUpdatedAt.toISOString()),
    };
}

