import { ReactNode, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import { i18nextChangeLanguage } from '../translation/initI18next'
import { getLanguage } from '../translation/Languages'

// Change language if set as url query param
export const LanguageProvider = (props: { children: ReactNode }) => {
  const { i18n } = useTranslation()
  const location = useLocation()

  const urlSearchParams = new URLSearchParams(location.search)
  const userLanguage = urlSearchParams.get('lang') || i18n.language

  useEffect(() => {
    const language = getLanguage(userLanguage)
    if (language !== i18n.language) i18nextChangeLanguage(language)
  }, [userLanguage])

  return <>{props.children}</>
}
