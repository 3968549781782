import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { SingleCheckbox } from 'frr-web/lib/components/SingleCheckbox'
import { P } from 'frr-web/lib/html'
import styled from 'styled-components'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

interface AuthLoginStartTemplateProps {
  discardPage: boolean
  onDiscardPage: (value: boolean) => void
  onRegister: () => void
  onLogin: () => void
}

export const AuthLoginStartTemplate = (props: AuthLoginStartTemplateProps) => {
  return (
    <LayoutPageLandingContent
      title={'authentication.loginStart.title'}
      description="authentication.loginStart.text1"
    >
      <AuthenticationForm
        data={{}}
        formFields={[]}
        renderTopChildren={() => (
          <>
            <ButtonWrapper>
              <Button
                dataTestId="goto-signup"
                override={{ maxWidth: '100%' }}
                label="authentication.actions.gotoSignup.label"
                type={ButtonType.Primary}
                onClick={props.onRegister}
              />
            </ButtonWrapper>
            <P
              label="authentication.loginStart.migratedCustomerInfo"
              style={{ color: 'var(--ion-color-dark)' }}
            />

            <InfoLoginText>
              <P label={'authentication.loginStart.loginText'} />
            </InfoLoginText>
            <ButtonWrapper>
              <Button
                dataTestId="goto-login"
                override={{ maxWidth: '100%' }}
                label={'authentication.loginStart.actions.gotoLoginFromRegister.label'}
                type={ButtonType.Chromeless}
                onClick={props.onLogin}
              />
            </ButtonWrapper>
          </>
        )}
        renderBottomChildren={() => (
          <>
            <CheckboxWrapper>
              <SingleCheckbox
                error={false}
                value={props.discardPage}
                onChange={props.onDiscardPage}
                label={{
                  label: 'authentication.loginStart.discardPage',
                }}
              />
            </CheckboxWrapper>
          </>
        )}
      />
    </LayoutPageLandingContent>
  )
}

const ButtonWrapper = styled.div`
  margin-bottom: 16px;
`
const InfoLoginText = styled.div`
  margin-bottom: 16px;

  @media ${DesktopStyle} {
    margin-top: 48px;
  }

  @media ${MobileStyle} {
    margin-top: 16px;
  }
`
const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: flex-start;
  justify-content: flex-end;

  @media ${DesktopStyle} {
    position: absolute;
    bottom: 0;
    margin-bottom: 8px;
  }

  @media ${MobileStyle} {
    margin-top: 20px;
  }
`
