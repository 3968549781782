import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ButtonType } from 'frr-web/lib/components/Button'

import { OnboardingContent } from 'components/layout/OnboardingLayout'
import { IdentityModal } from 'components/modal/Identity.modal'
import { DocumentsMissingModal } from 'components/modal/DocumentsMissing.modal'
import { usePhotoGallery } from 'hooks/usePhotoGallery.hook'

import {
  DocumentNonSwissNationalityOptions,
  DocumentSwissNationalityOptions,
  DocumentType,
} from '../../../types/Loan.d'
import { Loan } from '../../../types/Loan'
import { UploadFileRow, UploadRowT } from '../../../components/UploadFileRow'

export type Props = {
  loan: Loan
  requiredDocuments: Array<DocumentType>
  idDocuments: {
    documentsOptions: Array<DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions>
    documentOptionSelected: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null
    setDocumentOptionSelected: (
      v: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null,
    ) => void
    documents: Array<DocumentType>
  }
}

export const OnboardingUploadDocumentsTemplate = (props: Props) => {
  const { takePhoto, files, setFiles } = usePhotoGallery()
  const { t } = useTranslation()
  const [missingModal, setMissingModal] = useState(false)
  const [identityModal, setIdentityModal] = useState(false)

  const isIdentityUploaded = props.idDocuments.documents.some((f) => files[f])

  const items: Array<UploadRowT> = [
    {
      label: isIdentityUploaded
        ? t(`Identity ${props.idDocuments.documentOptionSelected}`)
        : t('document.identity'),
      type: DocumentType.SwissPassport,
      uploadLabel: !isIdentityUploaded ? t('documentUpload.uploadId') : t('documentUpload.remove'),
      onUploadClick: () => {
        setIdentityModal(true)
      },
      required: !isIdentityUploaded,
      removeClick: isIdentityUploaded,
    },
    {
      label: t(`document.${DocumentType.WageStatementPartnerLastMonth}`),
      type: DocumentType.WageStatementPartnerLastMonth,
      required: true,
      file: files[DocumentType.WageStatementPartnerLastMonth],
    },
    {
      label: t(`document.${DocumentType.WageStatementPartnerMonthBeforeLast}`),
      type: DocumentType.WageStatementPartnerMonthBeforeLast,
      required: false,
      file: files[DocumentType.WageStatementPartnerMonthBeforeLast],
    },
    {
      label: t(`document.${DocumentType.WageStatementPartnerMonthTwiceBeforeLast}`),
      type: DocumentType.WageStatementPartnerMonthTwiceBeforeLast,
      required: false,
      file: files[DocumentType.WageStatementPartnerMonthTwiceBeforeLast],
    },
  ]

  const upload = () => {
    // TODO
    // Do the server upload
  }

  const isDisabled = items.some((f) => f.type && f.required && !files[f.type])
  const missingFile = items.some((f) => f.type && !files[f.type])

  return (
    <OnboardingContent
      title={t('documentUploadInitial.title')}
      description={t('documentUploadInitial.description')}
      leftButton={{
        label: t('documentUploadInitial.back'),
        type: ButtonType.Chromeless,
        onClick: () => {
          // TODO: handle back button
        },
        override: { width: 80 },
      }}
      rightButton={{
        label: t('documentUploadInitial.submit'),
        type: ButtonType.Primary,
        onClick: () => {
          if (missingFile) {
            setMissingModal(true)
          } else {
            upload()
          }
        },
        override: { marginLeft: 'auto' },
        disabled: isDisabled,
      }}
    >
      <>
        <IdentityModal
          idDocuments={props.idDocuments}
          onChange={(f) => {
            setFiles({ ...files, ...f })
          }}
          onRemove={(itemType) => {
            setFiles({ ...files, [itemType]: undefined })
            props.idDocuments.setDocumentOptionSelected(null)
          }}
          modal={{
            isOpen: identityModal,
            onClose: () => {
              setIdentityModal(false)
            },
          }}
        />
        <DocumentsMissingModal
          modal={{
            isOpen: missingModal,
            onClose: () => {
              setMissingModal(false)
            },
          }}
          onSubmit={() => {
            upload()
          }}
        />

        {items.map((item, key) => (
          <UploadFileRow
            {...item}
            type={item.type}
            key={key}
            onRemove={() => {
              if (item.type) setFiles({ ...files, [item.type]: undefined })
            }}
            onChange={(newFiles) => {
              setFiles({ ...files, ...newFiles.reduce((obj, i) => ({ ...obj, [i.type]: i.file }), {}) })
            }}
            onTakePhoto={takePhoto}
          />
        ))}
      </>
    </OnboardingContent>
  )
}
