export const get = (url: string, header?: Record<string, string>) =>
  fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(header || {}),
    },
  }).catch((error) => {
    throw error
  })

export const post = (url: string, data?: any, header?: Record<string, string>) =>
  fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(header || {}),
    },
    body: JSON.stringify(data || {}),
  }).catch((error) => {
    throw error
  })
