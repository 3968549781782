import { Form, FormProps } from 'frr-web/lib/form/components/Form'
import { CSSProperties } from 'frr-web/lib/theme/configure.theme'
import { renderHtml } from 'frr-web/lib/utils/renderHtml'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

type LinkProps = { url: string; label: string; dataTestId: string }

export const AuthenticationForm = <T extends {}>({
  dataTestId,
  error,
  info,
  links,
  renderBottomChildren,
  linksStyle,
  ...formProps
}: FormProps<T> & {
  dataTestId?: string
  error?: string | null
  info?: string | null
  links?: Array<LinkProps>
  linksStyle?: CSSProperties
}) => {
  const { t } = useTranslation()

  return (
    <FormWrapper data-test-id={dataTestId}>
      <Form
        {...formProps}
        renderBottomChildren={(data) => (
          <FormBottomContent style={linksStyle}>
            {renderBottomChildren?.(data)}
            {links?.map((link: LinkProps) => (
              <LinkText key={link.dataTestId}>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <Link data-test-id={link.dataTestId} to={link.url} tabIndex={-1}>
                  {t(link.label)}
                </Link>
              </LinkText>
            ))}
            {!!error && <ErrorText data-test-id={error}>{t(error)}</ErrorText>}
            {!!info && <InfoText data-test-id={info}>{renderHtml(t(info))}</InfoText>}
          </FormBottomContent>
        )}
      />
    </FormWrapper>
  )
}

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`

const FormBottomContent = styled.div`
  position: relative;
  width: 100%;
  flex-grow: 1;
  padding: 0;
  z-index: 2;
`

const LinkText = styled.p`
  width: 100%;
  text-align: right;
  padding-right: 8px;

  @media ${MobileStyle} {
    margin-bottom: 24px;
  }
  @media ${DesktopStyle} {
    margin-bottom: 32px;
  }
`

const ErrorText = styled.p`
  width: 100%;
  color: var(--ion-color-danger);
  font-size: 16px;
`

const InfoText = styled.p`
  width: 100%;
  font-size: 16px;
  margin-top: 32px;
`
