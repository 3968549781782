import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import { useState } from 'react'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'

import { CredentialsData } from 'types/Authentication'
import { validateEmail } from 'pages/utils/validate'

export const AuthLoginTemplate = (props: {
  data: CredentialsData
  error: string | null
  formFields: Array<FormField<CredentialsData>>
  isBlocked: boolean
  isLoading: boolean
  onChange: () => void
  onLogin: (values: CredentialsData) => void
  disableDirtyValidation?: boolean
  setDisableDirtyValidation?: (value: boolean) => void
}) => {
  const [values, setValues] = useState(props.data)

  const onChange = (values: CredentialsData) => {
    setValues(values)
    props.onChange()
    if (values.username === null || values.username === '') {
      props.setDisableDirtyValidation?.(true)
    }
    if (!!values.username && values.username !== '') {
      props.setDisableDirtyValidation?.(false)
    }
  }

  const onLogin = () => {
    props.onLogin(values)
  }

  return (
    <LayoutPageLandingContent title={'authentication.login.title'}>
      <AuthenticationForm
        data={values}
        dataTestId={'login-form'}
        error={props.error}
        formFields={props.formFields}
        linksStyle={{ marginTop: '-36px' }}
        links={[
          {
            dataTestId: 'forgot-password-link',
            label: 'authentication.formFields.forgotPassword.label',
            url: '/login/forgot-password',
          },
          {
            dataTestId: 'start-registration-link',
            label: 'authentication.formFields.startRegistration.label',
            url: '/login/register',
          },
        ]}
        onChange={onChange}
        onSubmit={onLogin}
        buttons={[
          {
            isDisabled: (data) =>
              props.isBlocked ||
              props.isLoading ||
              validateEmail(values.username) !== null ||
              values.username === null ||
              values.username === '' ||
              values.password === null ||
              values.password === '',
            label: 'authentication.actions.login.label',
            onClick: ({ submit }) => submit(),
            type: ButtonType.Primary,
          },
        ]}
        disableDirtyValidation={props.disableDirtyValidation}
      />
    </LayoutPageLandingContent>
  )
}
