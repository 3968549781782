import { Loading } from 'frr-web/lib/components/Loading'
import styled, { CSSProperties } from 'styled-components'

import { ProfileCard } from 'components/card/ProfileCard'
import { DesktopStyle } from 'styles/theme/IonicMediaQuery'

import { PaymentSlipDataProps, PaymentSlipDataResult } from './PaymentSlip.data'

type Props = {
  values: PaymentSlipDataProps
  paymentSlipData: PaymentSlipDataResult
  setValues: (values: PaymentSlipDataProps) => void
  onSubmit: () => void
  buttonDisabled: boolean
  isLoading: boolean
  style?: {
    form?: {
      wrapper?: CSSProperties
      content?: CSSProperties
    }
    row?: {
      wrapper?: CSSProperties
      item?: CSSProperties
    }
  }
}

export const PaymentSlipTemplate = (props: Props) => {
  return (
    <>
      {props.isLoading ? (
        <LoadingWrapper>
          <Loading
            style={{
              transform: 'scale(0.3)',
              height: 32,
              width: 32,
              marginLeft: -16,
            }}
          />
        </LoadingWrapper>
      ) : (
        <StyledProfileCard
          data={props.values}
          formFields={props.paymentSlipData.formFields}
          onChange={(formState: PaymentSlipDataProps) =>
            props.setValues({ ...props.values, ...formState })
          }
          onSubmit={props.onSubmit}
          buttonDisabled={props.buttonDisabled}
          buttonLabel={'paymentSlip.formFields.actions.send.label'}
          style={props.style}
        />
      )}
    </>
  )
}

const StyledProfileCard = styled(ProfileCard)`
  margin-left: 0px;
  padding: 12px;

  @media ${DesktopStyle} {
    margin-left: 24px;
    padding: 48px;
  }
`

const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 48px;
`
