import styled from 'styled-components'

import { ProfileCard } from 'components/card/ProfileCard'
import { DesktopStyle } from 'styles/theme/IonicMediaQuery'
import { LoadingOverlayModal } from 'components/overlay/LoadingOverlayModal'
import { ChangePassword } from 'types/Authentication'

import { SecurityDataResult } from './Security.data'

export type Props = {
  values: ChangePassword
  setValues: (values: ChangePassword) => void
  formFields: ReturnType<SecurityDataResult['formFields']>
  onSubmit: () => void
  buttonDisabled: boolean
  isLoading: boolean
  disableDirtyValidation: boolean
}

export const SecurityTemplate = (props: Props) => {
  const onChange = (formState: ChangePassword) => {
    props.setValues({ ...props.values, ...formState })
  }

  return props.isLoading ? (
    <LoadingOverlayModal />
  ) : (
    <StyledProfileCard
      data={props.values}
      formFields={props.formFields}
      onChange={onChange}
      onSubmit={props.onSubmit}
      buttonDisabled={props.buttonDisabled}
      buttonLabel={'security.actions.send.label'}
      disableDirtyValidation={props.disableDirtyValidation}
    />
  )
}

const StyledProfileCard = styled(ProfileCard)`
  margin-left: 0px;
  padding: 12px;

  @media ${DesktopStyle} {
    margin-left: 24px;
    padding: 48px;
  }
`
