/* eslint-disable react-hooks/exhaustive-deps */
import { CSSProperties, ReactNode, useEffect, useLayoutEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'

import { useWindowDimensions } from 'components/utils/getWindowDimensions'
import { useCustomThemeContext } from 'scaffold/providers/ThemeProvider'
import { ModalAnimation, ModalAnimations } from 'styles/animations/ModalAnimations'
import { DesktopStyle, MobileStyle, MobileWidth } from 'styles/theme/IonicMediaQuery'

const modalRoot = document.getElementById('modal-root')

export type Props = {
  onClose: () => void
  onSubmit?: () => void
  isOpen: boolean
  children: ReactNode
  style?: CSSProperties
}

export const Modal = (props: Props) => {
  const [show, setShow] = useState(false)
  const [close, setClose] = useState(false)

  const { width } = useWindowDimensions()

  const customContext = useCustomThemeContext()

  const onClose = () => {
    if (width > MobileWidth) props.onClose()
  }

  useEffect(() => {
    let timerId: any
    if (props.isOpen && !show) {
      setShow(true)
    } else if (!props.isOpen && show) {
      setClose(true)
      const delay = customContext.animation === ModalAnimation.Seven ? 2000 : 700
      timerId = setTimeout(() => {
        setShow(false)
        setClose(false)
      }, delay)
    }

    return timerId ? () => clearTimeout(timerId) : undefined
  }, [props.isOpen, show, close])

  useLayoutEffect(() => {
    if (show) {
      const pageContent = document.getElementById('page-content')
      if (pageContent) {
        pageContent.className = `${customContext.animation}__content`
      }
    }
  }, [show])

  useLayoutEffect(() => {
    if (close) {
      const pageContent = document.getElementById('page-content')
      if (pageContent) {
        pageContent.className = `${customContext.animation}__content out`
      }
    }
  }, [close])

  return show
    ? ReactDOM.createPortal(
        <ModalWrapper
          animation={customContext.animation}
          className={close ? 'out' : ''}
          id="modal-container"
          data-test-id="modal-container"
          show={show}
        >
          <ModalBackgroundWrapper onMouseDown={onClose} className="modal-background">
            <ModalContentWrapper
              className="modal"
              style={props.style}
              onMouseDown={(e) => {
                e.stopPropagation()
              }}
            >
              <div className="modal-content">{props.children}</div>
            </ModalContentWrapper>
          </ModalBackgroundWrapper>
        </ModalWrapper>,
        modalRoot as Element,
      )
    : null
}

const ModalWrapper = styled.div<{ show: boolean; animation: ModalAnimation }>`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  transform: scale(0);

  ${({ show, animation }) => show && ModalAnimations[animation].Modal}
`

const ModalBackgroundWrapper = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;

  /* background: var(--modal-backdrop-background-color); */
  background: rgba(170, 68, 66, 0.8);

  display: flex;
  justify-content: center;

  @media ${DesktopStyle} {
    align-items: center;
  }

  @media ${MobileStyle} {
    overflow-y: auto;
  }
`

const ModalContentWrapper = styled.div`
  background: var(--modal-background-color, white);
  max-width: 600px;
  padding: 24px 32px;
  width: 100%;
  z-index: 10;

  @media ${DesktopStyle} {
    border-radius: 8px;
    max-height: calc(var(--vh, 1vh) * 100 - 240px);
    overflow-y: auto;
  }
`
