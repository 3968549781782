import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { ModalWithContent } from './ModalWithContent'
import { Props as ModalProps } from './Modal'

type Props = {
  title: string
  message: string
  modal: Omit<ModalProps, 'children'>
}

export const ErrorMessageModal = (props: Props) => {
  const { t } = useTranslation()
  return (
    <ModalWithContent
      modal={{
        ...props.modal,
      }}
      title={props.title}
    >
      <ErrorWrapper>
        <ErrorText>{t(props.message)}</ErrorText>
      </ErrorWrapper>
    </ModalWithContent>
  )
}

const ErrorText = styled.p`
  color: var(--ion-color-danger);
  padding-top: 32px;
`

const ErrorWrapper = styled.div`
  text-align: center;
  padding-bottom: 32px;
`
