import { useTranslation } from 'react-i18next'
import styled, { CSSProperties } from 'styled-components'

type Props = {
  items: Array<{
    label: string
    value: string
  }>
  style?: CSSProperties
}

export const DataPreview = (props: Props) => {
  const { t } = useTranslation()
  return (
    <Wrapper style={props.style}>
      {props.items.map((item, key) => (
        <Item key={key}>
          <Label>{t(item.label)}</Label>
          <Value>{item.value}</Value>
        </Item>
      ))}
    </Wrapper>
  )
}

const Item = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  height: 40px;
`

const Label = styled.p`
  color: var(--neutral-gray-4);
`

const Value = styled.p``

const Wrapper = styled.div`
  border: 1px solid var(--neutral-gray-2);
  border-radius: 8px;
  padding: 8px 16px;
`
