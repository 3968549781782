/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Customer,
    CustomerFromJSON,
    CustomerToJSON,
    CustomerChangeRequest,
    CustomerChangeRequestFromJSON,
    CustomerChangeRequestToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface CustomerCreateCustomerChangeRequestRequest {
    customerChangeRequest: CustomerChangeRequest;
}

/**
 * 
 */
export class CustomerApi extends runtime.BaseAPI {

    /**
     * Create a change request for the authenticated user\'s customer.
     */
    async customerCreateCustomerChangeRequestRaw(requestParameters: CustomerCreateCustomerChangeRequestRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.customerChangeRequest === null || requestParameters.customerChangeRequest === undefined) {
            throw new runtime.RequiredError('customerChangeRequest','Required parameter requestParameters.customerChangeRequest was null or undefined when calling customerCreateCustomerChangeRequest.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/customers/me/changeRequests`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CustomerChangeRequestToJSON(requestParameters.customerChangeRequest),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a change request for the authenticated user\'s customer.
     */
    async customerCreateCustomerChangeRequest(requestParameters: CustomerCreateCustomerChangeRequestRequest, initOverrides?: RequestInit): Promise<void> {
        await this.customerCreateCustomerChangeRequestRaw(requestParameters, initOverrides);
    }

    /**
     * Get the user\'s customer.
     */
    async customerGetCustomerByMeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Customer>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/customers/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CustomerFromJSON(jsonValue));
    }

    /**
     * Get the user\'s customer.
     */
    async customerGetCustomerByMe(initOverrides?: RequestInit): Promise<Customer> {
        const response = await this.customerGetCustomerByMeRaw(initOverrides);
        return await response.value();
    }

}
