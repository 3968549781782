import styled from 'styled-components'
import { IoDownloadOutline } from 'react-icons/io5'
import { Loading } from 'frr-web/lib/components/Loading'
import { useTranslation } from 'react-i18next'

import { DocumentsList as DocumentsListProps } from 'pages/loanDetail/documentsDownload/DocumentsDownload.page'
import { DocumentTypeEnum } from 'data/openapi/backend'
import { MobileStyle } from 'styles/theme/IonicMediaQuery'

type Props = {
  onDownload: (key: DocumentTypeEnum) => void
  documentsList: DocumentsListProps
}

export const DocumentsList = (props: Props) => {
  const { t } = useTranslation()
  const { documentsList, onDownload } = props

  return (
    <>
      {Object.keys(documentsList).map((key, index) => {
        return (
          <div key={`${key}-${index}`}>
            <DocumentItem onClick={() => onDownload(key as DocumentTypeEnum)}>
              {t(documentsList[key].translationKey, { year: documentsList[key].datedAt.getFullYear() })}
              <DocumentDownloadIcon
                style={{
                  pointerEvents: documentsList[key as string].isLoading ? 'none' : 'auto',
                }}
              >
                {documentsList[key as string].isLoading ? (
                  <Loading
                    style={{
                      transform: 'scale(0.3)',
                      paddingTop: 8,
                      marginRight: 3,
                      marginTop: -12,
                      marginBottom: 10,
                      height: 32,
                      width: 32,
                    }}
                  />
                ) : (
                  <IoDownloadOutline style={{ height: 24, width: 24 }} />
                )}
              </DocumentDownloadIcon>
            </DocumentItem>
            {index !== Object.keys(documentsList).length - 1 && <Separator />}
          </div>
        )
      })}
    </>
  )
}

const Separator = styled.div`
  background-color: var(--neutral-gray-2);
  height: 1px;
  margin: 16px -16px;
`

const DocumentItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  @media ${MobileStyle} {
    padding-right: 16px;
    padding-left: 16px;
  }

  &:hover {
    color: #41afc8;
  }
`

const DocumentDownloadIcon = styled.div``
