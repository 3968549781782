import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { ResetPasswordAttemptState } from 'data/authenticationapi/models/ResetPasswordAttempt'
import { ResetPasswordAttemptChallenge, SubmitResetPasswordData } from 'types/ResetPassword'

const otpFormLens = makeFormLens<SubmitResetPasswordData>()

const otpFormFields: Array<FormField<SubmitResetPasswordData>> = [
  {
    type: FormFieldType.FormSection,
    fields: [
      {
        type: FormFieldType.CodeInput,
        label: { label: 'authentication.formFields.code.label' },
        length: 6,
        lens: otpFormLens(['code']),
        required: true,
        isAutoFocus: true,
      },
    ],
  },
]

interface OTPDataResult {
  data: SubmitResetPasswordData
  // error: string | null
  formFields: Array<FormField<SubmitResetPasswordData>>
  isBlocked: boolean
  isResendDisabled: boolean
  phoneNr?: string
}

export const ResetPasswordOTPData = (params: {
  attempt: ResetPasswordAttemptChallenge | { state: ResetPasswordAttemptState.Error; error: string }
  password: string
  resetPasswordToken: string
}): OTPDataResult => {
  return params.attempt.state === ResetPasswordAttemptState.Error
    ? {
        data: {
          code: '',
          password: '',
          resetPasswordToken: params.resetPasswordToken,
        },
        // error: params.attempt.error,
        formFields: otpFormFields,
        isBlocked: true,
        isResendDisabled: true,
        phoneNr: '',
      }
    : {
        data: {
          code: '',
          password: params.password,
          resetPasswordToken: params.resetPasswordToken,
        },
        // error:
        //   (params.attempt.currentMTanFailCount >= params.attempt.maxMTanFailCount &&
        //     'authentication.errors.maxSubmitOTPCount') ||
        //   (params.attempt.currentMTanFailCount > 0 && 'authentication.errors.invalidCode') ||
        //   null,
        isBlocked:
          params.attempt.state !== ResetPasswordAttemptState.MTanCallenge ||
          params.attempt.currentMTanFailCount >= params.attempt.maxMTanFailCount,
        isResendDisabled: params.attempt.currentMTanSendCount >= params.attempt.maxMTanSendCount,
        formFields: otpFormFields,
        phoneNr: params.attempt.phoneNr?.replaceAll('#', '*'),
      }
}
