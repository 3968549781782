import React from 'react'
import styled from 'styled-components'
import { SeverityLevel } from '@microsoft/applicationinsights-web'

import { TrackEventType } from 'types/Analytics.d'
import { appInsightsTracker, traceID } from './providers/AppInsightsProvider'
import { api, Mutation } from 'data/api'
import { MobileStyle } from 'styles/theme/IonicMediaQuery'

const text: Record<'IT' | 'DE' | 'FR', { title: string; description: string }> = {
  DE: {
    title: 'Es ist ein Fehler aufgetreten',
    description:
      'Leider ist ein technischer Fehler aufgetreten. Bitte starten Sie das Kundenportal von bob Finance neu. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an unseren Kundenservice unter +41 44 244 25 00',
  },

  FR: {
    title: "Une erreur s'est produite",
    description:
      "Malheureusement il s'est produit une erreur. Veuillez redémarrer le portail client de bob Finance. Si le problème persiste, veuillez contacter notre service clientèle au +41 44 244 25 00",
  },

  IT: {
    title: 'Si è verificato un errore',
    description:
      'Purtroppo si è verificato un errore tecnico. Si prega di riavviare il portale clienti bob Finance. Se il problema persiste, contatti il nostro servizio clienti al +41 44 244 25 00',
  },
}

const windowClearStorageAndReload = () => {
  const win = window as Window
  win.localStorage.clear()
  win.sessionStorage.clear()
  win.location.reload()
}

type ErrorBoundaryProps = {
  children: React.ReactNode
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }
  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(exception: any) {
    console.error('EXCEPTION', exception)
    appInsightsTracker.trackException({
      type: TrackEventType.Exception,
      exception,
      severity: SeverityLevel.Error,
    })
  }

  render() {
    const state = this.state as { hasError: boolean; errorLabel: string }

    const languages = ['DE', 'FR', 'IT'] as Array<'IT' | 'DE' | 'FR'>

    return state.hasError ? (
      <Wrapper data-test-id="error-page">
        <Container>
          <WarningImage src={'/assets/warning.svg'} alt="error" />

          {languages.map((l) => (
            <ErrorItem key={l}>
              <h5>{text[l].title}</h5>
              <p>{text[l].description}</p>
            </ErrorItem>
          ))}
          {process.env.NODE_ENV !== 'production' && <p>TRACE ID: {traceID}</p>}
          <Button
            onClick={() => {
              // Try to logout
              api[Mutation.Logout]().then(windowClearStorageAndReload).catch(windowClearStorageAndReload)
            }}
          >
            Ok
          </Button>
        </Container>
      </Wrapper>
    ) : (
      this.props.children
    )
  }
}

const WarningImage = styled.img`
  width: 144px;
  height: 144px;
  margin-bottom: 32px;
`

const ErrorItem = styled.div`
  margin: 16px 0;
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #fafafa;

  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
`
const Container = styled.div`
  width: 580px;
  overflow-y: auto;

  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 48px 32px;

  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${MobileStyle} {
    width: 100%;
    height: 100%;
    background-color: transparent;
  }

  & > h1 {
    max-width: 480px;
    font-size: 32px;
    font-weight: 700;
    margin: 24px 16px 8px;
    font-family: Helvetica;
    text-align: center;
  }

  & > h3 {
    max-width: 480px;
    font-size: 18px;
    opacity: 0.5;
    margin: 8px 16px;
    font-family: Helvetica;
    text-align: center;
  }

  & > p {
    max-width: 480px;
    font-size: 14px;
    opacity: 0.5;
    margin: 8px 16px 32px;
    font-family: Helvetica;
    text-align: center;
  }
`

const Button = styled.div`
  border: 0;
  width: 144px;
  height: 32px;
  color: white;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 4px;
  font-family: Arial;
  line-height: 32px;
  text-align: center;
  cursor: pointer;
  margin-top: 48px;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: rgba(80, 80, 80, 1);
  }
`
