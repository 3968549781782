import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { api, Query } from 'data/api'
import { Document, DocumentTypeEnum } from 'data/openapi/backend'
import { downloadFileFromBlob } from 'pages/utils/downloadFile'
import { Loan } from 'types/Loan.d'

import { ErrorMessageModal } from 'components/modal/ErrorMessage.modal'
import { DocumentsDownloadTemplate } from './DocumentsDownload.template'
import { ExpandedDocumentsDownloadTemplate } from './ExpandedDocumentsDownload.template'

type Props = {
  onClose: () => void
  loan: Loan
  documentsList: Document[]
  groups?: Array<DocumentTypeEnum>
}

export type DocumentListElement = {
  translationKey: string
  datedAt: Date
  type: DocumentTypeEnum
  isLoading: boolean
  isError: boolean
}

export interface DocumentsList {
  [key: string]: DocumentListElement
}

export const DocumentsDownloadPage = (props: Props) => {
  const { t, i18n } = useTranslation()

  const [documentsList, setDocumentsList] = useState<DocumentsList>(
    props.documentsList.reduce(
      (o, key) => ({
        ...o,
        [key['id']]: {
          translationKey: key['translationKey'],
          datedAt: key['datedAt'],
          type: key['type'],
          isLoading: false,
          isError: false,
        },
      }),
      {},
    ),
  )

  const [showErrorModal, setShowErrorModal] = useState<boolean>(false)

  const onClick = (documentId: string) => {
    if (!documentsList[documentId].isLoading) {
      setDocumentsList((prev) => ({
        ...prev,
        [documentId]: { ...prev[documentId], isLoading: true, isError: false },
      }))

      api[Query.FetchLoanDocument]({ documentId, acceptLanguage: i18n.language })
        .then((blob: Blob) => {
          downloadFileFromBlob(
            blob,
            `${t(
              `documents.modal.filename.product_${
                !!props.loan.product?.productId
                  ? props.loan.product.productId.replace(' ', '')
                  : 'default'
              }`,
              {
                documentType: t(
                  `${props.documentsList.find((d) => d.id === documentId)?.translationKey}`,
                  { year: null },
                ).replace(/\s/g, ''),
                year:
                  props.documentsList.find((d) => d.id === documentId)?.type ===
                  DocumentTypeEnum.AccountStatement
                    ? props.documentsList.find((d) => d.id === documentId)?.datedAt.getFullYear()
                    : null,
              },
            )}`,
          ).finally(() => {
            setDocumentsList((prev) => ({
              ...prev,
              [documentId]: { ...prev[documentId], isLoading: false, isError: false },
            }))
          })
        })
        .catch(() => {
          setDocumentsList((prev) => ({
            ...prev,
            [documentId]: { ...prev[documentId], isLoading: false, isError: true },
          }))
        })
    }
  }

  useEffect(() => {
    if (!!Object.keys(documentsList).find((doc) => documentsList[doc].isError)) {
      setShowErrorModal(true)
    }
  }, [documentsList])

  return (
    <>
      <ErrorMessageModal
        title={'documents.download.error.title'}
        modal={{ isOpen: showErrorModal, onClose: () => setShowErrorModal(false) }}
        message={t('documents.download.error.message')}
      />
      {props.groups ? (
        <ExpandedDocumentsDownloadTemplate
          documentsList={documentsList}
          onDownload={onClick}
          groups={props.groups}
        />
      ) : (
        <DocumentsDownloadTemplate documentsList={documentsList} onDownload={onClick} />
      )}
    </>
  )
}
