import { api, Mutation } from 'data/api'
import { useEffect, useLayoutEffect, useState } from 'react'
import { UseQueryResult } from 'react-query'

export const MyCockpitApiRetry = (failureCount: number, error: Response) => {
  // Quit immediately if the user is unauthorized
  if (error.status === 401) {
    return false
  } else if (failureCount > 1) {
    return false
  } else {
    return true
  }
}

interface ErrorDetails {
  detail?: string // 'CustomerRelationship not found.'
  status: number
  title: string // 'Not Found'
  traceId?: string // '00-9d499a6eb5bc3c4f9f93a3fb57e779ff-ba6942078eea434d-00'
  type?: string // 'https://tools.ietf.org/html/rfc7231#section-6.5.4'
}

export const MapMyCockpitApiResponse = <T extends {}>(response: UseQueryResult<T, Response>) => {
  const [errorDetails, setErrorDetails] = useState<ErrorDetails | null>(null)
  const { data, isLoading, isError, error } = response

  const isSessionExpired = isError && error?.status === 401

  if (!isLoading && !isError && !data) throw new Error(` 💀  Data not found`)

  useEffect(() => {
    if (isSessionExpired) {
      api[Mutation.Logout]().then(() => {
        // Use window.location here to enforce browser refresh
        window.location.href = `${document.baseURI}logout/session-expired`
      })
    } else if (error) {
      error.json().then(setErrorDetails)
    }
  }, [isSessionExpired, error])

  useLayoutEffect(() => {
    if (errorDetails) throw new Error(errorDetails.detail || errorDetails.title)
  }, [errorDetails])

  return { isLoading: isLoading || isError, data }
}
