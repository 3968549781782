import { ComponentThemeConfig } from 'frr-web/lib/theme/theme.components'

export const componentTheme: Partial<ComponentThemeConfig> = {
  codeInput: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      margin: '16px 0',
      '@media-mobile': {
        margin: '0 0 32px',
      },
    },
    input: {
      border: '1px solid var(--ion-border-color)',
      borderRadius: 4,
      backgroundColor: 'transparent',
      caretColor: 'transparent',
      color: 'var(--ion-text-color)',
      cursor: 'pointer',
      fontSize: 24,
      fontWeight: 600,
      height: 56,
      marginRight: 10,
      padding: 0,
      textAlign: 'center',
      width: 40,

      ':focus': {
        outline: 0,
        borderColor: 'var(--secondary-yellow-1)',
      },
    },
  },
  optionGroup: {
    wrapper: {
      cursor: 'pointer',
      border: '1px solid var(--ion-border-color)',
      borderRadius: 4,
    },
    itemActive: {},
    item: {
      minWidth: 60,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexGrow: 1,
      padding: '16px 16px',
    },
    label: {
      textAlign: 'center',
      fontSize: 14,
    },
    labelActive: {
      fontWeight: 'bold',
    },
  },
  textInput: {
    errorWrapper: {
      borderColor: 'var(--ion-color-danger)',
    },
    errorHook: {
      backgroundColor: 'var(--ion-color-danger)',
    },
    readOnlyWrapper: {
      borderWidth: 0,
    },
    readOnlyInput: {
      textAlign: 'left',
    },
    readOnlyHook: {
      display: 'none',
    },
    wrapper: {
      flexGrow: 1,
      boxShadow: '1px 1px 1px 1px rgba(0,0,0, 0.02)',
      display: 'flex',
      alignItems: 'center',
      height: 48,
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 8,
      borderColor: 'var(--ion-border-color)',
      width: '100%',
      position: 'relative',
      transition: 'border 0.1s',
      ':hover': {
        borderColor: 'var(--ion-border-color-focus)',
      },
    },
    wrapperFocus: {
      borderColor: 'var(--ion-border-color-focus)',
    },
    input: {
      width: '100%',
      height: '100%',
      border: 0,
      fontSize: 14,
      fontFamily: 'var(--font-family)',
      backgroundColor: 'transparent',
      color: 'black',
      padding: '0 16px',
    },
    hook: {
      display: 'none',
    },
    disabledInput: {
      color: 'var(--ion-color-medium)',
      borderRadius: 8,
      background: 'var(--ion-background-color)',
    },
    disabledWrapper: {},
    errorInput: {},
  },
  button: {
    secondary: {
      color: 'white',
      height: 48,
      background: 'var(--primary-blue-4)',
    },
    secondaryLabel: {
      fontFamily: 'var(--font-family)',
      color: 'white',
      fontSize: 18,
      fontWeight: 600,
    },
    chromeless: {
      background: 'transparent',
      border: '1px solid var(--neutral-gray-2)',
      height: 48,
    },
    chromelessLabel: {
      fontWeight: 600,
      fontSize: 16,
    },
    primary: {
      height: 48,
      background: 'var(--secondary-yellow-1)',
      ':disabled': {
        opacity: 0.6,
        pointerEvents: 'none',
      },
      ':focus': {
        filter: 'brightness(80%)',
      },
    },
    primaryLabel: {
      fontWeight: 600,
      fontSize: 18,
      color: 'var(--secondary-yellow-3)',
    },
    common: {
      borderRadius: 4,
      padding: '0 12px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      cursor: 'pointer',
      width: '100%',
      maxWidth: 490,
      transition: 'all 0.7s ease',
      ':disabled': {
        cursor: 'default',
        opacity: 0.5,
      },
      ':hover': {
        filter: 'brightness(80%)',
      },
      ':focus': {
        boxShadow: '2px 2px 8px rgba(0, 0, 0, 0.35), 0 -2px 8px rgba(0, 0, 0, 0.05)',
      },
    },
  },
  textArea: {
    wrapper: {
      display: 'flex',
      alignItems: 'center',
      borderStyle: 'solid',
      borderWidth: 1,
      borderRadius: 8,
      borderColor: 'var(--ion-border-color)',
      width: '100%',
      height: 144,
      maxWidth: 'calc(100% - var(--form-label-width))',
      position: 'relative',
      margin: '8px 0',
    },
    input: {
      width: '100%',
      height: '100%',
      border: 0,
      cursor: 'pointer',
      padding: '8px',
      fontFamily: 'var(--font-family)',
      fontSize: 'var(--font-size-p)',
      fontWeight: 600,
      lineHeight: 1.4,
      // Required in case the field is extended by the user
      backgroundColor: 'var(--color-background-content)',
      zIndex: 2,
      resize: 'none',
    },
    disabled: {},
  },
  label: {
    labelTextWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    labelTextError: {
      color: 'var(--ion-color-danger)',
    },
    labelText: {
      color: 'var(--ion-color-dark)',
      fontWeight: 300,
      marginTop: 8,
      marginBottom: 4,
    },

    errorIcon: {
      position: 'absolute',
      left: -20,
      color: 'var(--ion-color-danger)',
      '@media-mobile': {
        left: -24,
      },
    },
    errorLabel: {
      color: 'var(--ion-color-danger)',
      fontSize: 11,
      marginBottom: 8,
      '@media-mobile': {
        marginTop: 8,
      },
    },
    sublabelText: {
      color: 'var(--ion-color-medium)',
      fontSize: 11,
      marginTop: 4,
      marginBottom: 4,
      '@media-mobile': {
        marginTop: 0,
        marginBottom: 8,
      },
    },
    wrapper: {
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      paddingLeft: 4,
      paddingRight: 8,
      paddingBottom: 4,
      '@media-mobile': { width: '100%', marginRight: 0 },
    },
  },
  singleCheckbox: {
    wrapper: {
      marginTop: 2,
      marginRight: 8,
    },
  },
}
