import { LoanDataItem, Loan, LoanApplicationState } from '../../types/Loan.d'
import { mapLoanLink } from '../utils/mapLoanLink'
import { mapLoanNotification } from '../utils/mapLoanNotification'

export interface LoanListDataResult {
  openApplicationList: Array<LoanDataItem>
  activeLoanList: Array<LoanDataItem>
}

// const openApplicationFilter = (loan: Loan) =>
//   [
//     LoanApplicationState.AwaitingContractDocumentsReview,
//     LoanApplicationState.ContractReady,
//     LoanApplicationState.UploadApplicationDocuments,
//     LoanApplicationState.UploadSignedContract,
//   ].includes(loan.status.customer.applicationState)

// const activeLoanFilter = (loan: Loan) =>
//   [LoanApplicationState.LoanActive, LoanApplicationState.LoanPaymentOverdue].includes(
//     loan.status.customer.applicationState,
//   )

export const LoanListData = (params: { loanList: Array<Loan> }): LoanListDataResult => {
  const data = {
    openApplicationList: [],
    activeLoanList: params.loanList.map((loan) => ({
      ...loan,
      link: mapLoanLink[loan.status.customer.applicationState as LoanApplicationState](loan),
      notification: mapLoanNotification(loan),
      notificationCount: 1,
    })),
  }

  return data
}
