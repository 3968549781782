import { useTranslation } from 'react-i18next'

import { Props as ModalProps } from './Modal'
import { ModalWithContent } from './ModalWithContent'
import { DocumentsDownloadPage } from 'pages/loanDetail/documentsDownload/DocumentsDownload.page'
import { Document, DocumentTypeEnum } from 'data/openapi/backend'
import { Loan } from 'types/Loan.d'

type Props = {
  modal: Omit<ModalProps, 'children'> & {
    loan: Loan
    documentsList: Document[]
    title: string
    groups?: Array<DocumentTypeEnum>
  }
}

export const DocumentsDownloadModal = (props: Props) => {
  const { t } = useTranslation()

  return (
    <ModalWithContent
      title={t(props.modal.title)}
      modal={{
        ...props.modal,
      }}
      buttonProps={{ label: '', style: { common: { display: 'none' } } }}
    >
      <DocumentsDownloadPage
        documentsList={props.modal.documentsList}
        loan={props.modal.loan}
        onClose={props.modal.onClose}
        groups={props.modal.groups}
      />
    </ModalWithContent>
  )
}
