import { useState } from 'react'

import { LoanDetailProps } from 'data/hocs/withLoanDetail'
import { CustomerRelationship } from 'types/CustomerRelationship'
import {
  DocumentNonSwissNationalityOptions,
  DocumentSwissNationalityOptions,
  DocumentType,
  Loan,
} from '../../types/Loan.d'

export type LoanOnboardingDataResult = {
  user: CustomerRelationship | null
  loan: Loan | null
  idDocuments: {
    documentsOptions: Array<DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions>
    documentOptionSelected: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null
    setDocumentOptionSelected: (
      v: DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null,
    ) => void
    documents: Array<DocumentType>
  }
  requiredDocuments: Array<DocumentType>
}

/* const checkNationality = (nationality: string | undefined) => {
  if (!!nationality && nationality === 'Swiss') return true
  return false
} */

const getDocumentOptions = (isSwiss: boolean) => {
  if (isSwiss) {
    return [
      DocumentSwissNationalityOptions.SwissID,
      DocumentSwissNationalityOptions.SwissPassport,
      DocumentSwissNationalityOptions.SwissDrivingLicense,
    ]
  }
  return [
    DocumentNonSwissNationalityOptions.Passport,
    DocumentNonSwissNationalityOptions.IdentityCard,
    DocumentNonSwissNationalityOptions.IdentityPaper,
    DocumentNonSwissNationalityOptions.ResidencePermit,
  ]
}

const getDocuments = (documentOptionSelected: string | null) => {
  switch (documentOptionSelected) {
    case DocumentSwissNationalityOptions.SwissID:
      return [DocumentType.SwissIDFront, DocumentType.SwissIDBack]
    case DocumentSwissNationalityOptions.SwissPassport:
      return [DocumentType.SwissPassport]
    case DocumentSwissNationalityOptions.SwissDrivingLicense:
      return [DocumentType.SwissDriverLicense]
    case DocumentNonSwissNationalityOptions.Passport:
      return [DocumentType.ForeignPassport]
    case DocumentNonSwissNationalityOptions.IdentityCard:
      return [DocumentType.ForeignerIdentityCardBCFront, DocumentType.ForeignerIdentityCardBCBack]
    case DocumentNonSwissNationalityOptions.IdentityPaper:
      return [DocumentType.ForeignerIdentityPaper]
    case DocumentNonSwissNationalityOptions.ResidencePermit:
      return [DocumentType.ResidencePermit]

    default:
      return []
  }
}

export const LoanOnboardingData = (params: LoanDetailProps): LoanOnboardingDataResult => {
  const [documentOptionSelected, setDocumentOptionSelected] = useState<
    DocumentSwissNationalityOptions | DocumentNonSwissNationalityOptions | null
  >(null)
  const isSwiss = true // TODO: !!checkNationality(params.user?.nationality)

  const onboardingData = {
    user: params.user,
    loan: params.loan,
    idDocuments: {
      documentsOptions: getDocumentOptions(isSwiss),
      documentOptionSelected,
      setDocumentOptionSelected,
      documents: getDocuments(documentOptionSelected),
    },
    requiredDocuments: [
      DocumentType.PaySlipLastMonth,
      DocumentType.PaySlipMonthBeforeLast,
      DocumentType.PaySlipMonthTwiceBeforeLast,
    ],
  }

  return onboardingData
}
