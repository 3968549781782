import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { P } from 'frr-web/lib/html'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { formatAmount, formatDate } from 'components/utils/format'
import { LoanStateEnum } from 'data/openapi/backend'
import { i18n } from 'scaffold/translation/initI18next'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { LoanDataItem } from 'types/Loan'

import { Card } from './Card'

const mapLoanStateToNextInstallmentTextColor: Record<LoanStateEnum, string> = {
  [LoanStateEnum.Active]: 'var(--primary-blue-5)',
  [LoanStateEnum.ActiveInArrears]: 'var(--error-red-1)',
  [LoanStateEnum.ActiveInClosure]: 'black',
  [LoanStateEnum.ActiveNew]: 'black',
  [LoanStateEnum.ActiveOverdue]: 'var(--secondary-yellow-2)',
  [LoanStateEnum.Closed]: 'black',
  [LoanStateEnum.Cooloff]: 'black',
  [LoanStateEnum.Hidden]: 'black',
}

const mapLoanStateToCurrentProgressBarColor: Record<LoanStateEnum, string> = {
  [LoanStateEnum.Active]: 'var(--primary-blue-4)',
  [LoanStateEnum.ActiveInArrears]: 'var(--error-red-1)',
  [LoanStateEnum.ActiveInClosure]: 'var(--primary-blue-4)',
  [LoanStateEnum.ActiveNew]: 'var(--primary-blue-5)',
  [LoanStateEnum.ActiveOverdue]: 'var(--primary-blue-4)',
  [LoanStateEnum.Closed]: 'var(--primary-blue-5)',
  [LoanStateEnum.Cooloff]: 'var(--primary-blue-5)',
  [LoanStateEnum.Hidden]: 'var(--primary-blue-5)',
}

const mapLoanStateToNextInstallmentText: Record<LoanStateEnum, string> = {
  [LoanStateEnum.Active]: 'loanCard.bottom.nextRate.label',
  [LoanStateEnum.ActiveInArrears]: 'loanCard.bottom.nextRateInArrears.label',
  [LoanStateEnum.ActiveInClosure]: '',
  [LoanStateEnum.ActiveNew]: 'loanCard.bottom.firstRate.label',
  [LoanStateEnum.ActiveOverdue]: 'loanCard.bottom.nextRateOverdue.label',
  [LoanStateEnum.Closed]: 'var(--primary-blue-5)',
  [LoanStateEnum.Cooloff]: 'var(--primary-blue-5)',
  [LoanStateEnum.Hidden]: 'var(--primary-blue-5)',
}

type Props = {
  loan: LoanDataItem
}

export const LoanCard = (props: Props) => {
  const { loan } = props
  const { t } = useTranslation()

  const history = useHistory()

  return (
    <StyledLoanCard data-test-id="loanCard">
      <Header
        onClick={() => {
          history.push(`/loan/${props.loan.id}/detail/overview`)
        }}
      >
        <HeaderTop>
          <Logo
            src={props.loan.product?.productLogo}
            style={{
              filter: loan.state === LoanStateEnum.Closed ? 'grayscale(100%) opacity(75%)' : '',
              maxWidth: '120px',
            }}
          />
          <DetailsButton>
            <small>{`${t('loanCard.details.label')}`}</small>
            <ArrowImg src="/assets/chevron.svg" alt="arrow" />
          </DetailsButton>
        </HeaderTop>

        {props.loan.product?.productId && (
          <h4>{`${t(`product_${props.loan.product.productId.replace(' ', '')}`)}`}</h4>
        )}
        <LoanStats>
          <LoanStat>
            {`${t('loanCard.stats.contractId.label', { contractId: props.loan.contractId })}`}
          </LoanStat>
          <LoanStat style={{ marginLeft: 16 }}>
            {`${t('loanCard.stats.principalAmount.label', {
              amount: formatAmount(i18n)({ value: props.loan.principalAmount || 0 }),
            })}`}
          </LoanStat>
        </LoanStats>
      </Header>
      {
        // TODO: rollback changes when Story 7274 enters in phase 2 -> https://dev.azure.com/bob-finance/Bob%20Credit%20Relaunch/_workitems/edit/7274
        loan.state === LoanStateEnum.Cooloff && (
          <Expanded>
            <DocumentsTextWrapper>
              <TextField>
                <P
                  label={t('loanCard.documents.offers.label.head', {
                    cooloffDate: props.loan.coolOffEndsAt
                      ? formatDate(i18n)(props.loan.coolOffEndsAt)
                      : '',
                  })}
                />
              </TextField>
              <DocumentsButtonWrapper>
                <Button
                  label={'documents.download.modal.title'}
                  onClick={() => {
                    history.push(`/loan/${props.loan.id}/detail/overview/documents`)
                  }}
                  type={ButtonType.Secondary}
                  override={{ maxWidth: '100%' }}
                  style={{ label: { lineHeight: 'normal' } }}
                />
              </DocumentsButtonWrapper>
              <TextField>
                <P label={t('loanCard.documents.offers.label.foot')} />
              </TextField>
            </DocumentsTextWrapper>
          </Expanded>
        )
      }
      {
        // TODO: rollback changes when Story 7274 enters in phase 2 -> https://dev.azure.com/bob-finance/Bob%20Credit%20Relaunch/_workitems/edit/7274
        /* {loan.state === LoanStateEnum.Cooloff ? (
        <Expanded>
          <DocumentsTextWrapper>
            <TextField>
              <P
                label={t('loanCard.documents.offers.label.head', {
                  cooloffDate: props.loan.coolOffEndsAt
                    ? formatDate(i18n)(props.loan.coolOffEndsAt)
                    : '',
                })}
              />
            </TextField>
            <DocumentsButtonWrapper>
              <Button
                label={'documents.download.modal.title'}
                onClick={() => {
                  history.push(`/loan/${props.loan.id}/detail/overview/documents`)
                }}
                type={ButtonType.Secondary}
                override={{ maxWidth: '100%' }}
                style={{ label: { lineHeight: 'normal' } }}
              />
            </DocumentsButtonWrapper>
            <TextField>
              <P label={t('loanCard.documents.offers.label.foot')} />
            </TextField>
          </DocumentsTextWrapper>
        </Expanded>
      ) : loan.state && loanActiveStates.includes(loan.state) ? (
        <Expanded>
          <ProgressBarHeader>
            <InstallmentText>
              <ColoredSpan>
                <strong>{props.loan.paidInstallmentCount || 0}</strong>
              </ColoredSpan>
              <span className="desktop">
                {t('loanCard.bottom.totalInstallmentCount.label', {
                  installmentTotal: props.loan.totalInstallmentCount,
                })}
              </span>
              <span className="mobile">
                {t('loanCard.bottom.totalInstallmentCount.labelMobile', {
                  installmentTotal: props.loan.totalInstallmentCount,
                })}
              </span>
            </InstallmentText>
            {loan.state !== LoanStateEnum.ActiveInClosure &&
              loan.state !== LoanStateEnum.ActiveOverdue &&
              props.loan.overdueAmount && (
                <NextInstallmentText
                  style={{ color: mapLoanStateToNextInstallmentTextColor[loan.state as LoanStateEnum] }}
                >
                  <span>{t(mapLoanStateToNextInstallmentText[loan.state as LoanStateEnum])}</span>
                  <NextRateAmount>
                    {t('loanCard.bottom.nextRateAmount.label', {
                      amount: formatAmount(i18n)({ value: props.loan.overdueAmount }),
                    })}
                  </NextRateAmount>
                </NextInstallmentText>
              )}
          </ProgressBarHeader>
          {!!props.loan.totalInstallmentCount && (
            <ProgressBarWrapper>
              {!!props.loan.paidInstallmentCount && (
                <CompletedInstallmentsBar
                  style={{
                    width: `${
                      props.loan.state === LoanStateEnum.ActiveInArrears
                        ? (props.loan.paidInstallmentCount / props.loan.totalInstallmentCount) * 100
                        : ((props.loan.paidInstallmentCount - 1) / props.loan.totalInstallmentCount) *
                          100
                    }%`,
                  }}
                />
              )}

              <CurrentInstallmentBar
                style={{
                  width:
                    props.loan.paidInstallmentCount === 0
                      ? 3
                      : `${(1 / props.loan.totalInstallmentCount) * 100}%`,
                  minWidth: props.loan.paidInstallmentCount === 0 ? undefined : 7,
                  backgroundColor: mapLoanStateToCurrentProgressBarColor[loan.state as LoanStateEnum],
                }}
              />
            </ProgressBarWrapper>
          )}

          {loan.state === LoanStateEnum.ActiveNew && props.loan.nextPaymentAt && (
            <ExplainerText>
              {t('loanCard.bottom.nextPaymentAt.label', {
                amount: props.loan.installmentAmount,
                date: formatDate(i18n)(props.loan.nextPaymentAt),
              })}
            </ExplainerText>
          )}
        </Expanded>
      ) : (
        <Expanded>
          <ExplainerText style={{ opacity: 0.7 }}>{t('loanCard.bottom.loanClosed.label')}</ExplainerText>
        </Expanded>
      )} */
      }
    </StyledLoanCard>
  )
}

const StyledLoanCard = styled(Card)`
  @media ${DesktopStyle} {
    padding: 0;
  }

  @media ${MobileStyle} {
    padding: 0;
  }
`

const LoanStats = styled.div`
  display: flex;
  align-items: center;
  margin-top: 4px;
`

const LoanStat = styled.p`
  font-size: 14px;
  color: #4b4b4b;
`

const ExplainerText = styled.p`
  font-size: 14px;
  color: #4b4b4b;
  margin-top: 14px;
`

const InstallmentText = styled.p`
  font-size: 13px;
  color: #4b4b4b;

  @media ${DesktopStyle} {
    .desktop {
      display: inline-block;
      margin-left: 4px;
    }
    .mobile {
      display: none;
    }
  }

  @media ${MobileStyle} {
    width: 90%;
    .desktop {
      display: none;
    }
    .mobile {
      display: inline-block;
      margin-left: 4px;
    }
  }
`

const NextInstallmentText = styled.p`
  font-size: 13px;
  font-weight: 600;

  @media ${MobileStyle} {
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: right;
  }
`

const CompletedInstallmentsBar = styled.div`
  height: 100%;
  background: var(--primary-blue-4);
`

const CurrentInstallmentBar = styled.div`
  height: 100%;
`

const ProgressBarWrapper = styled.div`
  width: 100%;
  background: #f5f5f5;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
`

const ProgressBarHeader = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
  align-items: center;

  @media ${MobileStyle} {
    margin-bottom: 8px;
  }
`

const Expanded = styled.div`
  padding: 16px 24px;
  border-top: 1px solid var(--neutral-gray-2);
`

const DetailsButton = styled.div`
  display: flex;
  align-items: center;
  transition: all ease 0.4s;
  cursor: pointer;

  img,
  small {
    transition: all ease 0.4s;
  }

  img {
    opacity: 0.7;
  }

  &:hover {
    img {
      opacity: 1;
    }
    small {
      color: black;
    }
  }
`

const Header = styled.div`
  padding: 16px 24px;
`

const HeaderTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`

const Logo = styled.img`
  height: 32px;
`

const ArrowImg = styled.img`
  width: 6px;
  margin-left: 8px;
`

const NextRateAmount = styled.span`
  @media ${DesktopStyle} {
    margin-left: 6px;
  }
`

const ColoredSpan = styled.span`
  color: var(--primary-blue-4);
`

const DocumentsTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

const DocumentsButtonWrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  float: left;
  width: 100%;
`

const TextField = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;

  > P {
    > b {
      text-size: 32px;
    }
  }
`
