import { useTranslation } from 'react-i18next'

import { CustomerRelationship } from 'types/CustomerRelationship'

import { Props as ModalProps } from './Modal'
import { ModalWithContent } from './ModalWithContent'
import { Security } from 'pages/profile/security/Security.page'

type Props = {
  modal: Omit<ModalProps, 'children'>
  user: CustomerRelationship
}

export const ChangePasswordModal = (props: Props) => {
  const { t } = useTranslation()

  return (
    <ModalWithContent
      title={t('changePassword.modal.title')}
      modal={{
        ...props.modal,
      }}
    >
      <Security user={props.user} onClose={props.modal.onClose} />
    </ModalWithContent>
  )
}
