import { SeverityLevel } from '@microsoft/applicationinsights-web'
import { ReactNode, useEffect } from 'react'
import styled from 'styled-components'
import { Browser, detect } from 'detect-browser'
import { useTranslation } from 'react-i18next'

import { TrackEventType } from 'types/Analytics.d'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { Languages } from 'scaffold/translation/Languages'

import { appInsightsTracker } from './AppInsightsProvider'

const browser = detect()
/* List of all browsers detected (as of 2021-06-10)
  | 'android'
  | 'aol'
  | 'bb10'
  | 'beaker'
  | 'chrome'
  | 'chromium-webview'
  | 'crios'
  | 'curl'
  | 'edge-chromium'
  | 'edge-ios'
  | 'edge'
  | 'facebook'
  | 'firefox'
  | 'fxios'
  | 'ie'
  | 'instagram'
  | 'ios-webview'
  | 'ios'
  | 'kakaotalk'
  | 'miui'
  | 'opera-mini'
  | 'opera'
  | 'phantomjs'
  | 'safari'
  | 'samsung'
  | 'searchbot';
  | 'silk'
  | 'yandexbrowser'
 */

const supportedBrowserList: Array<Partial<Browser>> = [
  'android',
  'chrome',
  'chromium-webview',
  'edge',
  'firefox',
  'ios',
  'opera',
  'safari',
  // Added on May, 3rd 2022
  'edge-chromium',
  'edge-ios',
  'facebook',
  'instagram',
  'samsung',
]

export const BrowserCompatibility = (props: { children: ReactNode }) => {
  const { i18n } = useTranslation()

  const isUnsupportedBrowser =
    (browser &&
      !supportedBrowserList.find((supportedBrowser) => browser.name.includes(supportedBrowser))) ||
    window.location.pathname.endsWith('/error/browser')

  useEffect(() => {
    browser &&
      isUnsupportedBrowser &&
      appInsightsTracker.trackException({
        type: TrackEventType.Exception,
        exception: new Error(`unsupported brower - ${browser.name}`),
        severity: SeverityLevel.Warning,
      })
  }, [isUnsupportedBrowser])

  const errorText = Languages.map((lang) => ({
    title: i18n.t('browserIncompatibility.title', { lng: lang, defaultValue: 'T.B.D.' }),
    subtitle: i18n.t('browserIncompatibility.subtitle', { lng: lang, defaultValue: 'T.B.D.' }),
  }))

  return isUnsupportedBrowser ? (
    <Wrapper>
      <Container>
        <WarningImage src={'/warning.svg'} alt="warning" />
        {errorText.map((p) => (
          <>
            <h3>{p.title}</h3>
            <p>{p.subtitle}</p>
          </>
        ))}
      </Container>
    </Wrapper>
  ) : (
    <>{props.children}</>
  )
}

const WarningImage = styled.img`
  width: 144px;
  height: 144px;
  margin-bottom: 32px;
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  background-color: #fafafa;

  display: flex;
  flex-direction: column;

  @media ${DesktopStyle} {
    align-items: center;
    justify-content: center;
  }

  @media ${MobileStyle} {
    overflow-y: auto;
  }
`

const Container = styled.div`
  width: 580px;

  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.03);
  padding: 48px 32px 64px;

  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${MobileStyle} {
    width: 100%;
    background-color: transparent;
  }

  img {
    margin-bottom: 32px;
  }

  & > h1 {
    font-family: Helvetica;
    font-size: 32px;
    font-weight: 700;
    margin: 40px 16px 8px;
    max-width: 480px;
    text-align: center;
  }

  & > h3 {
    font-family: Helvetica;
    font-size: 18px;
    margin: 8px 16px 0 16px;
    max-width: 480px;
    opacity: 1;
    text-align: center;
  }

  & > p {
    font-family: Helvetica;
    font-size: 14px;
    margin-bottom: 24px;
    margin: 8px 16px 0px;
    max-width: 480px;
    opacity: 0.6;
    text-align: center;
  }
`
