// import { OnboardingRoute } from 'pages/loanOnboarding/LoanOnboarding.page'
import { Loan, LoanNotificationAction } from 'types/Loan.d'

// const DetailLinkActionStateList = [LoanActionState.MakePayment]
/* const LoanActionRouteMap = {
  [LoanActionState.UploadApplicationDocuments]: (loanId: string) =>
    `/loan/${loanId}/onboarding/${OnboardingRoute.UploadDocuments}`,
  [LoanActionState.DownloadSignedContract]: (loanId: string) =>
    `/loan/${loanId}/onboarding/${OnboardingRoute.ContractOffer}`,
  [LoanActionState.UploadSignedContract]: (loanId: string) =>
    `/loan/${loanId}/onboarding/${OnboardingRoute.ContractUpload}`,
  [LoanActionState.NoActionRequired]: (loanId: string) => undefined,
  [LoanActionState.MakePayment]: (loanId: string) => `/loan/${loanId}`,
} */

export const mapLoanNotificationAction = (loan: Loan): LoanNotificationAction | null => {
  let action = null

  /* if (loan.status.currentState !== LoanActionState.NoActionRequired) {
    action = {
      localeLabel: `loanNotification.${loan.status.currentState}.actionLabel`,
      href: loan.status.currentState
        ? LoanActionRouteMap[loan.status.currentState as LoanActionState](`${loan.contractId}`)
        : undefined,
    }
  } */

  return action
}
