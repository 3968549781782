import { useIonToast } from '@ionic/react'
import { useTranslation } from 'react-i18next'

// Ionic provides the following types (https://ionicframework.com/docs/api/toast#color)
// "primary", "secondary", "tertiary", "success", "warning", "danger", "light", "medium" and "dark"

export enum ToastType {
  Success = 'success',
  Error = 'danger',
  Info = 'secondary',
  Warning = 'warning',
}
export const useToast = () => {
  const [present] = useIonToast()
  const { t } = useTranslation()

  return {
    showToast: (message: string, type = ToastType.Info) =>
      present({
        message: t(message),
        duration: 5000,
        color: type,
        position: 'top',
        id: `toast-${type}`,
        cssClass: `toast-${type}`,
      }),
  }
}
