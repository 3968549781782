import { IonPage } from '@ionic/react'
import { useState } from 'react'

import { validatePassword } from 'pages/utils/validate'
import { useResetPasswordAttemptContext } from 'scaffold/providers/authentication/AuthResetPasswordProvider'
import { SubmitPasswordData } from 'types/ResetPassword'

import { ResetPasswordData } from './ResetPassword.data'
import { ResetPasswordTemplate } from './ResetPassword.template'

interface ResetPasswordPageProps {
  onFinish: () => void
  onSetPassword: (values: SubmitPasswordData) => void
}

export const ResetPasswordPage = (props: ResetPasswordPageProps) => {
  const attempt = useResetPasswordAttemptContext()
  const registrationData = ResetPasswordData({ attempt })

  const [values, setValues] = useState(registrationData.data)

  const passwordValidated = validatePassword(values.newPassword) === null

  const [disableDirtyValidation, setDisableDirtyValidation] = useState<boolean>(true)

  return (
    <IonPage id="reset-password">
      <ResetPasswordTemplate
        data={values}
        error={registrationData.error}
        formFields={registrationData.formFields}
        isError={registrationData.isError}
        isSuccess={registrationData.isSuccess}
        buttonDisabled={
          registrationData.isBlocked ||
          values.newPassword === '' ||
          values.newPasswordRetyped === '' ||
          values.newPassword !== values.newPasswordRetyped ||
          !passwordValidated
        }
        onChange={setValues}
        onFinish={props.onFinish}
        onSetPassword={props.onSetPassword}
        successFormFields={registrationData.successFormFields}
        disableDirtyValidation={disableDirtyValidation}
        setDisableDirtyValidation={setDisableDirtyValidation}
      />
    </IonPage>
  )
}
