export enum AuthenticationAttemptState {
  Error = 'error', // An error occurred while checking authentication attempt (e.g. missing scope params)
  Expired = 'expired', // The email token is expired and no more valid
  MTanCallenge = 'mtan-challenge',
  MTanChallengeFailLimitReached = 'failed-mtan-challenge-max-fails',
  None = 'none', // Initial state before any calls to customer IDP services
  PasswordChallenge = 'password-challenge',
  PasswordChallengeFailLimitReached = 'failed-password-challenge-max-fails',
  Succeeded = 'succeeded', // Successful log in or registration
}

export type AuthenticationAttempt = {
  email?: string
  id?: string
  maxMTanFailCount: number
  maxMTanSendCount: number
  maxPasswordFailCount: number
  mTanFailCount: number
  mTanSendCount: number
  passwordFailCount: number
  phone?: string
  returnUrl?: string
  state:
    | AuthenticationAttemptState.MTanCallenge
    | AuthenticationAttemptState.MTanChallengeFailLimitReached
    | AuthenticationAttemptState.PasswordChallenge
    | AuthenticationAttemptState.PasswordChallengeFailLimitReached
  version?: number
}
