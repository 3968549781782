import { ButtonType } from 'frr-web/lib/components/Button'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'

export const AuthInfoTemplate = (props: {
  title: string
  text?: string
  errorText?: string
  infoText?: string
  onFinishLabel: string
  onFinish: () => void
  onFinishButtonType?: ButtonType
}) => {
  return (
    <LayoutPageLandingContent title={props.title} description={props.text}>
      <AuthenticationForm
        buttons={[
          {
            label: props.onFinishLabel,
            onClick: ({ submit }) => submit(),
            type: props.onFinishButtonType || ButtonType.Primary,
          },
        ]}
        data={{}}
        error={props.errorText}
        formFields={[]}
        info={props.infoText}
        onSubmit={props.onFinish}
      />
    </LayoutPageLandingContent>
  )
}
