/* tslint:disable */
/* eslint-disable */
/**
 * WebApp
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DomainErrorCode {
    CustomerEmailNotConfirmed = 'customerEmailNotConfirmed',
    CustomerPhoneNotConfirmed = 'customerPhoneNotConfirmed',
    CustomerPasswordAlreadySet = 'customerPasswordAlreadySet',
    CustomerPasswordNotSet = 'customerPasswordNotSet',
    CustomerNotActive = 'customerNotActive',
    CustomerLockedOut = 'customerLockedOut',
    CustomerInitializePasswordFailed = 'customerInitializePasswordFailed',
    CustomerChangePasswordFailed = 'customerChangePasswordFailed',
    CustomerOperationNotSupportedInCurrentState = 'customerOperationNotSupportedInCurrentState',
    AuthorizationAttemptOperationNotSupportedInCurrentState = 'authorizationAttemptOperationNotSupportedInCurrentState',
    AuthorizationAttemptPasswordMismatch = 'authorizationAttemptPasswordMismatch',
    AuthorizationAttemptMTanMaxSends = 'authorizationAttemptMTanMaxSends',
    AuthorizationAttemptMTanExpired = 'authorizationAttemptMTanExpired',
    AuthorizationAttemptMTanMismatch = 'authorizationAttemptMTanMismatch',
    PasswordResetAttemptOperationNotSupportedInCurrentState = 'passwordResetAttemptOperationNotSupportedInCurrentState'
}

export function DomainErrorCodeFromJSON(json: any): DomainErrorCode {
    return DomainErrorCodeFromJSONTyped(json, false);
}

export function DomainErrorCodeFromJSONTyped(json: any, ignoreDiscriminator: boolean): DomainErrorCode {
    return json as DomainErrorCode;
}

export function DomainErrorCodeToJSON(value?: DomainErrorCode | null): any {
    return value as any;
}

