/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AccountStatement,
    AccountStatementFromJSON,
    AccountStatementToJSON,
    Loan,
    LoanFromJSON,
    LoanToJSON,
    ProblemDetails,
    ProblemDetailsFromJSON,
    ProblemDetailsToJSON,
} from '../models';

export interface LoanGetAccountStatementRequest {
    loanId: string | null;
}

export interface LoanGetLoanRequest {
    loanId: string | null;
}

/**
 * 
 */
export class LoanApi extends runtime.BaseAPI {

    /**
     * Get the Account Statement (Transactions) of a loan.
     */
    async loanGetAccountStatementRaw(requestParameters: LoanGetAccountStatementRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<AccountStatement>> {
        if (requestParameters.loanId === null || requestParameters.loanId === undefined) {
            throw new runtime.RequiredError('loanId','Required parameter requestParameters.loanId was null or undefined when calling loanGetAccountStatement.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/loans/{loanId}/accountStatement`.replace(`{${"loanId"}}`, encodeURIComponent(String(requestParameters.loanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => AccountStatementFromJSON(jsonValue));
    }

    /**
     * Get the Account Statement (Transactions) of a loan.
     */
    async loanGetAccountStatement(requestParameters: LoanGetAccountStatementRequest, initOverrides?: RequestInit): Promise<AccountStatement> {
        const response = await this.loanGetAccountStatementRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a bob loan.
     */
    async loanGetLoanRaw(requestParameters: LoanGetLoanRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Loan>> {
        if (requestParameters.loanId === null || requestParameters.loanId === undefined) {
            throw new runtime.RequiredError('loanId','Required parameter requestParameters.loanId was null or undefined when calling loanGetLoan.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/loans/{loanId}`.replace(`{${"loanId"}}`, encodeURIComponent(String(requestParameters.loanId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoanFromJSON(jsonValue));
    }

    /**
     * Get a bob loan.
     */
    async loanGetLoan(requestParameters: LoanGetLoanRequest, initOverrides?: RequestInit): Promise<Loan> {
        const response = await this.loanGetLoanRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the user\'s bob loans.
     */
    async loanGetLoansRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Loan>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        const response = await this.request({
            path: `/loans`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(LoanFromJSON));
    }

    /**
     * Get the user\'s bob loans.
     */
    async loanGetLoans(initOverrides?: RequestInit): Promise<Array<Loan>> {
        const response = await this.loanGetLoansRaw(initOverrides);
        return await response.value();
    }

}
