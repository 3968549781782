import { ChangePassword } from 'types/Authentication'
import { NewPassword } from 'types/ResetPassword'

const EMAIL_REGEX =
  // eslint-disable-next-line no-control-regex
  /^(?:[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-zA-Z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-zA-Z0-9](?:[a-z0-9-]*[a-zA-Z0-9])?\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-zA-Z0-9-]*[a-zA-Z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

const PASSWORD_REGEXP = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}/
const PASSWORD_EXTENDED_REGEXP_ABC = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{12,}/
const PASSWORD_EXTENDED_REGEXP_ABD = /(?=.*\d)(?=.*[a-z])(?=.*[^a-zA-Z0-9]).{12,}/
const PASSWORD_EXTENDED_REGEXP_ACD = /(?=.*\d)(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{12,}/
const PASSWORD_EXTENDED_REGEXP_BCD = /(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{12,}/

const SWISSZIP_REGEXP = /^\d{4}$/

const STREET_NUMBER_REGEXP = /^[a-zA-Z0-9 ]*$/

export const NAME_REGEXP = /^[A-Za-zÀ-ž ]+$/

export const validateEmail = (value: any) =>
  !!value && !EMAIL_REGEX.test(`${value}`) ? 'formFields.error.invalidEmail' : null

export type PasswordValidation = {
  isValid: boolean
  isValidRetype: boolean
  message: string | null
}

export const validateChangePassword = ({
  currentPassword,
  newPassword,
  newPasswordRetyped,
}: ChangePassword): string | null => {
  if (newPassword !== currentPassword && newPassword === newPasswordRetyped) {
    return validatePassword(newPassword)
  } else if (newPassword === currentPassword) {
    return 'formFields.error.passwordRepeated'
  } else if (newPassword !== newPasswordRetyped) {
    return 'formFields.error.passwordMismatch'
  } else return null
}

export const validateNewPassword = ({
  newPassword,
  newPasswordRetyped,
}: NewPassword): PasswordValidation => {
  if (newPassword === newPasswordRetyped) {
    return {
      isValid: !validatePassword(newPassword),
      isValidRetype: true,
      message: validatePassword(newPassword),
    }
  } else {
    return { isValid: false, isValidRetype: false, message: 'formFields.error.passwordMismatch' }
  }
}

export const validatePassword = (value: string | null) =>
  !!value && value.length < 12
    ? !PASSWORD_REGEXP.test(`${value}`)
      ? 'formFields.error.passwordNotValid'
      : null
    : !PASSWORD_EXTENDED_REGEXP_ABC.test(`${value}`) ||
      !PASSWORD_EXTENDED_REGEXP_ABD.test(`${value}`) ||
      !PASSWORD_EXTENDED_REGEXP_ACD.test(`${value}`) ||
      !PASSWORD_EXTENDED_REGEXP_BCD.test(`${value}`) // password longer than 12 chars
    ? 'formFields.error.passwordNotValid'
    : null

export const validateSwissZip = (value: any) =>
  !SWISSZIP_REGEXP.test(`${value}`) ? 'formFields.error.invalidZip' : null

export const validateStreetNumber = (value: string) =>
  !STREET_NUMBER_REGEXP.test(`${value}`) ? 'formFields.error.invalidStreetNumber' : null

export const validateCityName = (value: string) =>
  !NAME_REGEXP.test(`${value}`) ? 'formFields.error.invalidText' : null
