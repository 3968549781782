import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { Card } from 'components/card/Card'
import { formatAmount, formatDate, formatPercentage } from 'components/utils/format'
import { LoanDataRow, ValueType } from '../types/Loan.d'

import { StyledSkeletonText } from './SkeletonText'

export type Props = {
  list: Array<LoanDataRow>
  className?: string
  children?: ReactNode
  reverse?: boolean
}

export const CardDataList = (props: Props) => {
  const { t, i18n } = useTranslation()

  const getItemValueByType = (item: LoanDataRow) => {
    if (item.type !== ValueType.Separator && item.value !== null && item.value !== undefined) {
      switch (item.type) {
        case ValueType.Amount:
          return formatAmount(i18n)({ value: Number(item.value) })
        case ValueType.Duration:
          return t('duration', item)
        case ValueType.Date:
          return formatDate(i18n)(item.value as string)
        case ValueType.DateRange:
          return item.value.map(formatDate(i18n)).join(' - ')
        case ValueType.Percentage:
          return formatPercentage(item.value)
        case ValueType.Image:
          return <Image src={item.value} />
        case ValueType.Text:
          return t(item.value)
        default:
          return item.value
      }
    } else return <StyledSkeletonText animated type={item.type} />
  }

  return (
    <Card className={props.className} data-test-id="cardDataList" reverse={props.reverse}>
      <div>
        {props.list.map((item, key) => {
          return item.type === ValueType.Separator ? (
            <Separator key={key} />
          ) : (
            <DataItem key={key} style={{ borderBottom: 0 }} data-test-id={item.dataTestId}>
              <p style={{ ...item.style, ...item.labelStyle }}>{t(item.label)}</p>
              <p style={{ ...item.style }}>{getItemValueByType(item)}</p>
            </DataItem>
          )
        })}
      </div>
      {props.children}
    </Card>
  )
}

const Image = styled.img`
  max-width: 120px;
  max-height: 24px;
`

const DataItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
  padding: 0 16px;

  p:first-child {
    color: var(--neutral-gray-4);
  }

  p:last-child {
    min-width: 120px;
    text-align: right;
  }

  p {
    overflow: hidden;
  }
`

const Separator = styled.div`
  background-color: var(--neutral-gray-2);
  height: 1px;
  margin: 16px 0;
`
