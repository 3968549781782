/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * 
 * @export
 * @enum {string}
 */
export enum DocumentTypeEnum {
    None = 'None',
    Archive = 'Archive',
    Other = 'Other',
    Fax = 'Fax',
    Email = 'Email',
    ContractEmail = 'ContractEmail',
    ReminderEmail = 'ReminderEmail',
    SummaryEmail = 'SummaryEmail',
    ConfirmationEmail = 'ConfirmationEmail',
    FinancingContract = 'FinancingContract',
    Invoice = 'Invoice',
    InvoiceFromPartner = 'InvoiceFromPartner',
    InvoiceFromValora = 'InvoiceFromValora',
    InvoiceFromTechdata = 'InvoiceFromTechdata',
    DeliveryNoteFromPartner = 'DeliveryNoteFromPartner',
    DeliveryNoteFromTechdata = 'DeliveryNoteFromTechdata',
    OrderStatusFromTechdata = 'OrderStatusFromTechdata',
    Reminder1 = 'Reminder1',
    Reminder2 = 'Reminder2',
    Reminder3 = 'Reminder3',
    PaymentSlip = 'PaymentSlip',
    TechnicalDocument = 'TechnicalDocument',
    Leaflet = 'Leaflet',
    FeeSchedule = 'FeeSchedule',
    FormularA = 'FormularA',
    WelcomeLetter = 'WelcomeLetter',
    TermsAndConditions = 'TermsAndConditions',
    TermsAndConditionsInsurance = 'TermsAndConditionsInsurance',
    BudgetPlan = 'BudgetPlan',
    IdentityDocument = 'IdentityDocument',
    IdentityDocumentBackside = 'IdentityDocumentBackside',
    IdentityDocumentPartner = 'IdentityDocumentPartner',
    IdentityDocumentPartnerBackside = 'IdentityDocumentPartnerBackside',
    Selfie = 'Selfie',
    Passport = 'Passport',
    DriverLicense = 'DriverLicense',
    ResidencePermit = 'ResidencePermit',
    ResidencePermitBackside = 'ResidencePermitBackside',
    RegistrationCertificate = 'RegistrationCertificate',
    SalarySlip = 'SalarySlip',
    AdditionalIncome = 'AdditionalIncome',
    SalaryBankReceipt = 'SalaryBankReceipt',
    BankcardDisbursement = 'BankcardDisbursement',
    LoanRedemptionPaymentSlip = 'LoanRedemptionPaymentSlip',
    FinalCreditStatement = 'FinalCreditStatement',
    FinalLeasingStatement = 'FinalLeasingStatement',
    AccountStatement = 'AccountStatement',
    TaxStatement = 'TaxStatement',
    ResidentialRentalAgreement = 'ResidentialRentalAgreement',
    MortgageLoan = 'MortgageLoan',
    HealthInsurancePolicy = 'HealthInsurancePolicy',
    HealthInsurancePremiumReduction = 'HealthInsurancePremiumReduction',
    ProofOfAlimony = 'ProofOfAlimony',
    DivorceDecree = 'DivorceDecree'
}

export function DocumentTypeEnumFromJSON(json: any): DocumentTypeEnum {
    return DocumentTypeEnumFromJSONTyped(json, false);
}

export function DocumentTypeEnumFromJSONTyped(json: any, ignoreDiscriminator: boolean): DocumentTypeEnum {
    return json as DocumentTypeEnum;
}

export function DocumentTypeEnumToJSON(value?: DocumentTypeEnum | null): any {
    return value as any;
}

