import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { useTranslation } from 'react-i18next'

import { DataPreview } from 'components/DataPreview'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { Loan } from '../../../types/Loan'

export type Props = {
  loan: Loan
}

export const OnboardingWelcomeTemplate = (props: Props) => {
  const { t } = useTranslation()
  return (
    <LayoutPageLandingContent title={t('welcomePage.title')} description={t('welcomePage.description')}>
      <>
        <p style={{ marginTop: 32 }}> {t('welcomePage.subtitle')}</p>
        <DataPreview
          style={{ marginTop: 16 }}
          items={[
            {
              label: t('welcomePage.data.loanNumber.label'),
              value: 'ABC123',
            },
            {
              label: t('welcomePage.data.loanAmount.label'),
              value: 'CHF 30’000',
            },
            {
              label: t('welcomePage.data.loanLength.label'),
              value: '36 Monate',
            },
            {
              label: t('welcomePage.data.loanInsurance.label'),
              value: 'Ja',
            },
            {
              label: t('welcomePage.data.interestRate.label'),
              value: '4.9%',
            },
            {
              label: t('welcomePage.data.loanRate.label'),
              value: 'CHF 6’000',
            },
          ]}
        />
        <Button
          label={t('welcomePage.button.documentUploadLater.label')}
          type={ButtonType.Chromeless}
          onClick={() => {}}
          override={{ marginTop: 'auto', width: '100%' }}
        />
        <Button
          label={t('welcomePage.button.continue.label')}
          type={ButtonType.Primary}
          onClick={() => {}}
          override={{ marginTop: 16, width: '100%' }}
        />
      </>
    </LayoutPageLandingContent>
  )
}
