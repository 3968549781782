import { IonPage } from '@ionic/react'
import { api, Mutation } from 'data/api'
import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { useEffect, useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory } from 'react-router'
import { useAuthAttemptContext } from 'scaffold/providers/authentication/AuthDelegator'
import { useAuthConfigContext } from 'scaffold/providers/authentication/AuthProvider'
import {
  AuthenticationAttempt,
  AuthenticationAttemptChallenge,
  CredentialsData,
} from 'types/Authentication'
import { AuthLoginData } from './AuthLogin.data'
import { AuthLoginTemplate } from './AuthLogin.template'

interface LoginPageProps {
  onAuthStepSuccess: (attempt: AuthenticationAttempt) => void
  onAuthError: (error: string) => void
}

export const AuthLoginPage = (props: LoginPageProps) => {
  const { i18n } = useTranslation()

  // Login start page redirect on start-up
  const history = useHistory()
  const { showLoginStartPage } = useAuthConfigContext()

  useLayoutEffect(() => {
    if (showLoginStartPage) {
      history.replace('/info/login')
    }
  }, [])

  // Login attempt handling
  const attempt = useAuthAttemptContext() as AuthenticationAttemptChallenge

  const { mutateAsync: submitCredentials, isLoading } = useMutation(
    api[Mutation.LoginSubmitCredentials],
    {
      onSuccess: props.onAuthStepSuccess,
      onError: props.onAuthError,
    },
  )

  const onLogin = (values: CredentialsData) => {
    submitCredentials({ ...values, language: i18n.language })
    setLoginError(null)
  }

  const [disableDirtyValidation, setDisableDirtyValidation] = useState<boolean>(true)
  const [loginError, setLoginError] = useState<string | null>(null)
  const loginData = AuthLoginData({
    attempt,
    onResetError: () => setLoginError(null),
  })

  useEffect(() => {
    if (
      !isLoading &&
      (attempt.currentPasswordFailCount >= attempt.maxPasswordFailCount ||
        attempt.state === AuthenticationAttemptState.PasswordChallengeFailLimitReached)
    ) {
      setLoginError('authentication.errors.maxSubmitPasswordCount')
    } else if (!isLoading && attempt.currentPasswordFailCount > 0) {
      setLoginError('authentication.errors.invalidCredentials')
    }
  }, [isLoading, attempt.currentPasswordFailCount, attempt.maxPasswordFailCount])

  return (
    <IonPage id="login">
      <AuthLoginTemplate
        data={loginData.data}
        error={loginError}
        formFields={loginData.formFields}
        isBlocked={loginData.isBlocked}
        isLoading={isLoading}
        onChange={() => loginError && !loginData.isBlocked && setLoginError(null)}
        onLogin={onLogin}
        disableDirtyValidation={disableDirtyValidation}
        setDisableDirtyValidation={setDisableDirtyValidation}
      />
    </IonPage>
  )
}
