import { ElementType } from 'react'
import { useQuery } from 'react-query'

import { api, Query } from 'data/api'
import { Loan as OpenapiLoanType } from 'data/openapi/backend/models/Loan'
import { Loan, LoanApplicationState, LoanProcessingState } from 'types/Loan.d'

import { MapMyCockpitApiResponse, MyCockpitApiRetry } from './utils/MapMyCockpitApiResponse'

export interface LoanListProps {
  loanList: Array<Loan>
  isLoading: boolean
}

/* const mapActionState = {
  [LoanApplicationState.LoanActive]: LoanActionState.NoActionRequired,
  [LoanApplicationState.LoanFinished]: LoanActionState.NoActionRequired,
  [LoanApplicationState.LoanPaymentOverdue]: LoanActionState.NoActionRequired,
  [LoanApplicationState.ContractReady]: LoanActionState.DownloadSignedContract,
  [LoanApplicationState.UploadApplicationDocuments]: LoanActionState.UploadApplicationDocuments,
  [LoanApplicationState.UploadSignedContract]: LoanActionState.UploadSignedContract,
  [LoanApplicationState.AwaitingContractDocumentsReview]: LoanActionState.NoActionRequired,
  [LoanApplicationState.ContactDocumentsApproved]: LoanActionState.NoActionRequired,
} */

const mapLoanData = (loan: OpenapiLoanType): Loan => ({
  ...loan,
  status: {
    currentState: `${LoanApplicationState.LoanActive}`,
    customer: {
      processingState: LoanProcessingState.Success,
      applicationState: LoanApplicationState.LoanActive,
      errors: [],
    },
  },
})

export const withLoanList = <T extends {}>(Component: ElementType) => {
  return (props: T) => {
    const response = useQuery<Array<OpenapiLoanType>, Response>(
      Query.FetchLoanList,
      api[Query.FetchLoanList],
      {
        enabled: true,
        retry: MyCockpitApiRetry,
      },
    )

    const { isLoading, data } = MapMyCockpitApiResponse<Array<OpenapiLoanType>>(response)

    const loanList = (data || []).map(mapLoanData)

    return <Component loanList={loanList} isLoading={isLoading} {...props} />
  }
}
