import { FormThemeConfig } from 'frr-web/lib/theme/theme.form'

export const formTheme: Partial<FormThemeConfig> = {
  form: {
    wrapper: {
      flex: 1,
      width: '100%',
    },
    content: {
      width: '100%',
    },
    buttonContainer: {
      marginTop: 0,
      paddingTop: 8,
      backgroundColor: 'var(--card-background-color)',
    },
  },
  row: {
    wrapper: {
      alignItems: 'center',
      display: 'flex',
      marginBottom: 16,
      minHeight: 72,
      paddingBottom: '8px',
      transition: 'min-height 0.2s ease-out',

      ':readonly': {
        minHeight: 28,
      },
      '@media-mobile': {
        minHeight: 0,
      },
    },
    item: {
      height: '100%',
      transition: 'min-height 0.2s ease-out',
      paddingBottom: 16,

      ':readonly': {
        width: '100%',
      },
      '@media-mobile': {
        width: '100%',
        marginTop: 12,
        marginLeft: 0,
        marginRight: 0,
      },
    },
  },
  section: {
    wrapper: {
      position: 'relative',
      marginBottom: 16,
      ':readonly': {
        paddingBottom: 4,
        marginBottom: 0,
      },
    },
    titleWrapper: {
      display: 'flex',
      alignItems: 'center',
    },
    title: {
      fontSize: 18,
      fontWeight: 600,
      paddingBottom: 20,
    },
    description: {
      marginBottom: 32,
    },
    contentWrapper: {
      width: '100%',
    },
    content: {
      width: '100%',
    },
  },

  fieldReadOnly: {
    label: {
      color: 'var(--ion-color-medium)',
      fontWeight: 300,
      '@media-mobile': { width: '100%', marginRight: 0 },
    },
    item: {
      wordBreak: 'break-word',
      display: 'flex',
      '@media-mobile': {
        fontSize: 'var(--font-size-h3)',
        justifyContent: 'flex-start',
      },
    },
    image: {
      maxWidth: 112,
    },
    value: {
      fontWeight: 600,
    },
    valueHighlighted: {
      fontSize: 28,
      fontWeight: 600,
    },
    textAreaItem: {
      marginRight: 1,
      marginBottom: 4,
    },
    textAreaValue: {
      // height: 144,
      overflowY: 'auto',
      paddingBottom: 9,
      paddingRight: 16,
      paddingTop: 11,
      marginRight: 0,
    },
    wrapper: {
      display: 'var(--display-flex-mobile-block)',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: 0,
    },
  },
}
