// TEST
import { post } from '../runtime'

const BffPath = {
  TestSetEmail: '/test/customers/me/setEmail',
}

export const AuthenticationTestApi = {
  TestSetEmail: async (email: string): Promise<void> => {
    await post(BffPath.TestSetEmail, {
      email,
    })
    return
  },
}
