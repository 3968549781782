import { IonPage } from '@ionic/react'
import { api, Mutation } from 'data/api'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useAuthAttemptContext } from 'scaffold/providers/authentication/AuthDelegator'
import { useAuthConfigContext } from 'scaffold/providers/authentication/AuthProvider'
import {
  AuthenticationAttempt,
  AuthenticationAttemptChallenge,
  CredentialsData,
  UserInfo,
} from 'types/Authentication'
import { AuthRegistrationData } from './AuthRegistration.data'
import { AuthRegistrationTemplate } from './AuthRegistration.template'

interface RegistrationPageProps {
  onAuthStepSuccess: (attempt: AuthenticationAttempt) => void
  onAuthError: (error: string) => void
  userInfo: UserInfo | null
}

export const AuthRegistrationPage = (props: RegistrationPageProps) => {
  const { i18n } = useTranslation()

  // Login start page redirect on start-up
  const { setDiscardLoginStartPage } = useAuthConfigContext()
  useEffect(() => {
    setDiscardLoginStartPage(false)
  }, [])

  // Registration attempt handling
  const attempt = useAuthAttemptContext() as AuthenticationAttemptChallenge

  const { mutateAsync: initializePassword, isLoading } = useMutation(
    api[Mutation.RegistrationInitalizePassword],
    {
      onSuccess: props.onAuthStepSuccess,
      onError: props.onAuthError,
    },
  )
  const registrationData = AuthRegistrationData({
    attempt,
    isLoading,
    userInfo: props.userInfo,
  })

  const onRegistration = (values: CredentialsData) =>
    initializePassword({ ...values, language: i18n.language, emailToken: registrationData.emailToken })

  return (
    <IonPage id="signup">
      <AuthRegistrationTemplate
        data={registrationData.data}
        formFields={registrationData.formFields}
        isLoading={registrationData.isLoading}
        name={registrationData.name}
        onRegistration={onRegistration}
      />
    </IonPage>
  )
}
