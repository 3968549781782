import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'

import { CardDataList } from 'components/CardDataList'
import { LoanDetailContent } from 'components/layout/LoanDetailContent'
import { DocumentsDownloadModal } from 'components/modal/DocumentsDownload.modal'
import { ErrorMessageModal } from 'components/modal/ErrorMessage.modal'
import { PaymentSlipModal } from 'components/modal/PaymentSlip.modal'
import { LoadingOverlay } from 'components/overlay/LoadingOverlay'
import { Document, DocumentTypeEnum, LoanStateEnum } from 'data/openapi/backend'
import { loanDocumentsListSecondaryGroups } from 'shared/constants'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { Loan } from 'types/Loan.d'

import { dataRows, mapLoanStateToDataRows } from './Overview.data'

export type Props = {
  loan: Loan
  availableDocuments: Document[]
  isLoading: boolean
  openDocumentsModal: boolean
}

export const LoanDetailOverview = (props: Props) => {
  const { t } = useTranslation()

  const [accountStatementModal, setAccountStatementModal] = useState(false)
  const [contractsDowloadModal, setContractsDowloadModal] = useState(props.openDocumentsModal)
  const [paymentSlipModal, setPaymentSlipModal] = useState(false)
  const list = props.loan.state ? mapLoanStateToDataRows[props.loan.state as LoanStateEnum] : []

  const accountStatementDocuments =
    props.availableDocuments
      ?.filter((document) => document.type === DocumentTypeEnum.AccountStatement)
      .sort((a, b) => (a.datedAt > b.datedAt ? -1 : 1)) || []

  const documentsList =
    props.availableDocuments?.filter(
      (document) => document.type !== DocumentTypeEnum.AccountStatement,
    ) || []

  const showAccountStatement = accountStatementDocuments.length > 0
  const showDocumentList = documentsList.length > 0
  const showPaymentSlip = true

  return (
    <>
      {props.isLoading ? (
        <LoadingOverlay isLoading={props.isLoading} label={t('overview.loadingLabel')} />
      ) : (
        <>
          {showAccountStatement && (
            <DocumentsDownloadModal
              modal={{
                title: 'accountStatement.download.modal.title',
                documentsList: accountStatementDocuments,
                loan: props.loan,
                isOpen: accountStatementModal,
                onClose: () => {
                  setAccountStatementModal(false)
                },
              }}
            />
          )}

          {showPaymentSlip && (
            <PaymentSlipModal
              modal={{
                title: 'paymentSlip.modal.title',
                loan: props.loan,
                isOpen: paymentSlipModal,
                onClose: () => {
                  setPaymentSlipModal(false)
                },
              }}
            />
          )}

          {showDocumentList ? (
            <DocumentsDownloadModal
              modal={{
                title: 'documents.download.modal.title',
                documentsList,
                groups: loanDocumentsListSecondaryGroups,
                loan: props.loan,
                isOpen: contractsDowloadModal,
                onClose: () => {
                  setContractsDowloadModal(false)
                },
              }}
            />
          ) : (
            <ErrorMessageModal
              title={'documents.list.error.title'}
              modal={{ isOpen: contractsDowloadModal, onClose: () => setContractsDowloadModal(false) }}
              message={t('documents.list.error.message')}
            />
          )}
          <LoanDetailContent
            {...props}
            pageProps={{
              header: {
                title: t('loanDetail.overview.title'),
                goBack: {
                  to: '/loans',
                },
              },
            }}
          >
            <CardDataList list={list.map((r) => dataRows[r](props.loan))} reverse>
              <ButtonWrapper>
                {showAccountStatement && props.loan.state !== LoanStateEnum.Cooloff && (
                  <LeftButtonWrapper>
                    <Button
                      override={{ maxWidth: '100%' }}
                      label="loanDetail.button.viewAccountStatement"
                      type={ButtonType.Chromeless}
                      onClick={() => {
                        setAccountStatementModal(true)
                      }}
                    />
                  </LeftButtonWrapper>
                )}
                {showPaymentSlip && (
                  <RightButtonWrapper>
                    <Button
                      override={{ maxWidth: '100%' }}
                      label="loanDetail.button.paymentSlip"
                      type={ButtonType.Chromeless}
                      onClick={() => {
                        setPaymentSlipModal(true)
                      }}
                    />
                  </RightButtonWrapper>
                )}
                {showDocumentList && (
                  <RightButtonWrapper>
                    <Button
                      override={{ maxWidth: '100%' }}
                      label="loanDetail.button.viewLoanContracts"
                      type={ButtonType.Chromeless}
                      onClick={() => {
                        setContractsDowloadModal(true)
                      }}
                    />
                  </RightButtonWrapper>
                )}
              </ButtonWrapper>
            </CardDataList>
          </LoanDetailContent>
        </>
      )}
    </>
  )
}

const ButtonWrapper = styled.div`
  padding: 16px;

  @media ${DesktopStyle} {
    margin-top: 16px;
    display: flex;
    width: 100%;
  }

  @media ${MobileStyle} {
    display: flex;
    flex-direction: column-reverse;
  }
`

const LeftButtonWrapper = styled.div`
  @media ${DesktopStyle} {
    width: 100%;
    float: left;
  }
  @media ${MobileStyle} {
  }
`
const RightButtonWrapper = styled.div`
  @media ${DesktopStyle} {
    margin-left: 8px;
    width: 100%;
    float: left;
  }
  @media ${MobileStyle} {
    margin-bottom: 8px;
  }
`
