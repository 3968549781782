/* export enum DocumentType {
  IDBack = 'IDBack',
  IDFront = 'IDFront',
  PartnerIDBack = 'IDBack',
  PartnerIDFront = 'IDFront',
  PartnerSalaryStatementMonth1 = 'SalaryStatementMonth1',
  PartnerSalaryStatementMonth2 = 'SalaryStatementMonth2',
  PartnerSalaryStatementMonth3 = 'SalaryStatementMonth3',
  SalaryStatementMonth1 = 'SalaryStatementMonth1',
  SalaryStatementMonth2 = 'SalaryStatementMonth2',
  SalaryStatementMonth3 = 'SalaryStatementMonth3',
  Contract = 'Contract',
  ContractFeeOverview = 'ContractFeeOverview',
  ContractAdditionalInformation = 'ContractAdditionalInformation',
  ContractSigned = 'ContractSigned',
} */

import { Loan as OpenapiLoanType } from 'data/openapi/backend/models/Loan'
import { CSSProperties } from 'styled-components'

export enum DocumentSwissNationalityOptions {
  SwissID = 'SwissId',
  SwissPassport = 'SwissPassport',
  SwissDrivingLicense = 'SwissDrivingLicense',
}

export enum DocumentNonSwissNationalityOptions {
  Passport = 'Passport',
  IdentityCard = 'IdentityCard',
  IdentityPaper = 'IdentityPaper',
  ResidencePermit = 'ResidencePermit',
}

export enum DocumentType {
  // Signed contract documents
  CreditAgreement = 'CreditAgreement',
  GeneralCreditConditionsAKB = 'GeneralCreditConditionsAKB',
  BudgetCalculation = 'BudgetCalculation',

  // Identity documents
  SwissIDFront = 'SwissIDFront',
  SwissIDBack = 'SwissIDBack',
  SwissPassport = 'SwissPassport',
  SwissDriverLicense = 'SwissDriverLicense',
  ForeignPassport = 'ForeignPassport',
  ForeignerIdentityCardBCFront = 'ForeignerIdentityCardBCFront',
  ForeignerIdentityCardBCBack = 'ForeignerIdentityCardBCBack',
  ForeignerIdentityPaper = 'ForeignerIdentityPaper',
  ResidencePermit = 'ResidencePermit',

  // Pay slips
  PaySlipLastMonth = 'PaySlipLastMonth',
  PaySlipMonthBeforeLast = 'PaySlipMonthBeforeLast',
  PaySlipMonthTwiceBeforeLast = 'PaySlipMonthTwiceBeforeLast',
  ReceiptForAdditionalIncome = 'ReceiptForAdditionalIncome',
  BankReceiptForIncomingWages = 'BankReceiptForIncomingWages',

  // Documents of the second person
  CopyOfPartnerIDCard = 'CopyOfPartnerIDCard',
  WageStatementPartnerLastMonth = 'WageStatementPartnerLastMonth',
  WageStatementPartnerMonthBeforeLast = 'WageStatementPartnerMonthBeforeLast',
  WageStatementPartnerMonthTwiceBeforeLast = 'WageStatementPartnerMonthTwiceBeforeLast',
  ReceiptForAdditionalIncomePartner = 'ReceiptForAdditionalIncomePartner',
  BankReceiptForIncomingWagesPartner = 'BankReceiptForIncomingWagesPartner',

  // Bank documents
  CopyOfBankCardForCreditDisbursement = 'CopyOfBankCardForCreditDisbursement',
  LoanFinalStatementThirdPartyBank = 'LoanFinalStatementThirdPartyBank',
  LeasingFinalStatementThirdPartyBank = 'LeasingFinalStatementThirdPartyBank',
  PayInSlipForLoanRedemption = 'PayInSlipForLoanRedemption',

  // Proof of current costs
  ApartmentLeaseAgreement = 'ApartmentLeaseAgreement',
  MortgageAgreement = 'MortgageAgreement',
  HealthInsurancePolicy = 'HealthInsurancePolicy',
  PremiumReductionHealthInsurance = 'PremiumReductionHealthInsurance',
  ProofOfAlimonyOrCreditNote = 'ProofOfAlimonyOrCreditNote',
  DivorceDecree = 'DivorceDecree',
  Miscellaneous = 'Miscellaneous',

  // Contracts
  Contract = 'Contract',
  ContractFeeOverview = 'ContractFeeOverview',
  ContractAdditionalInformation = 'ContractAdditionalInformation',
  ContractSigned = 'ContractSigned',
}

export interface Document {
  id: string
  type: DocumentType
  fileName: string
  url: string
  meta?: {
    size: number
    format: string
    createdAt: string
  }
}

export interface PartnerVariant {
  partnerVariantId: string
  partnerVariantName: string
  partnerVariantLogo: string | null
}

/* export interface Loan extends PartnerVariant {
  id: string
  contractNr: string
  amount: number
  interestRate: number
  interestRateAmount: number | null
  hasPpi: boolean
  installmentAmount: number
  installmentCount: number
  currentInstallment: number
  repayment: {
    firstRepaymentDate: string
    lastRepaymentDate: string
    nextRepaymentDate: string | null
    paidAmount: number
    remainingAmount: number
  } | null
  status: {
    applicationState: LoanApplicationState
    processingState: LoanProcessingState
    actionState?: LoanActionState
  }
  documents: Array<Document>
} */

export enum LoanApplicationState {
  LoanActive,
  LoanFinished,
  LoanPaymentOverdue,
  ContractReady,
  UploadApplicationDocuments,
  UploadSignedContract,
  AwaitingContractDocumentsReview,
  ContactDocumentsApproved,
}

export enum LoanProcessingState {
  Processing,
  ActionRequired,
  Success,
  Rejected,
  Error,
}

enum ErrorType {
  None,
  Rejection,
  TechnicalError,
  Warning,
}

export type FailedFieldDTO = {
  errorType: ErrorType
  errorSubType: string
  errorIdentifier: string
}

export type Loan = Omit<OpenapiLoanType, 'status'> & {
  status: {
    currentState: string
    customer: {
      applicationState: LoanApplicationState
      processingState: LoanProcessingState
      errors: Array<FailedFieldDTO>
    }
  }
}

export enum LoanNotificationType {
  Info = 'Info',
  Warning = 'Warning',
  Danger = 'Danger',
}

export enum LoanActionState {
  NoActionRequired = 'NoActionRequired',
  DownloadSignedContract = 'DownloadSignedContract',
  UploadApplicationDocuments = 'UploadApplicationDocuments',
  UploadSignedContract = 'UploadSignedContract',
  MakePayment = 'MakePayment',
}

export interface LoanNotificationAction {
  localeLabel: string
  href?: string
  onClick?: () => void
}

export interface LoanNotification {
  type: LoanNotificationType
  localeInfoText: string
  localeInfoData?: { date?: Date | null; amount?: number | null }
  action: LoanNotificationAction | null
}

export interface LoanDataItem extends Loan {
  notificationCount: number
  notification: LoanNotification | null
  link?: string
}

export enum ValueType {
  Image = 'image',
  Amount = 'amount',
  Duration = 'duration',
  Percentage = 'percentage',
  Date = 'date',
  DateRange = 'dateRange',
  Text = 'text',
  Separator = 'separator',
  Unknown = 'unknown',
}

type Item = {
  isLoading?: boolean
  label: string
  dataTestId?: string
  labelStyle?: CSSProperties
  style?: CSSProperties
}

interface TypeAmount extends Item {
  value?: number | null
  type: ValueType.Amount
}
interface TypeDate extends Item {
  value?: string | Date | null
  type: ValueType.Date
}
interface TypeDateRange extends Item {
  value?: Array<Date> | null
  type: ValueType.DateRange
}
interface TypeDuration extends Item {
  value?: number | null
  type: ValueType.Duration
}
interface TypeImage extends Item {
  value?: string | null
  type: ValueType.Image
}
interface TypePercentage extends Item {
  value?: number | null
  type: ValueType.Percentage
}
interface TypeText extends Item {
  value?: string | null
  type: ValueType.Text
}

export type LoanDataRow =
  | (Item & {
      value?: string
      type?: ValueType.Unknown
    })
  | TypeAmount
  | TypeDate
  | TypeDateRange
  | TypeDuration
  | TypeImage
  | TypePercentage
  | TypeText
  | {
      type: ValueType.Separator
    }
