import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { validateEmail } from 'pages/utils/validate'
import { StartResetPasswordFormData } from 'types/ResetPassword'

const startResetPasswordFormLens = makeFormLens<StartResetPasswordFormData>()

export const startResetPasswordFormFields: Array<FormField<StartResetPasswordFormData>> = [
  {
    type: FormFieldType.FormSection,
    description: 'authentication.forgotPassword.text2',
    fields: [
      {
        type: FormFieldType.TextInput,
        label: { label: 'authentication.formFields.email.label' },
        lens: startResetPasswordFormLens(['email']),
        name: 'email',
        placeholder: 'authentication.formFields.email.placeholder',
        validate: validateEmail,
      },
    ],
  },
]

export interface StartResetPasswordDataResult {
  data: StartResetPasswordFormData
  formFields: Array<FormField<StartResetPasswordFormData>>
}

export const StartResetPasswordData = (): StartResetPasswordDataResult => ({
  data: {
    email: '',
    language: '',
  },
  formFields: startResetPasswordFormFields,
})
