export enum ResetPasswordAttemptState {
  Error = 'error', // An error occurred while checking reset password attempt (e.g. missing scope params)
  Expired = 'expired', // The email token is expired and no more valid
  MTanCallenge = 'mTanChallenge',
  MTanChallengeFailLimitReached = 'failedMTanChallengeMaxFailsState',
  None = 'none', // Initial state before any calls to customer IDP services
  Succeeded = 'succeeded', // Successful reset password
}

export type ResetPasswordAttempt = {
  email?: string
  id?: string
  maxMTanFailCount: number
  maxMTanSendCount: number
  mTanFailCount: number
  mTanSendCount: number
  phone?: string
  state: ResetPasswordAttemptState.MTanCallenge | ResetPasswordAttemptState.MTanChallengeFailLimitReached
  version?: number
}
