import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'
import { Options } from 'frr-web/lib/html'
import { range } from 'fp-ts/lib/Array'

export type PaymentSlipDataProps = {
  amount: number
  number: number
  cutmarks: boolean
}

export interface PaymentSlipDataResult {
  data: PaymentSlipDataProps
  formFields: Array<FormField<PaymentSlipDataProps>>
}

type dropdownValues = {
  minimum: number
  maximum: number
  step: number
}

const calcDropdownLabels = (dropdownValues: dropdownValues): Options<number> =>
  range(0, Math.floor((dropdownValues.maximum - dropdownValues.minimum) / dropdownValues.step)).map(
    (s) => ({
      label: `${Math.round((dropdownValues.minimum + s * dropdownValues.step) * 1000) / 1000}`,
      isLabelTranslated: true,
      value: Math.round((dropdownValues.minimum + s * dropdownValues.step) * 1000) / 1000,
    }),
  )

const paymentSlipFormLens = makeFormLens<PaymentSlipDataProps>()

export const PaymentSlipData = (defaultAmount: number): PaymentSlipDataResult => {
  const paymentSlipFormFields: Array<FormField<PaymentSlipDataProps>> = [
    {
      type: FormFieldType.FormSection,
      fields: [
        {
          label: {
            label: 'paymentSlip.formFields.amount.label',
          },
          lens: paymentSlipFormLens(['amount']),
          name: 'amount',
          autocomplete: 'nope',
          type: FormFieldType.NumberInput,
          required: true,
          style: { wrapper: { maxHeight: 32, maxWidth: 80 }, input: { textAlign: 'right' } },
        },

        // TODO: DO NOT DELETE this, it will be activated soon
        // {
        //   label: { label: 'paymentSlip.formFields.number.label' },
        //   type: FormFieldType.NumberSelect,
        //   options: calcDropdownLabels({
        //     minimum: 1,
        //     maximum: 36,
        //     step: 1,
        //   }),
        //   lens: paymentSlipFormLens(['number']),
        //   required: true,
        //   style: {
        //     wrapper: { width: 100 },
        //     select: { display: 'flex', float: 'right' },
        //     menu: { backgroundColor: 'white' },
        //     option: {
        //       display: 'flex',
        //       justifyContent: 'right',
        //     },
        //   },
        // },

        // TODO: DO NOT DELETE this, it will be activated soon
        // {
        //   label: { label: 'paymentSlip.formFields.cutmarks.label' },
        //   type: FormFieldType.Toggle,
        //   lens: paymentSlipFormLens(['cutmarks']),
        //   style: {
        //     wrapper: {
        //       borderWidth: 1,
        //       borderStyle: 'solid',
        //       borderColor: '#CACBCB',
        //     },
        //     wrapperActive: {
        //       borderColor: '#CACBCB',
        //       backgroundColor: 'var(--primary-blue-3)',
        //       flexDirection: 'row-reverse',
        //     },
        //     circle: {
        //       height: 24,
        //       width: 24,
        //       borderWidth: 1,
        //       borderStyle: 'solid',
        //       borderColor: '#CACBCB',
        //     },
        //     circleActive: {
        //       borderColor: 'grey',
        //       backgroundColor: 'white',
        //     },
        //   },
        // },
      ],
    },
  ]

  return {
    formFields: paymentSlipFormFields,
    data: {
      amount: defaultAmount,
      number: 12,
      cutmarks: true,
    },
  }
}
