import { useEffect, useState } from 'react'
import { useMutation } from 'react-query'

import { api, Mutation } from 'data/api'
import { ToastType, useToast } from 'hooks/useToast'

import { ChangePasswordRequestParams } from 'data/authenticationapi/models/ChangePassword'
import { validateChangePassword } from 'pages/utils/validate'
import { CustomerRelationship } from 'types/CustomerRelationship'

import { SecurityData } from './Security.data'
import { SecurityTemplate } from './Security.template'

type Props = {
  user: CustomerRelationship
  onClose: () => void
}

export const Security = (props: Props) => {
  const securityData = SecurityData()
  const [values, setValues] = useState<ChangePasswordRequestParams>(securityData.data)
  const { showToast } = useToast()

  const { mutateAsync: submitNewPassword, isLoading } = useMutation(api[Mutation.ChangePassword], {
    onSuccess: () => {
      props.onClose()
      showToast('security.passwordChange.success.message', ToastType.Success)
    },
    onError: () => {
      props.onClose()
      showToast('security.passwordChange.error.message', ToastType.Error)
    },
  })

  const onSubmit = () => {
    submitNewPassword({
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      newPasswordRetyped: values.newPasswordRetyped,
    })
    setValues(securityData.data)
  }

  const [disableDirtyValidation, setDisableDirtyValidation] = useState<boolean>(true)
  const passwordValidated = () => validateChangePassword(values) === null

  useEffect(() => {
    if (values.newPassword === null || values.newPassword === '') {
      setDisableDirtyValidation?.(true)
    }
    if (!!values.newPassword && values.newPassword !== '') {
      if (values.newPassword === values.newPasswordRetyped && passwordValidated()) {
        setDisableDirtyValidation?.(true)
      } else {
        setDisableDirtyValidation?.(false)
      }
    }
  }, [values])

  return (
    <SecurityTemplate
      values={values}
      setValues={setValues}
      formFields={securityData.formFields(values)}
      onSubmit={onSubmit}
      buttonDisabled={
        securityData.isLoading ||
        values.newPassword === null ||
        values.currentPassword === null ||
        values.newPasswordRetyped === null ||
        values.newPassword !== values.newPasswordRetyped ||
        !passwordValidated()
      }
      isLoading={isLoading}
      disableDirtyValidation={disableDirtyValidation}
    />
  )
}
