import { IonPage } from '@ionic/react'
import { useMutation } from 'react-query'
import { useEffect, useState } from 'react'

import { api, Mutation } from 'data/api'
import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { ToastType, useToast } from 'hooks/useToast'
import { useAuthAttemptContext } from 'scaffold/providers/authentication/AuthDelegator'

import { AuthenticationAttempt, AuthenticationAttemptChallenge } from 'types/Authentication'

import { AuthOTPData } from './AuthOTP.data'
import { AuthOTPTemplate } from './AuthOTP.template'
import { useTranslation } from 'react-i18next'

interface OTPPageProps {
  onAuthStepSuccess: (attempt: AuthenticationAttempt) => void
  onAuthError: (error: string) => void
}

export const AuthOTPPage = (props: OTPPageProps) => {
  const attempt = useAuthAttemptContext() as AuthenticationAttemptChallenge
  const { i18n } = useTranslation()
  const { showToast } = useToast()

  const { mutateAsync: submitCode, isLoading } = useMutation(api[Mutation.LoginSubmitOTPCode], {
    onSuccess: props.onAuthStepSuccess,
    onError: props.onAuthError,
  })

  const { mutateAsync: resendCode } = useMutation(api[Mutation.LoginResendOTPCode], {
    onSuccess: (attempt) => {
      if (attempt.state === AuthenticationAttemptState.MTanCallenge) {
        showToast('authentication.resendCode.success.message', ToastType.Info)
      }
      props.onAuthStepSuccess(attempt)
    },
    onError: props.onAuthError,
  })

  const [otpError, setOTPError] = useState<string | null>(null)
  const [isOtpErrorExpired, setIsOtpErrorExpired] = useState<boolean>(false)
  const [otpInfo, setOTPInfo] = useState<string | null>(null)
  const otpData = AuthOTPData({ attempt })

  useEffect(() => {
    if (!isLoading) {
      if (attempt.currentMTanFailCount >= attempt.maxMTanFailCount) {
        setOTPError('authentication.errors.maxSubmitOTPCount')
      } else if (attempt.isMTanExpired) {
        setOTPError('authentication.errors.mTanSessionExpired')
        setIsOtpErrorExpired(true)
      } else if (attempt.currentMTanFailCount > 0) {
        if (!isOtpErrorExpired) {
          setOTPError('authentication.errors.invalidCode')
        }
      }
    }
  }, [
    attempt.currentMTanFailCount,
    attempt.isMTanExpired,
    attempt.maxMTanFailCount,
    isLoading,
    isOtpErrorExpired,
  ])

  useEffect(() => {
    if (attempt.currentMTanSendCount >= attempt.maxMTanSendCount) {
      setOTPInfo('authentication.errors.maxMTanSent')
    }
  }, [attempt.currentMTanSendCount, attempt.maxMTanSendCount])

  return (
    <IonPage id="otp">
      <AuthOTPTemplate
        data={otpData.data}
        error={otpError}
        info={otpInfo}
        formFields={otpData.formFields}
        isBlocked={otpData.isBlocked}
        isExpired={otpData.isExpired}
        isLoading={isLoading}
        isResendDisabled={otpData.isResendDisabled}
        phoneNr={otpData.phoneNr}
        onChange={() => otpError && !otpData.isBlocked && setOTPError(null)}
        onSubmitCode={(values) => {
          submitCode(values)
          setOTPError(null)
          isOtpErrorExpired && setIsOtpErrorExpired(false)
        }}
        onResendCode={() => {
          resendCode(i18n.language)
          setOTPError(null)
          attempt.isMTanExpired && setIsOtpErrorExpired(true)
        }}
      />
    </IonPage>
  )
}
