import { useTranslation } from 'react-i18next'

import { LayoutPage } from 'components/layout/LayoutPage'
import { LoadingOverlay } from 'components/overlay/LoadingOverlay'
import { LoanListProps, withLoanList } from 'data/hocs/withLoanList'
import { LoanListTemplate } from 'pages/loanList/LoanList.template'

import { LoanListData } from './LoanList.data'

const LoanListPageComponent = (props: LoanListProps) => {
  const { t } = useTranslation()

  const loanListData = LoanListData({
    loanList: props.loanList,
  })

  return (
    <LayoutPage pageId="overview" header={{ titleDesktop: t('overview.header.title') }}>
      <LoadingOverlay isLoading={props.isLoading} label={t('overview.loadingLabel')} />
      <LoanListTemplate
        applications={loanListData.openApplicationList}
        loans={loanListData.activeLoanList}
        isLoading={props.isLoading}
      />
    </LayoutPage>
  )
}

export const LoanListPage = withLoanList(LoanListPageComponent)
