import { Button } from 'frr-web/lib/components/Button'

import { ModalWithContent } from './ModalWithContent'
import { Props as ModalProps } from './Modal'

type Props = {
  modal: Omit<ModalProps, 'children'>
  onSubmit: () => void
}

export const DocumentsMissingModal = (props: Props) => {
  // TODO: replace string with locize keys
  return (
    <ModalWithContent
      modal={{
        ...props.modal,
      }}
      title={'Documents missing'}
      description="Not all of the documents were uploaded. Before your application can be reviewed, all documents need to be uploaded"
    >
      <Button label="Finish anyway" onClick={props.onSubmit} />
      <Button label="Continue uploading" onClick={props.modal.onClose} />
    </ModalWithContent>
  )
}
