import { useState } from 'react'
import styled from 'styled-components'

import { AccordionDocumentsList } from 'components/AccordionDocumentsList'
import { DocumentsList } from 'components/DocumentsList'
import { DocumentTypeEnum } from 'data/openapi/backend'
import { DocumentsList as DocumentsListProps } from 'pages/loanDetail/documentsDownload/DocumentsDownload.page'

type Props = {
  onDownload: (key: DocumentTypeEnum) => void
  documentsList: DocumentsListProps
  groups?: Array<DocumentTypeEnum>
}

export const ExpandedDocumentsDownloadTemplate = (props: Props) => {
  const { documentsList, onDownload, groups } = props

  const [expanded, setExpanded] = useState<string>('')

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    setExpanded(isExpanded ? panel : '')

  const mainGroup = groups
    ? Object.fromEntries(
        Object.entries(documentsList).filter(
          ([key]) => !groups.some((group) => group === documentsList[key].type),
        ),
      )
    : documentsList

  return (
    <ContentWrapper>
      <AccordionDocumentsList
        title={'loanDetail.documentsList.mainGroup'}
        onChange={handleChange('panel1')}
        expanded={expanded}
        id={'panel1'}
      >
        <DocumentsList documentsList={mainGroup} onDownload={onDownload} />
      </AccordionDocumentsList>

      {groups &&
      groups?.length > 0 &&
      Object.values(documentsList).some((value) => groups.find((group) => value.type === group))
        ? groups.map((group, index) => {
            const targetGroup = Object.fromEntries(
              Object.entries(documentsList).filter(([key]) => group === documentsList[key].type),
            )

            return (
              <AccordionDocumentsList
                key={`panel${index + 2}`}
                title={`loanDetail.documentList.${group}`}
                onChange={handleChange(`panel${index + 2}`)}
                expanded={expanded}
                id={`panel${index + 2}`}
              >
                <DocumentsList documentsList={targetGroup} onDownload={onDownload} />
              </AccordionDocumentsList>
            )
          })
        : null}
    </ContentWrapper>
  )
}

const ContentWrapper = styled.div`
  margin-top: 24px;
`
