import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import { useState } from 'react'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { CardTitleWrapper, LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { StartResetPasswordFormData } from 'types/ResetPassword'
import { H3, P } from 'frr-web/lib/html'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

export const StartResetPasswordTemplate = (props: {
  data: StartResetPasswordFormData
  error: string | null
  formFields: Array<FormField<StartResetPasswordFormData>>
  isSuccess: boolean
  isLoading: boolean
  onResetPassword: (values: StartResetPasswordFormData) => void
  onGoBack: () => void
  disableDirtyValidation?: boolean
  setDisableDirtyValidation: (value: boolean) => void
}) => {
  const [values, setValues] = useState(props.data)
  const onResetPassword = () => {
    props.onResetPassword(values)
  }

  const onChange = (values: StartResetPasswordFormData) => {
    setValues(values)
    if (values.email === null || values.email === '') {
      props.setDisableDirtyValidation?.(true)
    }
    if (!!values.email && values.email !== '') {
      props.setDisableDirtyValidation?.(false)
    }
  }

  return (
    <LayoutPageLandingContent
      title={'authentication.forgotPassword.title'}
      description={`authentication.forgotPassword.${props.isSuccess ? 'successText1' : 'text1'}`}
      descriptionData={values}
    >
      {props.isSuccess ? (
        <AuthenticationForm
          data={{}}
          formFields={[]}
          renderTopChildren={() => (
            <CardTitleWrapper>
              <H3 label="authentication.forgotPassword.registerMigratedCustomer.title" />
              <Text>
                <P label="authentication.forgotPassword.registerMigratedCustomer.text" />
              </Text>
              <Link data-test-id="registerMigratedCustomerLink" to="/login/register">
                <P label="authentication.forgotPassword.registerMigratedCustomer.linkText" />
              </Link>
            </CardTitleWrapper>
          )}
          buttons={[
            {
              label: 'authentication.actions.gotoLogin.label',
              onClick: props.onGoBack,
              type: ButtonType.Chromeless,
            },
          ]}
        />
      ) : (
        <AuthenticationForm
          data={values}
          error={props.error}
          formFields={props.formFields}
          onChange={onChange}
          onSubmit={onResetPassword}
          style={{
            form: {
              wrapper: {},
              content: {},
              buttonContainer: {
                flexDirection: 'column',
              },
            },
          }}
          buttons={[
            {
              label: 'authentication.actions.gotoLogin.label',
              onClick: props.onGoBack,
              type: ButtonType.Chromeless,
            },
            {
              isDisabled: (data) => props.isLoading || data.email === null || data.email === '',
              label: 'authentication.actions.forgotPassword.label',
              onClick: ({ submit }) => submit(),
              override: { marginTop: 16, width: '100%' },
              type: ButtonType.Primary,
            },
          ]}
          disableDirtyValidation={props.disableDirtyValidation}
        />
      )}
    </LayoutPageLandingContent>
  )
}

const Text = styled.div`
  width: 100%;

  @media ${MobileStyle} {
    margin-bottom: 24px;
  }
  @media ${DesktopStyle} {
    margin-bottom: 32px;
  }
`
