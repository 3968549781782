import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { IoChevronDown } from 'react-icons/io5'
import styled from 'styled-components'

import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

type Props = {
  title: string
  children: ReactNode
  onChange: (event: React.ChangeEvent<{}>, isExpanded: boolean) => void
  expanded: string
  id: string
}

export const AccordionDocumentsList = (props: Props) => {
  const { t } = useTranslation()
  const handleChange = (event: React.ChangeEvent<{}>, isExpanded: boolean) =>
    props.onChange(event, isExpanded)

  return (
    <AccordionWrapper>
      <Accordion expanded={props.expanded === props.id} onChange={handleChange}>
        <AccordionSummary
          expandIcon={<IoChevronDown style={{ height: 24, width: 24 }} />}
          style={{ marginRight: 16 }}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Title>
            <Typography style={{ fontWeight: 600 }}>{t(props.title)}</Typography>
          </Title>
        </AccordionSummary>
        <Separator />
        <AccordionDetails>
          <ChildWrapper>{props.children}</ChildWrapper>
        </AccordionDetails>
      </Accordion>
    </AccordionWrapper>
  )
}

const AccordionWrapper = styled.div`
  margin-bottom: 2px;
`

const Title = styled.div`
  margin: 0px 16px;
`

const Separator = styled.div`
  background-color: var(--neutral-gray-2);
  height: 1px;
  margin: 0px 16px 8px;
`

const ChildWrapper = styled.div`
  max-width: 600px;
  overflow: hidden;
  width: 100%;

  @media ${DesktopStyle} {
    padding: 0px 16px;
  }
`
