import { useState } from 'react'
import { useMutation } from 'react-query'
import { api, Mutation } from 'data/api'

import { ToastType, useToast } from 'hooks/useToast'
import { CustomerRelationship } from 'types/CustomerRelationship'

import { CustomerData, CustomerDataChangeValues } from './CustomerData.data'
import { CustomerDataTemplate } from './CustomerData.template'

type Props = {
  user: CustomerRelationship
  onClose: () => void
}

export const CustomerDataPage = (props: Props) => {
  const customerData = CustomerData()
  const [values, setValues] = useState<CustomerDataChangeValues>(customerData.data)
  const { showToast } = useToast()

  const { mutateAsync: sendChangeRequest, isLoading } = useMutation(
    api[Mutation.CustomerDataChangeRequest],
    {
      onSuccess: () => {
        props.onClose()
        showToast('customerData.dataChange.success.message', ToastType.Success)
      },
      onError: () => {
        props.onClose()
        showToast('customerData.dataChange.error.message', ToastType.Error)
      },
    },
  )

  const onSubmit = () => {
    sendChangeRequest({
      customerChangeRequest: {
        ...values,
        streetAndHouseNumber: values.street,
        zip: values.zipCode,
      },
    })
  }

  const securityTemplateProps = {
    values,
    setValues,
    customerData,
    onSubmit,
    isLoading,
  }

  return <CustomerDataTemplate {...securityTemplateProps} />
}
