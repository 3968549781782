import { Route, Redirect, useRouteMatch } from 'react-router-dom'

import { ProfileData } from './Profile.data'
import { Information } from './information/Information.template'
import { ProfileTemplate } from './Profile.template'

export enum ProfileRoute {
  Address = 'address',
  Information = 'information',
  Security = 'security',
}

export const ProfilePage = () => {
  const data = ProfileData()
  const match = useRouteMatch()

  if (!data.user) {
    return <>Loading...</>
  }

  return (
    <ProfileTemplate>
      <Route path={`${match.url}/${ProfileRoute.Information}`} exact>
        <Information user={data.user} />
      </Route>

      <Route render={() => <Redirect to={`${match.url}/${ProfileRoute.Information}`} />} />
    </ProfileTemplate>
  )
}
