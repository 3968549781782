/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CustomerChangeRequest
 */
export interface CustomerChangeRequest {
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeRequest
     */
    streetAndHouseNumber?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeRequest
     */
    zip?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeRequest
     */
    city?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeRequest
     */
    email?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeRequest
     */
    phone?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CustomerChangeRequest
     */
    comment?: string | null;
}

export function CustomerChangeRequestFromJSON(json: any): CustomerChangeRequest {
    return CustomerChangeRequestFromJSONTyped(json, false);
}

export function CustomerChangeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CustomerChangeRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'streetAndHouseNumber': !exists(json, 'streetAndHouseNumber') ? undefined : json['streetAndHouseNumber'],
        'zip': !exists(json, 'zip') ? undefined : json['zip'],
        'city': !exists(json, 'city') ? undefined : json['city'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'phone': !exists(json, 'phone') ? undefined : json['phone'],
        'comment': !exists(json, 'comment') ? undefined : json['comment'],
    };
}

export function CustomerChangeRequestToJSON(value?: CustomerChangeRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'streetAndHouseNumber': value.streetAndHouseNumber,
        'zip': value.zip,
        'city': value.city,
        'email': value.email,
        'phone': value.phone,
        'comment': value.comment,
    };
}

