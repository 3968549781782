import { useState } from 'react'
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera'
import { Filesystem, Directory } from '@capacitor/filesystem'
import { Storage } from '@capacitor/storage'

import { DocumentType } from '../types/Loan.d'

export interface UserPhoto {
  DocumentType: DocumentType
  filepath: string
  webviewPath?: string
}

const PHOTO_STORAGE = 'photos'

export function usePhotoGallery() {
  const [photos, setPhotos] = useState<UserPhoto[]>([])
  const [files, setFiles] = useState<Record<string, File | undefined>>({})

  const takePhoto = async (fileParams: { type: DocumentType }) => {
    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    }).catch(() => undefined)

    if (cameraPhoto) {
      const fileName = `photo-${new Date().getTime()}.jpeg`
      const base64Data = await base64FromPath(cameraPhoto.webPath!)
      const file = new File([base64Data], fileName)
      setFiles({ ...files, [fileParams.type]: file })
    }
  }

  const deletePhoto = async (photo?: UserPhoto) => {
    if (photo) {
      // Remove this photo from the Photos reference data array
      const newPhotos = photos.filter((p) => p.filepath !== photo.filepath)

      // Update photos array cache by overwriting the existing photo array
      Storage.set({ key: PHOTO_STORAGE, value: JSON.stringify(newPhotos) })

      // delete photo file from filesystem
      const filename = photo.filepath.substr(photo.filepath.lastIndexOf('/') + 1)
      await Filesystem.deleteFile({
        path: filename,
        directory: Directory.Data,
      })
      setPhotos(newPhotos)
    }
  }

  return {
    takePhoto,
    photos,
    deletePhoto,
    setPhotos,
    files,
    setFiles,
  }
}

export async function base64FromPath(path: string): Promise<string> {
  const response = await fetch(path)
  const blob = await response.blob()
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.onerror = reject
    reader.onload = () => {
      if (typeof reader.result === 'string') {
        resolve(reader.result)
      } else {
        reject('method did not return a string')
      }
    }
    reader.readAsDataURL(blob)
  })
}
