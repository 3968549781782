import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { ResetPasswordAttemptState } from 'data/authenticationapi/models/ResetPasswordAttempt'
import { validatePassword } from 'pages/utils/validate'
import { ResetPasswordAttempt, NewPassword } from 'types/ResetPassword'

const resetPasswordFormLens = makeFormLens<NewPassword>()

const resetPasswordFormFields = (data: NewPassword): Array<FormField<NewPassword>> => {
  const validateRetypedPassword = (value: string) =>
    value !== data.newPassword ? 'formFields.error.passwordMismatch' : null

  return [
    {
      type: FormFieldType.FormSection,
      fields: [
        {
          label: {
            label: 'security.formFields.newPassword.label',
            sublabel: 'security.formFields.newPassword.sublabel',
          },
          placeholder: 'security.formFields.newPassword.placeholder',
          lens: resetPasswordFormLens(['newPassword']),
          name: 'newPassword',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'password',
          required: true,
          validate: validatePassword,
        },
        {
          label: {
            label: 'security.formFields.newPasswordRetyped.label',
          },
          placeholder: 'security.formFields.newPasswordRetyped.placeholder',
          lens: resetPasswordFormLens(['newPasswordRetyped']),
          name: 'newPasswordRetyped',
          autocomplete: 'nope',
          type: FormFieldType.TextInput,
          inputType: 'password',
          required: true,
          validate: validateRetypedPassword,
        },
      ],
    },
  ]
}

const resetPasswordSuccessFormFields: Array<FormField<{}>> = [
  {
    type: FormFieldType.FormSection,
    // title: 'authentication.resetPassword.successText1',
    fields: [],
  },
]

export interface ResetPasswordDataResult {
  data: NewPassword
  error: string | null
  formFields: (data: NewPassword) => Array<FormField<NewPassword>>
  isSuccess: boolean
  isError: boolean
  isBlocked: boolean
  successFormFields: Array<FormField<{}>>
}

export const ResetPasswordData = (params: {
  attempt: ResetPasswordAttempt
}): ResetPasswordDataResult => {
  return {
    data: {
      newPassword: null,
      newPasswordRetyped: null,
    },
    error:
      (params.attempt.state === ResetPasswordAttemptState.Error && params.attempt.error) ||
      (params.attempt.state === ResetPasswordAttemptState.Expired &&
        'authentication.errors.resetPasswortSessionExpired') ||
      (params.attempt.state === ResetPasswordAttemptState.MTanChallengeFailLimitReached &&
        'authentication.errors.maxSubmitOTPCount') ||
      null,
    formFields: resetPasswordFormFields,
    isBlocked: params.attempt.state === ResetPasswordAttemptState.MTanChallengeFailLimitReached,
    isError: [ResetPasswordAttemptState.Error, ResetPasswordAttemptState.Expired].includes(
      params.attempt.state,
    ),
    isSuccess: params.attempt.state === ResetPasswordAttemptState.Succeeded,
    successFormFields: resetPasswordSuccessFormFields,
  }
}
