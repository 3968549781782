import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import { useCallback, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { SubmitCodeData } from 'types/Authentication'
import { setValue } from '@microsoft/applicationinsights-core-js'

export const AuthOTPTemplate = (props: {
  data: SubmitCodeData
  error: string | null
  info: string | null
  formFields: Array<FormField<SubmitCodeData>>
  isBlocked: boolean
  isExpired: boolean
  isLoading: boolean
  isResendDisabled: boolean
  phoneNr?: string
  onChange: () => void
  onSubmitCode: (values: SubmitCodeData) => void
  onResendCode: () => void
}) => {
  const [values, setValues] = useState(props.data)
  const [error, setError] = useState<string | null>(props.error)

  const onChange = (values: SubmitCodeData) => {
    setValues(values)
    props.onChange()
  }

  const onSubmitCode = () => {
    props.onSubmitCode(values)
  }
  const onResendCode = () => {
    props.onResendCode()
    setValues(props.data)
  }

  const handleUserKeyPress = useCallback((event: KeyboardEvent, values: SubmitCodeData) => {
    const { key } = event
    const { code } = values

    if (key === 'Enter' && code.replaceAll('-', '').length === 6) {
      window.document.getElementById('submitButton')?.click()
    }
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', (event) => handleUserKeyPress(event, values))
    return () => {
      window.removeEventListener('keydown', (event) => handleUserKeyPress(event, values))
    }
  }, [values, handleUserKeyPress])

  useEffect(() => {
    if (props.error !== null) {
      setValues({ code: '------' })
      setError(props.error)
    }
  }, [props.error])

  return (
    <LayoutPageLandingContent
      title={'authentication.otp.title'}
      description={`authentication.otp.text1`}
    >
      <PhoneNrText data-test-id="phoneNr">{props.phoneNr}</PhoneNrText>

      <AuthenticationForm
        data={values}
        error={error}
        formFields={props.formFields}
        info={props.info}
        onChange={onChange}
        onSubmit={onSubmitCode}
        style={{
          form: {
            wrapper: {},
            content: {},
            buttonContainer: {
              flexDirection: 'column',
            },
          },
        }}
        buttons={[
          {
            disabled: props.isBlocked || props.isResendDisabled,
            label: 'authentication.actions.resendCode.label',
            onClick: onResendCode,
            type: ButtonType.Chromeless,
          },
          {
            isDisabled: (data) =>
              props.isBlocked ||
              props.isExpired ||
              props.isLoading ||
              data.code.replace('-', '').length < 6,
            label: 'authentication.actions.signup.label',
            onClick: ({ submit }) => submit(),
            override: { marginTop: 16, width: '100%' },
            type: ButtonType.Primary,
            id: 'submitButton',
          },
        ]}
      />
    </LayoutPageLandingContent>
  )
}

const PhoneNrText = styled.h2`
  font-weight: 600;
  margin-bottom: 32px;
`
