import { ElementType } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

import { LoadingOverlay } from 'components/overlay/LoadingOverlay'
import { api, Query } from 'data/api'
import { CustomerRelationship } from 'types/CustomerRelationship'

import { MapMyCockpitApiResponse, MyCockpitApiRetry } from './utils/MapMyCockpitApiResponse'

export interface UserProps {
  user: CustomerRelationship
  isLoading: boolean
}

export const withUser = <T extends {}>(Component: ElementType) => {
  return (props: T) => {
    const { t } = useTranslation()

    const response = useQuery<CustomerRelationship, Response>(Query.FetchMe, api[Query.FetchMe], {
      enabled: true,
      retry: MyCockpitApiRetry,
    })

    const { isLoading, data } = MapMyCockpitApiResponse<CustomerRelationship>(response)

    return isLoading ? (
      <LoadingOverlay label={t('user.loadingLabel')} isLoading={isLoading} />
    ) : (
      <Component user={data} isLoading={isLoading} {...props} />
    )
  }
}
