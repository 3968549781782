import './vendor/ReactVirtualized.css'

export const baseStyle = `
/* Base styles */

html,
body {
  font-family: var(--ion-font-family);
}

* {
  box-sizing: border-box;
}

b {
  font-weight: 600;
}

input,
input:focus {
  outline: none;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
}
select:focus {
  outline: none;
}

/* Remove the brower's default on touch styles */

button, li, a, div, span, select, input {
  -webkit-tap-highlight-color: transparent;
}
:focus {
  outline: none;
}
`
