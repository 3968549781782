import { LoanDetailProps } from 'data/hocs/withLoanDetail'
import { LoanDataItem } from '../../types/Loan'

export type LoanDetailDataResult = {
  loan: LoanDataItem | null
}

export const LoanDetailData = (params: LoanDetailProps): LoanDetailDataResult => {
  const data = params.loan
    ? {
        loan: {
          ...params.loan,
          notification: null,
          notificationCount: 0,
        },
      }
    : {
        loan: null,
      }

  return data
}
