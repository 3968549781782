import { IonPage } from '@ionic/react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router'
import { useAuthConfigContext } from 'scaffold/providers/authentication/AuthProvider'
import { AuthLoginStartTemplate } from './AuthLoginStart.template'

export const AuthLoginStartPage = () => {
  const history = useHistory()
  const { discardLoginStartPage, setDiscardLoginStartPage } = useAuthConfigContext()

  const [discardPage, setDiscardPage] = useState(false)

  useEffect(() => {
    // Redirect if discardLoginStartPage is active
    if (discardLoginStartPage) {
      history.replace('/login')
    }
  }, [])

  return (
    <IonPage id="login-start">
      <AuthLoginStartTemplate
        discardPage={discardPage}
        onDiscardPage={setDiscardPage}
        onRegister={() => {
          setDiscardLoginStartPage(discardPage)
          history.push('/login/register')
        }}
        onLogin={() => {
          setDiscardLoginStartPage(discardPage)
          history.push('/login')
        }}
      />
    </IonPage>
  )
}
