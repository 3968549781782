import { AuthenticationForm } from 'components/AuthenticationForm'
import { LayoutPageLandingContent } from 'components/layout/LayoutPageLandingContent'
import { ButtonType } from 'frr-web/lib/components/Button'
import { FormField } from 'frr-web/lib/form/components/types'
import { useState } from 'react'
import styled from 'styled-components'
import { SubmitResetPasswordData } from 'types/ResetPassword'

export const ResetPasswordOTPTemplate = (props: {
  data: SubmitResetPasswordData
  error: string | null
  formFields: Array<FormField<SubmitResetPasswordData>>
  isBlocked: boolean
  isResendDisabled: boolean
  onChange: () => void
  onResendCode: () => void
  onSubmitCode: (values: SubmitResetPasswordData) => void
  phoneNr?: string
}) => {
  const [values, setValues] = useState(props.data)

  const onChange = (values: SubmitResetPasswordData) => {
    setValues(values)
    props.onChange()
  }
  const onSubmitCode = () => {
    props.onSubmitCode(values)
  }
  const onResendCode = () => {
    props.onResendCode()
  }

  return (
    <LayoutPageLandingContent
      title={'authentication.resetPassword.otp.title'}
      description={`authentication.resetPassword.otp.text1`}
    >
      <PhoneNrText data-test-id="phoneNr">{props.phoneNr}</PhoneNrText>

      <AuthenticationForm
        data={values}
        error={props.error}
        formFields={props.formFields}
        onChange={onChange}
        onSubmit={onSubmitCode}
        style={{
          form: {
            wrapper: {},
            content: {},
            buttonContainer: {
              flexDirection: 'column',
            },
          },
        }}
        buttons={[
          {
            disabled: props.isBlocked || props.isResendDisabled,
            label: 'authentication.actions.resendCode.label',
            onClick: onResendCode,
            type: ButtonType.Chromeless,
          },
          {
            isDisabled: (data) => props.isBlocked || data.code.replace('-', '').length < 6,
            label: 'authentication.actions.resetPassword.label',
            onClick: ({ submit }) => submit(),
            override: { marginTop: 16, width: '100%' },
            type: ButtonType.Primary,
          },
        ]}
      />
    </LayoutPageLandingContent>
  )
}

const PhoneNrText = styled.h2`
  font-weight: 600;
  margin-bottom: 32px;
`
