import { IonPage, IonRefresher, IonRefresherContent } from '@ionic/react'
import { ReactNode } from 'react'
import styled from 'styled-components'

import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'
import { AppHeader, Props as HeaderProps } from '../header/AppHeader'

import { Footer } from './Footer'

export type Props = {
  header?: HeaderProps
  children: ReactNode
  pageId?: string
  onRefresh?: () => void
}

export const LayoutPage = (props: Props) => {
  return (
    <IonPage id={props.pageId || 'default'}>
      <Wrapper>
        <AppHeader {...(props.header || {})} />
        <div id="page-content">
          <PageOuterWrapper>
            <PageContentWrapper>
              {props.onRefresh && (
                <IonRefresher slot="fixed" onIonRefresh={props.onRefresh}>
                  <IonRefresherContent></IonRefresherContent>
                </IonRefresher>
              )}
              {props.children}
            </PageContentWrapper>
          </PageOuterWrapper>
        </div>
        <Footer textColor="black" />
      </Wrapper>
    </IonPage>
  )
}

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  overflow-y: auto;
`

const PageOuterWrapper = styled.div`
  width: 100%;
  overflow-y: auto;

  @media ${MobileStyle} {
    min-height: calc(var(--vh, 1vh) * 100 - 144px);
  }

  @media ${DesktopStyle} {
    min-height: calc(var(--vh, 1vh) * 100 - 256px);
  }
`

const PageContentWrapper = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 16px;

  @media ${DesktopStyle} {
    padding: 0 48px;
  }
`
