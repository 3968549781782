import React, { ElementType } from 'react'
import { useQuery } from 'react-query'
import { useRouteMatch } from 'react-router-dom'

import { api, Query } from 'data/api'
import { Loan as OpenapiLoan } from 'data/openapi/backend'
import { CustomerRelationship } from 'types/CustomerRelationship'
import { Loan } from 'types/Loan'
import { MapMyCockpitApiResponse, MyCockpitApiRetry } from './utils/MapMyCockpitApiResponse'

export interface LoanDetailProps {
  user: CustomerRelationship | null
  loan: Loan | null
  isLoading: boolean
}

export const withLoanDetail = <T extends {}>(Component: ElementType) => {
  return (props: T) => {
    const match = useRouteMatch<{ loanId: string }>()

    // TODO this is temporary stuff, to be replaced when we've got it working with the BE

    /* const { data, isLoading, isError, error } = useQuery(Query.FetchLoanDetails, () =>
      api[Query.FetchLoanDetails]({ unifiedLoanId: match.params.loanId }),
    ) */

    const response = useQuery<Array<OpenapiLoan>, Response>(
      Query.FetchLoanList,
      api[Query.FetchLoanList],
      {
        enabled: true,
        retry: MyCockpitApiRetry,
      },
    )

    const { isLoading, data } = MapMyCockpitApiResponse<Array<OpenapiLoan>>(response)

    const componentData = data?.find((d) => d.id === match.params.loanId)

    return <Component loan={componentData} isLoading={isLoading} {...props} />
  }
}
