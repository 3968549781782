// TEST
import {
  AuthenticationAttempt,
  AuthenticationAttemptChallenge,
  AuthenticationAttemptSuccess,
  UserInfo,
} from 'types/Authentication.d'
import { AuthenticationAttemptState } from '../models/AuthenticationAttempt'
import { ChangePasswordRequestParams } from '../models/ChangePassword'
import {
  LoginSubmitCredentialsRequestParams,
  RegistrationInitalizePasswordRequesetParams,
  SubmitOTPCodeRequesetParams,
} from '../models/CustomerAuthentication'
import { RegistrationStartRequestParams } from '../models/Registration'
import { get, post } from '../runtime'
import { mapAuthenticationAttempt } from './utils/mapAuthenticationAttempt'
import { mapAuthenticationAttemptWithErrorCode } from './utils/mapAuthenticationAttemptWithErrorCode'
import { mapUserInfo } from './utils/mapUserInfo'

const CUSTOMER_IDP_PROXY_PATH = '/idp'

const BffPath = {
  FetchUserInfo: `/auth/userinfo`,
  Authorize: `/auth/connect/authorize`,
  EndSession: `/auth/connect/end-session`,
  LoginEnd: `/auth/login/end`,
  LoginStart: `/auth/login/start`,
  Logout: `/auth/logout`,
}

const CustomerIDPPath = {
  FetchAuthenticationAttempt: `/idp/authorizationAttempt`,
  FetchCustomerState: `/idp/customers/me/state`,
  ChangePassword: '/idp/customers/me/changePassword',
  LoginSubmitCredentials: `/idp/authorizationAttempt/submitPassword`,
  ResendOTPCode: `/idp/authorizationAttempt/sendMtan`,
  SubmitOTPCode: `/idp/authorizationAttempt/submitMtan`,
  RegistrationInitalizePassword: `/idp/customers/me/initializePassword`,
  Register: `/idp/customers/register`,
}

export const IntialAuthenticationAttempt: AuthenticationAttemptChallenge = {
  state: AuthenticationAttemptState.PasswordChallenge,
  currentMTanFailCount: 0,
  currentMTanSendCount: 0,
  currentPasswordFailCount: 0,
  isMTanExpired: false,
  maxMTanFailCount: 5,
  maxMTanSendCount: 5,
  maxPasswordFailCount: 5,
}

export const SucceededAuthenticatedAttempt: AuthenticationAttemptSuccess = {
  state: AuthenticationAttemptState.Succeeded,
}

export const AuthenticationApi = {
  /*
   * Queries
   */

  // Authentication
  FetchAuthenticationAttempt: async (): Promise<AuthenticationAttempt> => {
    const attemptResponse = await get(CustomerIDPPath.FetchAuthenticationAttempt)
    return attemptResponse.status === 200
      ? await mapAuthenticationAttempt(attemptResponse)
      : IntialAuthenticationAttempt
  },

  FetchUserInfo: async (): Promise<UserInfo | null> => {
    const userInfoResponse = await get(BffPath.FetchUserInfo)
    return userInfoResponse.status === 200 ? await mapUserInfo(userInfoResponse) : null
  },

  FetchCustomerState: async (
    emailToken: string,
  ): Promise<{
    userInfo: UserInfo | null
    state: AuthenticationAttemptState
  }> => {
    const customerStateResponse = await get(CustomerIDPPath.FetchCustomerState, {
      Authorization: `Bearer ${emailToken}`,
    })

    const userInfo =
      customerStateResponse.status === 200 ? await mapUserInfo(customerStateResponse, emailToken) : null

    return {
      userInfo,
      state:
        (userInfo && AuthenticationAttemptState.PasswordChallenge) ||
        (customerStateResponse.status === 401 && AuthenticationAttemptState.Expired) ||
        AuthenticationAttemptState.Error,
    }
  },

  /*
   * Mutations
   */

  LoginStart: async (): Promise<AuthenticationAttempt> => {
    const bffResponse = await post(BffPath.LoginStart)
    if (!bffResponse.ok) throw new Error('login start failed')
    if (bffResponse.status === 204) return SucceededAuthenticatedAttempt

    const { authorizationUrl } = await bffResponse.json()

    const idpResponse = await post(BffPath.Authorize, { authorizationUrl })
    if (!idpResponse.ok) throw new Error('authorizationUrl failed')

    return await mapAuthenticationAttempt(idpResponse)
  },

  LoginEnd: async (returnUrl: string): Promise<true> => {
    const idpResponse = await get(`${CUSTOMER_IDP_PROXY_PATH}${returnUrl}`)

    if (!idpResponse.redirected) throw new Error('returnUrl not redirected')

    const bffResponse = await post(BffPath.LoginEnd, { pageUrl: idpResponse.url })

    if (!bffResponse.ok) throw new Error('technical error while logging in')

    return true
  },

  Logout: async (): Promise<AuthenticationAttempt> => {
    const bffResponse = await post(BffPath.Logout, {})
    if (!bffResponse.ok) {
      console.error('logout failed')
    }

    const { endSessionUrl } = await bffResponse.json()

    const idpResponse = await post(BffPath.EndSession, { endSessionUrl })
    if (!idpResponse.ok) throw new Error('logout session url failed')

    return IntialAuthenticationAttempt
  },

  /*
   * Customer IDP endpoints
   */

  RegistrationStart: async (values: RegistrationStartRequestParams): Promise<void> => {
    const idpResponse = await post(
      CustomerIDPPath.Register,
      { email: values.email },
      { 'Accept-Language': values.language },
    )

    if (![202].includes(idpResponse.status))
      throw new Error('technical error while starting reset password')

    return
  },

  RegistrationInitalizePassword: async (
    values: RegistrationInitalizePasswordRequesetParams,
  ): Promise<AuthenticationAttempt> => {
    // 1. initialize password ...
    const idpResponse = await post(
      CustomerIDPPath.RegistrationInitalizePassword,
      {
        password: values.password,
      },
      {
        Authorization: `Bearer ${values.emailToken}`,
      },
    )
    if (![200, 401].includes(idpResponse.status)) throw new Error('error while initializing password')

    // Expired
    if (idpResponse.status === 401) {
      return await mapAuthenticationAttempt(idpResponse)
    }

    const initializePasswordData = await idpResponse.json()

    // 2. ... and start login flow
    await AuthenticationApi.LoginStart()

    // 3. ... send credentials
    return await AuthenticationApi.LoginSubmitCredentials({
      username: initializePasswordData.email,
      password: values.password,
      language: values.language,
    })
  },

  LoginSubmitCredentials: async (
    values: LoginSubmitCredentialsRequestParams,
  ): Promise<AuthenticationAttempt> => {
    const idpResponse = await post(CustomerIDPPath.LoginSubmitCredentials, {
      email: values.username,
      password: values.password,
    })
    if (![200, 400, 401].includes(idpResponse.status))
      throw new Error('error while submitting credentials')

    let attempt = await mapAuthenticationAttemptWithErrorCode(
      idpResponse,
      AuthenticationApi.FetchAuthenticationAttempt,
    )

    // If success, send initial mTan code to user
    if (attempt.state === AuthenticationAttemptState.MTanCallenge) {
      attempt = await AuthenticationApi.ResendOTPCode(values.language)
    }

    return attempt
  },

  SubmitOTPCode: async (values: SubmitOTPCodeRequesetParams): Promise<AuthenticationAttempt> => {
    const idpResponse = await post(CustomerIDPPath.SubmitOTPCode, {
      mtan: values.code,
    })

    if (![200, 400, 401].includes(idpResponse.status))
      throw new Error('technical error while submitting mtan')

    return await mapAuthenticationAttemptWithErrorCode(
      idpResponse,
      AuthenticationApi.FetchAuthenticationAttempt,
    )
  },

  ResendOTPCode: async (language: string): Promise<AuthenticationAttempt> => {
    const idpResponse = await post(CustomerIDPPath.ResendOTPCode, null, { 'Accept-Language': language })

    if (![200, 400, 401].includes(idpResponse.status))
      throw new Error('technical error while sending mtan')

    return await mapAuthenticationAttemptWithErrorCode(
      idpResponse,
      AuthenticationApi.FetchAuthenticationAttempt,
    )
  },

  ChangePassword: async (values: ChangePasswordRequestParams): Promise<any> => {
    const idpResponse = await post(CustomerIDPPath.ChangePassword, {
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
    })

    if (idpResponse.status !== 200) throw new Error('technical error while changing password')

    return await idpResponse.json()
  },
}
