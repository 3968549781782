import { AuthenticationAttemptState } from 'data/authenticationapi/models/AuthenticationAttempt'
import { FormField, FormFieldType } from 'frr-web/lib/form/components/types'
import { makeFormLens } from 'frr-web/lib/form/util'

import { AuthenticationAttemptChallenge, CredentialsData } from 'types/Authentication'
import { validateEmail } from '../../utils/validate'

const loginFormLens = makeFormLens<CredentialsData>()

interface LoginDataResult {
  data: CredentialsData
  formFields: Array<FormField<CredentialsData>>
  isBlocked: boolean
}

export const AuthLoginData = (params: {
  attempt: AuthenticationAttemptChallenge
  onResetError: () => void
}): LoginDataResult => {
  const isBlocked =
    params.attempt.state !== AuthenticationAttemptState.PasswordChallenge ||
    params.attempt.currentPasswordFailCount >= params.attempt.maxPasswordFailCount

  const loginFormFields: Array<FormField<CredentialsData>> = [
    {
      type: FormFieldType.FormSection,
      title: 'authentication.formFields.loginTitle',
      fields: [
        {
          type: FormFieldType.TextInput,
          autocomplete: 'username',
          inputType: 'email',
          label: { label: 'authentication.formFields.username.label' },
          lens: loginFormLens(['username']),
          name: 'username',
          onFocus: () => !isBlocked && params.onResetError(),
          required: true,
          validate: validateEmail,
        },
        {
          type: FormFieldType.TextInput,
          autocomplete: 'current-password',
          inputType: 'password',
          label: { label: 'authentication.formFields.password.label' },
          lens: loginFormLens(['password']),
          name: 'password',
          onFocus: () => !isBlocked && params.onResetError(),
          required: true,
        },
      ],
    },
  ]

  return {
    formFields: loginFormFields,
    data: {
      username: null,
      password: null,
      language: '',
    },
    isBlocked,
  }
}
