import { IonRouterOutlet } from '@ionic/react'
import { Route, useRouteMatch } from 'react-router-dom'

import { LoanDetailProps, withLoanDetail } from 'data/hocs/withLoanDetail'
import { LoanDocumentsProps, withLoanDocuments } from 'data/hocs/withLoanDocuments'

import { LoanDetailDocuments } from './documents/Documents.page'
import { LoanDetailData } from './LoanDetail.data'
import { LoanDetailOverview } from './overview/Overview.page'

export enum LoanDetailRoute {
  Overview = 'overview',
  Documents = 'documents',
}

const LoanDetailPageComponent = (props: LoanDetailProps & LoanDocumentsProps) => {
  const data = LoanDetailData(props)
  const match = useRouteMatch()

  if (!data.loan) {
    return <div />
  }

  return (
    <IonRouterOutlet id="loan-detail-pages">
      <Route path={`${match.url}/${LoanDetailRoute.Overview}`} exact>
        <LoanDetailOverview
          loan={data.loan}
          availableDocuments={props.documents}
          isLoading={props.isLoading || props.isLoadingDocuments}
          openDocumentsModal={false}
        />
      </Route>
      <Route path={`${match.url}/${LoanDetailRoute.Overview}/${LoanDetailRoute.Documents}`} exact>
        <LoanDetailOverview
          loan={data.loan}
          availableDocuments={props.documents}
          isLoading={props.isLoading || props.isLoadingDocuments}
          openDocumentsModal={true}
        />
      </Route>
      <Route path={`${match.url}/${LoanDetailRoute.Documents}`} exact>
        <LoanDetailDocuments loan={data.loan} />
      </Route>
    </IonRouterOutlet>
  )
}

export const LoanDetailPage = withLoanDetail(withLoanDocuments(LoanDetailPageComponent))
