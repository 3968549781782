import { ErrorCode } from 'data/authenticationapi/models/ErrorCode'
import { AuthenticationAttempt, AuthenticationAttemptChallenge } from 'types/Authentication.d'
import { AuthenticationAttemptState } from '../../models/AuthenticationAttempt'
import { mapAuthenticationAttempt } from './mapAuthenticationAttempt'

export const mapAuthenticationAttemptWithErrorCode = async (
  response: Response,
  fetchAuthenticationAttempt: () => Promise<AuthenticationAttempt>,
) => {
  let mappedAttempt

  if (response.status === 401) {
    // Status 401 = Token is expired
    mappedAttempt = {
      state: AuthenticationAttemptState.Expired,
    }
  } else if (response.status === 400) {
    const error = await response.json()
    switch (error.errorCode) {
      // Password challenge with blocked user after reload or in a new browser
      case ErrorCode.CustomerLockedOut: {
        mappedAttempt = {
          currentMTanFailCount: 0,
          currentMTanSendCount: 0,
          currentPasswordFailCount: 5,
          phoneNr: '',
          email: '',
          id: 'none',
          maxMTanFailCount: 5,
          maxMTanSendCount: 5,
          maxPasswordFailCount: 5,
          state: AuthenticationAttemptState.PasswordChallengeFailLimitReached,
        }
        break
      }
      // MTan code is expired
      case ErrorCode.AuthorizationAttemptMTanExpired: {
        mappedAttempt = (await fetchAuthenticationAttempt()) as AuthenticationAttemptChallenge
        mappedAttempt.isMTanExpired = true
        break
      }
      // Fetch authentication attempt in case of other errors
      default: {
        mappedAttempt = await fetchAuthenticationAttempt()
      }
    }
  } else {
    mappedAttempt = await mapAuthenticationAttempt(response)
  }
  return mappedAttempt as AuthenticationAttempt
}
