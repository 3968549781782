import styled, { CSSProperties } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { ReactNode } from 'react'
import { Button, ButtonType } from 'frr-web/lib/components/Button'
import { Props as ButtonProps } from 'frr-web/lib/components/Button'

import { DesktopStyle, MobileStyle } from 'styles/theme/IonicMediaQuery'

import { Modal, Props as ModalProps } from './Modal'

type Props = {
  modal: Omit<ModalProps, 'children'>
  title: string | ReactNode
  children: ReactNode
  description?: string
  buttonProps?: ButtonProps
  style?: {
    header?: { mobile?: CSSProperties; desktop?: CSSProperties }
    footer?: { mobile?: CSSProperties; desktop?: CSSProperties }
  }
}

export const ModalWithContent = (props: Props) => {
  const { t } = useTranslation()

  return (
    <Modal {...props.modal}>
      <ModalHeader {...props.style?.header}>
        {typeof props.title === 'string' ? <h4>{t(props.title)}</h4> : props.title}
        <img
          src="/assets/close.svg"
          style={{ width: 20, opacity: 0.7, cursor: 'pointer' }}
          onClick={props.modal.onClose}
          alt="arrow"
          data-test-id="modal-close"
        />
      </ModalHeader>
      {props.description && <p style={{ marginTop: 24, maxWidth: 320 }}>{t(props.description)}</p>}
      {props.children}
      {props.buttonProps && (
        <ModalFooter {...props.style?.footer}>
          {!!props.modal.onClose && (
            <Button
              type={ButtonType.Chromeless}
              label={t('button.cancel')}
              onClick={props.modal.onClose}
              override={{ maxWidth: '100%' }}
            />
          )}
          <Button {...props.buttonProps} />
        </ModalFooter>
      )}
    </Modal>
  )
}

const ModalHeader = styled.div<{ mobile?: CSSProperties; desktop?: CSSProperties }>`
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media ${DesktopStyle} {
    ${({ desktop }) =>
      desktop &&
      Object.keys(desktop)
        .map((key) =>
          `${key}: ${desktop[key as keyof CSSProperties]}`.replace(
            /[A-Z]/g,
            (letter) => `-${letter.toLowerCase()}`,
          ),
        )
        .join(';')}
  }

  @media ${MobileStyle} {
    ${({ mobile }) =>
      mobile &&
      Object.keys(mobile)
        .map((key) =>
          `${key}: ${mobile[key as keyof CSSProperties]}`.replace(
            /[A-Z]/g,
            (letter) => `-${letter.toLowerCase()}`,
          ),
        )
        .join(';')}
  }
`

const ModalFooter = styled.div<{ mobile?: CSSProperties; desktop?: CSSProperties }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 32px 0;

  @media ${DesktopStyle} {
    ${({ desktop }) =>
      desktop &&
      Object.keys(desktop)
        .map((key) =>
          `${key}: ${desktop[key as keyof CSSProperties]}`.replace(
            /[A-Z]/g,
            (letter) => `-${letter.toLowerCase()}`,
          ),
        )
        .join(';')}
  }

  @media ${MobileStyle} {
    ${({ mobile }) =>
      mobile &&
      Object.keys(mobile)
        .map((key) =>
          `${key}: ${mobile[key as keyof CSSProperties]}`.replace(
            /[A-Z]/g,
            (letter) => `-${letter.toLowerCase()}`,
          ),
        )
        .join(';')}
  }
`
