/* tslint:disable */
/* eslint-disable */
/**
 * MyCockpit API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    LoanStateEnum,
    LoanStateEnumFromJSON,
    LoanStateEnumFromJSONTyped,
    LoanStateEnumToJSON,
} from './LoanStateEnum';
import {
    Product,
    ProductFromJSON,
    ProductFromJSONTyped,
    ProductToJSON,
} from './Product';

/**
 * A loan.
 * @export
 * @interface Loan
 */
export interface Loan {
    /**
     * Id of the loan.
     * @type {string}
     * @memberof Loan
     */
    id?: string | null;
    /**
     * State of the loan.
     * @type {LoanStateEnum}
     * @memberof Loan
     */
    state: LoanStateEnum | null;
    /**
     * Contract id of the loan.
     * @type {string}
     * @memberof Loan
     */
    contractId?: string | null;
    /**
     * Customer the loan belongs to.
     * @type {string}
     * @memberof Loan
     */
    customerId: string;
    /**
     * Loan principal, the amount borrowed.
     * @type {number}
     * @memberof Loan
     */
    principalAmount: number;
    /**
     * Total loan cost, the amount borrowed plus interest.
     * @type {number}
     * @memberof Loan
     */
    totalCostAmount: number;
    /**
     * Amount still pending to pay of the total loan amount.
     * @type {number}
     * @memberof Loan
     */
    remainingAmount?: number | null;
    /**
     * Installment Amount.
     * @type {number}
     * @memberof Loan
     */
    installmentAmount?: number | null;
    /**
     * The amount overdue as of this moment.
     * @type {number}
     * @memberof Loan
     */
    overdueAmount?: number | null;
    /**
     * Number of paid installment.
     * @type {number}
     * @memberof Loan
     */
    paidInstallmentCount?: number | null;
    /**
     * Number of total installments.
     * @type {number}
     * @memberof Loan
     */
    totalInstallmentCount?: number | null;
    /**
     * Duration of the loan in months.
     * @type {number}
     * @memberof Loan
     */
    durationMonths: number;
    /**
     * Has payment protection insurance.
     * @type {boolean}
     * @memberof Loan
     */
    hasPpi?: boolean | null;
    /**
     * Interest percent rate.
     * @type {number}
     * @memberof Loan
     */
    interestRate: number;
    /**
     * App of the application.
     * @type {string}
     * @memberof Loan
     */
    app: string;
    /**
     * When the application was created.
     * @type {Date}
     * @memberof Loan
     */
    applicationAt?: Date | null;
    /**
     * When the loan cool-off period ends.
     * @type {Date}
     * @memberof Loan
     */
    coolOffEndsAt?: Date | null;
    /**
     * Time of the loan disbursal.
     * @type {Date}
     * @memberof Loan
     */
    disbursedAt?: Date | null;
    /**
     * Next repayment date.
     * @type {Date}
     * @memberof Loan
     */
    nextPaymentAt?: Date | null;
    /**
     * Product of the loan.
     * @type {Product}
     * @memberof Loan
     */
    product: Product | null;
}

export function LoanFromJSON(json: any): Loan {
    return LoanFromJSONTyped(json, false);
}

export function LoanFromJSONTyped(json: any, ignoreDiscriminator: boolean): Loan {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'state': LoanStateEnumFromJSON(json['state']),
        'contractId': !exists(json, 'contractId') ? undefined : json['contractId'],
        'customerId': json['customerId'],
        'principalAmount': json['principalAmount'],
        'totalCostAmount': json['totalCostAmount'],
        'remainingAmount': !exists(json, 'remainingAmount') ? undefined : json['remainingAmount'],
        'installmentAmount': !exists(json, 'installmentAmount') ? undefined : json['installmentAmount'],
        'overdueAmount': !exists(json, 'overdueAmount') ? undefined : json['overdueAmount'],
        'paidInstallmentCount': !exists(json, 'paidInstallmentCount') ? undefined : json['paidInstallmentCount'],
        'totalInstallmentCount': !exists(json, 'totalInstallmentCount') ? undefined : json['totalInstallmentCount'],
        'durationMonths': json['durationMonths'],
        'hasPpi': !exists(json, 'hasPpi') ? undefined : json['hasPpi'],
        'interestRate': json['interestRate'],
        'app': json['app'],
        'applicationAt': !exists(json, 'applicationAt') ? undefined : (json['applicationAt'] === null ? null : new Date(json['applicationAt'])),
        'coolOffEndsAt': !exists(json, 'coolOffEndsAt') ? undefined : (json['coolOffEndsAt'] === null ? null : new Date(json['coolOffEndsAt'])),
        'disbursedAt': !exists(json, 'disbursedAt') ? undefined : (json['disbursedAt'] === null ? null : new Date(json['disbursedAt'])),
        'nextPaymentAt': !exists(json, 'nextPaymentAt') ? undefined : (json['nextPaymentAt'] === null ? null : new Date(json['nextPaymentAt'])),
        'product': ProductFromJSON(json['product']),
    };
}

export function LoanToJSON(value?: Loan | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'state': LoanStateEnumToJSON(value.state),
        'contractId': value.contractId,
        'customerId': value.customerId,
        'principalAmount': value.principalAmount,
        'totalCostAmount': value.totalCostAmount,
        'remainingAmount': value.remainingAmount,
        'installmentAmount': value.installmentAmount,
        'overdueAmount': value.overdueAmount,
        'paidInstallmentCount': value.paidInstallmentCount,
        'totalInstallmentCount': value.totalInstallmentCount,
        'durationMonths': value.durationMonths,
        'hasPpi': value.hasPpi,
        'interestRate': value.interestRate,
        'app': value.app,
        'applicationAt': value.applicationAt === undefined ? undefined : (value.applicationAt === null ? null : value.applicationAt.toISOString()),
        'coolOffEndsAt': value.coolOffEndsAt === undefined ? undefined : (value.coolOffEndsAt === null ? null : value.coolOffEndsAt.toISOString()),
        'disbursedAt': value.disbursedAt === undefined ? undefined : (value.disbursedAt === null ? null : value.disbursedAt.toISOString()),
        'nextPaymentAt': value.nextPaymentAt === undefined ? undefined : (value.nextPaymentAt === null ? null : value.nextPaymentAt.toISOString()),
        'product': ProductToJSON(value.product),
    };
}

