import { CookieModal } from 'components/modal/Cookie.modal'
import { memo } from 'react'
import { i18n } from 'scaffold/translation/initI18next'
import { useConfigContext } from './ConfigProvider'

export const CookieModalMemo = memo<{
  domainGroupId: string
  language: string
}>(
  (props) => CookieModal(props),
  () => true,
)

export const CookieProvider = () => {
  const config = useConfigContext()

  return config.cookieBotDomainGroupId ? (
    <CookieModalMemo
      domainGroupId={config.cookieBotDomainGroupId}
      language={i18n.language.replace('-CH', '')}
    />
  ) : null
}
