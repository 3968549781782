import { AppInsightsContext, ReactPlugin, withAITracking } from '@microsoft/applicationinsights-react-js'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import { ReactNode } from 'react'

import { AppInsights_TrackProperties, TrackEventData, TrackException } from 'types/Analytics.d'
import { createBrowserHistory } from 'history'

const hasAppInsightsTracking =
  process.env.NODE_ENV !== 'development' && process.env.REACT_APP_APPINSIGHTS_CONNECTION

const reactPlugin = new ReactPlugin()

let appInsights: ApplicationInsights | null = null

if (hasAppInsightsTracking) {
  const browserHistory = createBrowserHistory({ basename: '' })
  appInsights = new ApplicationInsights({
    config: {
      autoTrackPageVisitTime: true,
      connectionString: process.env.REACT_APP_APPINSIGHTS_CONNECTION,
      disableFetchTracking: false,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: {
          history: browserHistory,
        },
      },
    },
  })

  appInsights.loadAppInsights()
}

export const traceID =
  process.env.NODE_ENV !== 'development' && appInsights
    ? appInsights.context.telemetryTrace.traceID
    : 'no trace id available in development'

/*
 * Prepare properties for tracker
 */

const getTrackEventProperties = (params: TrackEventData): AppInsights_TrackProperties => ({
  errors: params.errors,
})

/*
 * Tracker for redux sagas
 */

export const appInsightsTracker = {
  trackEvent: (params: { name: string } & TrackEventData) => {
    const eventProperties = getTrackEventProperties(params)
    if (!hasAppInsightsTracking) {
      console.debug('### APP INSIGHTS - TRACK EVENT ###')
      console.debug('Name', params.name)
      console.debug('Properties', JSON.stringify(eventProperties, null, 2))
      console.debug('')
    } else if (appInsights) {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('### APP INSIGHTS - TRACK EXCEPTION ###')
        console.debug('APP INSIGHTS OPERATION ID: ', traceID)
      }
      appInsights.trackEvent(
        {
          name: params.name,
        },
        eventProperties,
      )
    }
  },

  // Track exception
  trackException: (payload: TrackException) => {
    const exceptionData = {
      exception: payload.exception,
      severityLevel: payload.severity,
    }
    if (!hasAppInsightsTracking) {
      console.debug('### APP INSIGHTS - TRACK EXCEPTION ###')
      console.debug('ExceptionData', JSON.stringify(exceptionData, null, 2))
    } else if (appInsights) {
      if (process.env.NODE_ENV !== 'production') {
        console.debug('### APP INSIGHTS - TRACK EXCEPTION ###')
        console.debug('APP INSIGHTS OPERATION ID: ', traceID)
      }
      appInsights.trackException(exceptionData)
    }
  },
}

/*
 * Tracker provider
 */

export const AppInsightsTrackingProvider = hasAppInsightsTracking
  ? withAITracking(reactPlugin, ({ children }: { children: ReactNode }) => (
      <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>
    ))
  : ({ children }: { children: ReactNode }) => <>{children}</>
