import { CustomerAuthenticationState, UserInfo } from 'types/Authentication.d'

export const mapUserInfo = async (response: Response, emailToken?: string): Promise<UserInfo | null> => {
  const userInfo = await response.json()

  return userInfo
    ? {
        ...userInfo,
        emailToken,
        phoneMobile: userInfo.phone_mobile || null,
        preferredUsername: userInfo.preferred_username || null,
        state: userInfo.state || CustomerAuthenticationState.Active,
      }
    : null
}
