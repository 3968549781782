import { ReactNode } from 'react'
import styled, { keyframes } from 'styled-components'

type Props = {
  className?: string
}

export const AppHeaderEffects = (props: Props) => {
  return (
    <div className={props.className}>
      <SnowFlakesWrapper className={props.className}>{SnowFlakes}</SnowFlakesWrapper>
      <LightsWrapper>{Lights}</LightsWrapper>
    </div>
  )
}

const SnowFallAnimation = keyframes`
  0% {
    transform: translate3d(var(--left-start), 0, 0);
  }
  100% {
    transform: translate3d(var(--left-end), 110vh, 0);
  }
`

const SnowFlakesWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  pointer-events: none;
  z-index: 99;

  /* added small blur every 6 snowflakes*/
  .snowflake:nth-child(6n) {
    filter: blur(1px);
  }
`
const SnowFlakeItem = styled.div<{
  size: number
  left: number
  leftStart: number
  leftEnd: number
  duration: number
  delay: number
}>`
  --left-start: ${(props) => props.leftStart}vw;
  --left-end: ${(props) => props.leftEnd}vw;
  animation-delay: -${(props) => props.delay}s;
  animation: ${SnowFallAnimation} ${(props) => props.duration}s linear infinite;
  background: white;
  border-radius: 50%;
  position: absolute;
  left: ${(props) => props.left}vw;
  top: -5vh;
  height: ${(props) => props.size}vw;
  width: ${(props) => props.size}vw;
`

const SnowFlakeCount = 100
const SnowFlakes: Array<ReactNode> = []
for (let i = 0; i < SnowFlakeCount; i++) {
  SnowFlakes.push(
    <SnowFlakeItem
      className="snowflake"
      delay={Math.random() * 10}
      duration={5 + Math.random() * 10}
      key={`flake-${i}`}
      left={Math.random() * 100}
      leftEnd={Math.random() * 20 - 10}
      leftStart={Math.random() * 20 - 10}
      size={Math.random() * 5 * 0.2}
    />,
  )
}

const LightsCount = 50
const Lights: Array<ReactNode> = []

for (let i = 0; i < LightsCount; i++) {
  Lights.push(<li key={`light-${i}`} />)
}

const LightWidth = 12
const LightHeight = 28
const LightSpacing = 40
const LightSpread = 3
const LightShadow = `0px ${LightHeight / 6}px ${LightWidth * 2}px ${LightSpread}px`
const LightOffOpacity = 0.4

const Flash1Animation = keyframes`
  0%, 100% {
    background: rgba(0,247,165,1);
    box-shadow: ${LightShadow} rgba(0,247,165,1);
  } 
  50% {
    background: rgba(0,247,165,${LightOffOpacity});
    box-shadow: ${LightShadow} rgba(0,247,165,0.2);
  }
`
const Flash2Animation = keyframes`
  0%, 100% {
    background: rgba(0,255,255,1);
    box-shadow: ${LightShadow} rgba(0,255,255,1);
  } 
  50% {
    background: rgba(0,255,255,${LightOffOpacity});
    box-shadow: ${LightShadow} rgba(0,255,255,0.2);
  }
`
const Flash3Animation = keyframes`
  0%, 100% {
    background: rgba(247,0,148,1);
    box-shadow: ${LightShadow} rgba(247,0,148,1);
  } 
  50% {
    background: rgba(247,0,148,${LightOffOpacity});
    box-shadow: ${LightShadow} rgba(247,0,148,0.2);
  }
`

const LightsWrapper = styled.ul`
  margin: -15px 0 0 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 72px;
  text-align: center;
  white-space: nowrap;
  width: 100%;
  z-index: 1;

  li {
    animation-duration: 2s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: ${Flash1Animation};
    background: rgba(0, 247, 165, 1);
    border-radius: 50%;
    box-shadow: ${LightShadow} rgba(0, 247, 165, 1);
    display: inline-block;
    list-style: none;
    position: relative;
    width: ${LightWidth}px;
    height: ${LightHeight}px;
    margin: ${LightSpacing / 2}px;
    padding: 0;

    :nth-child(2n + 1) {
      background: rgba(0, 255, 255, 1);
      box-shadow: ${LightShadow} rgba(0, 255, 255, 0.5);
      animation-name: ${Flash2Animation};
      animation-duration: 0.4s;
    }
    :nth-child(4n + 2) {
      background: rgba(247, 0, 148, 1);
      box-shadow: ${LightShadow} rgba(247, 0, 148, 1);
      animation-name: ${Flash3Animation};
      animation-duration: 1.1s;
    }
    :nth-child(odd) {
      animation-duration: 1.8s;
    }
    :nth-child(3n + 1) {
      animation-duration: 1.4s;
    }

    :before {
      content: '';
      position: absolute;
      background: #222;
      width: ${LightWidth - 2}px;
      height: ${LightHeight / 3}px;
      border-radius: 3px;
      top: ${0 - LightHeight / 6}px;
      left: 1px;
    }
    :after {
      content: '';
      top: ${0 - LightHeight / 2}px;
      left: ${LightWidth - 3}px;
      position: absolute;
      width: ${LightSpacing + 12}px;
      height: ${(LightHeight / 3) * 2}px;
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }
    :last-child:after {
      content: none;
    }
    :first-child {
      margin-left: ${LightSpacing * -1}px;
    }
  }
`
